import { useState, useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, MenuDivider, Center, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiMoreFill} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import OrderItemsList from "@modules/order/components/OrderItemsList"
import { StatAsset, StatCard, MenuAsset, PromptAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { useOrderService } from "@services/index"
import OrderDetails from "@modules/order/components/OrderDetails"

const Details = () => {

    const navigate = useNavigate()

    const location = useLocation()
    const { pathname } = location
    const reference = pathname?.split('/')[2]

    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()

    const { getOrder, response:order } = useOrderService()
    const { updateOrder, response:updated_order, load } = useOrderService()
    const { deleteOrder, response:deleted_order, load:delete_order_load } = useOrderService()

    const { amount, order_items, shipping, payment, user } = order?.order || {}

    useEffect(() => {
        if (reference || updated_order) {
            getOrder({id: reference})
        }
    }, [reference, updated_order])

    useEffect(() => {
        if (deleted_order) {
            navigate('/orders')
        }
    }, [deleted_order])

    const PageHeaderAction = () => (
        <ButtonGroup>
            {
                order?.order?.status === 'submitted' &&
                <Button isLoading={load} onClick={onOpenPrompt} size={'sm'} className="!bg-primary-950 !text-white !text-xs">Confirm Order</Button>
            }
            <Button onClick={onOpen} size={'sm'}>More Details</Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={`Order #${reference} Details | Review and Manage This Order`}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={`Order Details for #${reference}`}
                        description={`Explore the full details of Order #${reference}, including items, status, and history.`}
                        action={PageHeaderAction}
                    />
                </Box>

                <Box className="content">

                    <Box className="mb-4 grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Amount'}
                                number={`₦${localNumber({number: amount, decimals: 2})}`}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Quantity'}
                                number={localNumber({number: order?.order?.quantity,  decimals: 0})}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Designs Uploaded'}
                                number={`${order_items?.filter(item => item?.design !== null)?.length}/${order_items?.length}`}
                            />
                        </Box>

                    </Box>

                    <Box>
                        {
                            order_items && 
                            <OrderItemsList defaultItems={order_items?.map((item) => ({...item, user: user}))} />
                        }
                    </Box>

                </Box>               
               
           </Box>
        
            <OrderDetails 
                order={order?.order} 
                payment={payment} 
                updateOrder={updateOrder} 
                deleteOrder={deleteOrder}
                load={load}
                delete_order_load={delete_order_load}
                isOpen={isOpen} 
                onClose={onClose} 
            />

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    updateOrder({status: 'confirmed', id: order?.order?.id})
                    onClosePrompt()
                }}
            />

        </AuthenticatedLayout>
    )

}

export default Details