import { Box, Text } from "@chakra-ui/react"
import { BreadcrumbAsset } from "@utils/assets"

const PageHeader = ({title, description, action}) => {

    return (
        
        <Box className="w-full flex lg:flex-row flex-col items-center justify-between gap-y-4">
                        
            <Box className="lg:w-3/6 w-full">

                <Box className="lg:hidden block pb-2">
                    <BreadcrumbAsset />
                </Box>

                <Box>
                    <Text className="font-bold text-xl capitalize">{title}</Text>
                    <Text className="text-xs">{description}</Text>
                </Box>
            </Box>

            <Box className="lg:w-auto w-full lg:items-end items-start">

                {action && action()}

            </Box>

        </Box>

    )

}


export default PageHeader