import { useMemo } from "react"
import { useRequest } from "@handlers/index"
import { setStore, sessionExpires, appURL } from "@utils/helpers"
import Cookies from 'js-cookie'

const MediaService = () => { 

    const {errors: requestErrors, response, load, request} = useRequest()

    const endpoint = `${appURL}/api/media`;

    // Get All Media
    const getMedia = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}`,
            params: data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Get Media by ID
    const getMediaById = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Delete Media by ID
    const deleteMedia = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Services Object with all methods
    const services = {
        requestErrors, 
        response, 
        load,
        getMedia,
        getMediaById,
        deleteMedia
    };
    

    return services

    // return useMemo(() => (services), []);

}

export default MediaService