import store from "@redux/store"
import moment from "moment"
import countries from "@utils/countries.json"
import ColorNamer from "color-namer"

export const appURL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8081' : 'https://q406cfegw8.apprintout.com'
export const cdnURL =  process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8081/storage' : 'https://dqo8c5ib5w.apprintout.com/public/storage'

//Set Data To Redux Store
export const setStore = async (payload, action) => {
    if(payload && action){
        store.dispatch(action(payload))
    }
}

//Get Data From Redux Store
export const getStore = (reducer) => {
    if(reducer){
        const  state = store.getState()
        return state[reducer]
    }
}

// Set Data To Local Storage
export const setStorage = (key, value) => {
    try {
        if (key && value !== '') {
            localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (error) {
        // console.error('Failed to set data to localStorage', error);
    }
}

// Get Data From Local Storage
export const getStorage = (key) => {
    try {
        if (key) {
            const data = localStorage.getItem(key);
            return data ? JSON.parse(data) : null;
        }
        return null;
    } catch (error) {
        // console.error('Failed to get data from localStorage', error);
        return null;
    }
}


//Remove Data From Local Storage
export const removeStorage = (key) => {
    if(key){
        localStorage.removeItem(key);
    }
}

//Clear Local Storage
export const clearStorage = (key) => {
    localStorage.clear(key);
}

//Convert Number To Local Number 
export const localNumber = ({number, decimals}) => {
    return parseFloat(number ?? 0).toLocaleString('en-US', { minimumFractionDigits: decimals ?? 2, maximumFractionDigits: decimals ?? 2 });
}

export const removeEncodedSpace = (value) => {
    return value.replace(/%20/g, ' ');
}

export const formatDate = ({date, format}) => {
    const newDate = new Date(date);
    if (format === 'human') {
        return moment(newDate).fromNow()
    }else{
        return moment(newDate).local().format(format ?? 'YYYY-MM-DD HH:mm');   
    }
}

export const debounce = (func, delay=200) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };
};

export const generateID = (type, length) => {
    return Math.random().toString(36).substr(2, 9);
}

export const sessionExpires = () => {
    const date = new Date();
    date.setTime(date.getTime() + (1440 * 60 * 1000));
    return date;
}

export const truncateText = ({text, length}) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
};

export const convertDataUrlToBlob = (url) =>{

    const [header, base64] = url.split(',');
    const mimeType = header.match(/:(.*?);/)[1];
    const binaryString = atob(base64);
    const len = binaryString.length;
    const uint8Array = new Uint8Array(len);
  
    for (let i = 0; i < len; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }
  
    return new Blob([uint8Array], { type: mimeType });
    
}

export const dials = [...new Set(countries.map(country => country.phone_code))]
.sort((a, b) => a.replace('-', '').localeCompare(b.replace('-', ''), undefined, { numeric: true }));

export const statusColors = (status) => {
    const statuses = {
        active: "green",
        inactive: "red",
        approved: "green",
        true: "green",
        false: "red",
        pending: "orange",
        placed: "blue",
        submitted: "orange",
        unconfirmed: "orange",
        confirmed: "blue",
        shipped: "green",
        delivered: "green",
        successful: "green",
        declined: "red",
        canceled: "red",
        new: "blue"
    }

    return statuses[status?.toLowerCase()] ?? "blue"
}

export const toOrdinal = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = num % 100;
    const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
    return num + suffix;
}

export const colourCodeToName = (code) => {
    const result = ColorNamer(code);
    return result.html[0].name
}

export const isArrayEqual = (...arrays) => {
    if (arrays.length < 2) return true; // Only one array or none, considered equal by default.
    
    const [firstArray, ...restArrays] = arrays;

    return restArrays.every(arr => 
        arr.length === firstArray.length &&
        arr.every((value, index) => JSON.stringify(value) === JSON.stringify(firstArray[index]))
    );
};

export const toSingular = (word) => {
    if (word.endsWith('ies')) {
        return word.slice(0, -3) + 'y'; // "activities" -> "activity"
    } else if (word.endsWith('s') && !word.endsWith('ss')) {
        return word.slice(0, -1); // "users" -> "user"
    }
    return word; // Return as is if no match
}