import { useState } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, LinkAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill} from "@remixicon/react"
import { Link } from "react-router-dom"
import { orderAmount } from '@modules/order/utils/OrderUtils';

const TicketList = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)

    const filters = ['pending', 'open', 'closed']    
    

    const { results, SearchView, setItems } = SearchListAsset([])

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            <HStack spacing={5}>
            
                <MenuAsset
                    triggerProps={{className: '!text-xs', size: 'sm'}}
                    trigger={Button}
                    triggerContent={<Text>Status</Text>}

                    menuContent={
                        <CheckboxGroup>
                            {
                                filters.map((filter, index) => (
                                    <MenuItem key={index}>
                                        <Checkbox size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                    </MenuItem>
                                ))
                            }
                        </CheckboxGroup>
                    }
                />

                <MenuAsset
                    triggerProps={{className: '!text-xs', size: 'sm'}}
                    trigger={Button}
                    triggerContent={<RiMoreFill size={20} />}

                    menuContent={
                        <VStack spacing={5} className="!px-4 !py-2 items-center">
                            <Box>
                                <Text className="text-center text-xs">From</Text>
                                <Input variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                            </Box>

                            <Box>
                                <Text className="text-center text-xs">To</Text>
                                <Input variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                            </Box>
                        </VStack>
                    }
                />

            </HStack>

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for tickets'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0"><Checkbox /> Reference</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Name</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Email</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Title</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>

                    {
                        Array(3)?.fill(0)?.map((ticket, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td>
                                    <HStack>
                                        <Checkbox />
                                        <Text>YYH0gU</Text>
                                    </HStack>
                                </Td>
                                <Td>Godspower Chimezie</Td>
                                <Td>classiciyke@gmail.com</Td>
                                <Td>Design Upload Issue</Td>
                                <Td>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors('active')} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>Active</Text>
                                    </Badge>
                                </Td>
                                <Td>Tue, Oct 15, 7:35 PM</Td>

                                <Td isNumeric>
                                    <LinkAsset href={'#'}>
                                        More Details
                                    </LinkAsset>
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={<></>}
        />
    )

    return (
        <Box>   
            
            {FilterView()}

            {TableView()}
            
        </Box>
    )

}


export default TicketList