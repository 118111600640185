import React, {useEffect, useState} from "react";
import { Box, HStack, Spinner, VStack} from "@chakra-ui/react";
import Header from '@components/header'
import SideBar from "@components/sidebar";
import Footer from '@components/footer';
import { useAccountService } from "@services/index";
import {connect} from 'react-redux'
import Layout from ".";
import { DarkFavicon } from "@utils/images";
import { setStorage, getStorage } from "@utils/helpers";

const AuthenticatedLayout = ({children, accountReducer, title, description}) => {

  const { user } = accountReducer || {}

  const [isFold, setFold] = useState(true)
  const [isMobileSideBar, toggleMobileSideBar] = useState(false)

  const { userDataAuth, load } = useAccountService()

  useEffect(() => {
      userDataAuth()
  }, []);

  useEffect(() => {
    sideBarStorage()
  }, [])


  const sideBarStorage = async () => {
    const is_fold = await getStorage('is_fold_side_bar')
    setFold(is_fold === true)
  }

  const toggleFold = async (status) => {
    setFold(status)
    setStorage('is_fold_side_bar', status)
  }

  return (
    <Layout title={title} description={description} >

        <HStack spacing={0} className="relative justify-between w-full h-full">


            {
              load &&
              <Box className="absolute top-0 left-0 w-full h-full bg-dark z-10 backdrop-blur-sm">
                <Box className="absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2">
                  <Spinner size={'xl'} color="primary.950" className="!border-2 !w-[150px] !h-[150px]" />
                </Box>
                  <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <DarkFavicon width={70} />
                  </Box>
              </Box>
            }

            
            <Box className={ `${isMobileSideBar ? 'z-20 w-full' : 'hidden'} fixed left-0 lg:block h-full ${!isFold ? 'xl:w-[13%] lg:w-[25%]' : 'xl:w-[5%] lg:w-[10%]'} transition-all duration-300 ease-in-out`}>
              <SideBar user={user} isFold={isFold} toggleFold={toggleFold} isSideBar={isMobileSideBar} toggleSideBar={toggleMobileSideBar} />
            </Box>

            <Box className={`!overflow-y-auto fixed right-0 w-full h-full px-3 py-5 ${!isFold ? 'xl:w-[87%] lg:w-[75%]' : 'xl:w-[95%] lg:w-[90%]'} transition-all duration-300 ease-in-out`}>

                {!isMobileSideBar && <Header user={user} toggleFold={toggleFold} isSideBar={isMobileSideBar} toggleSideBar={toggleMobileSideBar} />}

                <Box className="py-3 !overflow-y-auto">
                  {children}
                </Box>
            
            </Box>

        </HStack>
        
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { accountReducer } = state
  return { accountReducer }
};

export default connect(mapStateToProps)(AuthenticatedLayout)