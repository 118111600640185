import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
    MenuItem, MenuList, Menu, MenuButton, useColorMode, StackDivider, Center,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    InputGroup,
    InputRightElement,
    InputLeftElement 
} from '@chakra-ui/react'


export const FieldControl = ({
    
    label, helper, error, leftElement, rightElement, 
    children, formProps, labelProps, helperProps, errorProps,
    leftElementProps, rightElementProps
    
}) => {
    
    return (

        <FormControl textAlign={'left'} isInvalid={error} {...formProps}>

            {label && <FormLabel fontSize={'xs'} {...labelProps}>{label}</FormLabel>}

            <InputGroup size='md'>

                {
                    leftElement && 
                    <InputLeftElement h={'100%'} w={'auto'} {...leftElementProps}>
                        <Box ml={'auto'}>
                            {leftElement}
                        </Box>
                    </InputLeftElement>
                }
                    {children}

                {
                    rightElement &&
                    <InputRightElement h={'100%'} w={'auto'} {...rightElementProps}>
                        <Box ml={'auto'}>
                            {rightElement}
                        </Box>
                    </InputRightElement>
                }

            </InputGroup>

            {
                !error ? <>
                        {helper && <FormHelperText {...helperProps}>We&apos;ll never share your email.</FormHelperText>}
                    </> 
                : 
                <FormErrorMessage {...errorProps} fontSize={'xs'}>{error}</FormErrorMessage>
            }


        </FormControl>

    )

}


export const clearInputErrors = (errorState) => {
    errorState({})
}