"use client";
import { useState, useEffect } from "react";
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
  Menu, MenuButton, MenuList, MenuItem, useColorMode, Center, Heading, StackDivider,
  IconButton, Divider, Avatar, Input, Textarea, Checkbox, useDisclosure
} from '@chakra-ui/react'
import { RiGoogleFill, RiAppleFill, RiFacebookCircleFill, RiStarFill, RiEyeLine, RiEyeOffLine } from "@remixicon/react";
import { FieldControl, clearInputErrors } from "@components/form";
import { BreadcrumbAsset, SearchAsset, ButtonAsset } from "@utils/assets";
import rules from "@utils/rules.json"
import { useAuthService } from "@services/index";
import { useValidator } from "@handlers/index";
import GuestLayout from "@components/layout/Guest";
import { Link } from "react-router-dom";



const ResetPassword = () => {
    
    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            email: '',
            channel: 'email'
        }
    );

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { requestErrors, load, reset, response } = useAuthService()
    
    const {validate, validationErrors} = useValidator(rules.reset_password)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

    const submit = () => {
       if (!isDisabled) {
            clearInputErrors(setError)
            reset(data)
       }
    }

    return (
        <GuestLayout title={'Reset Your Password'}>

            <VStack spacing={5} className="lg:w-10/12 w-full">

                <VStack spacing={4}>

                    <Box mb={5} className="w-10/12">
                        <Text className="font-bold text-2xl">Password Reset</Text>
                        <Text className="text-sm text-gray-400 ">
                            Enter your email to receive a code for resetting your AP Print Out password.
                        </Text>
                    </Box>

                </VStack>

                <VStack alignItems={'flex-start'} w={'100%'} spacing={8}>

                    <FieldControl error={errors?.email?.[0]} label={'Email Address'} labelProps={{fontSize: 'sm'}}>
                        <Input 
                            size={{base: 'lg', lg: 'md'}} 
                            value={data.email} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}  
                            onChange={(event) => onHandleChange('email', event.target.value)} 
                            type="email" 
                            fontSize={'sm'} 
                            placeholder="Enter your email address" 
                            variant='flushed'
                        />
                    </FieldControl>
                
                </VStack>

                <VStack mt={10} spacing={4} w={'100%'}>
                    <Center w={'100%'}>
                        <ButtonAsset onClick={submit} buttonProps={{w: '100%', isLoading: load, isDisabled}}>
                            Reset Password
                        </ButtonAsset>
                    </Center>
                    <Text className="text-xs text-gray-400">Remembered your password? 
                        <Link className="text-primary-500 font-semibold" to={'/auth/login'}>Log In</Link>
                    </Text>
                </VStack>

            </VStack>
            
        </GuestLayout>
    )

}

export default ResetPassword