import { useState, useEffect} from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select,
    Switch
} from "@chakra-ui/react"
import {RiCloseFill, RiAddFill} from "@remixicon/react"
import { useNavigate } from "react-router-dom"
import {ModalAsset, SelectAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import UploadMedia from "@modules/media/components/UploadMedia"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useProductService } from "@services/index"

const CategoryForm = ({isOpen, onClose, category}) => {

    const navigate = useNavigate()

    const {isOpen: isMediaOpen, onOpen: onMediaOpen, onClose:onMediaClose} = useDisclosure()

    const [errors, setError] = useState({})
    const [attributes, setAttributes] = useState([])
    const [data, setData] = useState(
        { 
            name: '', 
            description: '',
            tags: '',
            image: '',
            attributes: []
        }
    );

    const { createCategory, response:updated_category, requestErrors, load } = useProductService()
    const { getAttributes, response:product_attributes } = useProductService()
    const {validate, validationErrors} = useValidator(rules.create_category)

    useEffect(() => {
        if (!attributes?.length)  getAttributes() 
    }, [attributes])

    useEffect(() => {
        if (product_attributes && !attributes?.length) setAttributes(product_attributes?.attributes)
    }, [product_attributes])

    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        if (updated_category?.category) {
            onClose()
            setData({ 
                name: '', 
                description: '',
                tags: '',
                image: '',
                attributes: []
            })
            navigate(`/products/categories/${updated_category?.category?.name}`)
        }
    }, [updated_category])

    useEffect(() => {
        if (category) {
            setData({
                ...data,
                name: category?.name,
                description: category?.description,
                tags: category?.tags,
                image: category?.image,
                attributes: category?.attributes
            })
        }
    }, [category])

    const onHandleChange = (name, value) => {
        setData((prevData) => ({ ...prevData, [name]: value }));
        validate(name, value)
    };

    const onSelectMedia = (media) => {
        onHandleChange('image', media);
    }

    const addAttribute = () => {
        const attribute = { name: "", options: [] };
        setData(prevData => ({
            ...prevData,
            attributes: [...(prevData.attributes || []), attribute]
        }));
    };

    const removeAttribute = (attributeIndex) => {
        setData(prevData => ({
            ...prevData,
            attributes: prevData.attributes.filter((_, index) => index !== attributeIndex)
        }));
    };

    const emptyAttributeOptions = (attributeIndex) => {
        setData(prevData => ({
            ...prevData,
            attributes: prevData.attributes.map((attribute, index) => 
                index === attributeIndex ? { ...attribute, options: [] } : attribute
            )
        }));
    };

    const isAttributeExists = (currentAttribute, currentAttributeIndex) => 
        data?.attributes.some((attribute, attributeIndex) => attributeIndex !== currentAttributeIndex && attribute.name === currentAttribute);

    const onHandleAttributeChange = (name, index, value) => {
        setData(prevData => ({
            ...prevData,
            attributes: prevData.attributes.map((attribute, idx) =>
                idx === index
                    ? { ...attribute, [name === 'attribute' ? 'name' : 'options']: value }
                    : attribute
            )
        }));
    };

    const submit = () => {
        if (!isDisabled) {
            createCategory({...data,  id: category?.id})
        }
    }

    const isDisabled = load || Object.keys(data).some(field => 
        Array.isArray(data[field]) ? data[field].length === 0 : !data[field]
    ) || Object.keys(errors).length > 0 || !data?.attributes?.length || data?.attributes?.some(attribute => 
        !attribute?.options?.length || !attribute?.name
    );

    return (
        
        <Box>

            <ModalAsset
                arrowless
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: '2xl'}, closeOnOverlayClick: false}}
                headerContent={
                <Box>
                    <Text className="font-semibold">{category ? `Edit ${category?.name}` : 'Create Category'}</Text>
                    <Text className="text-xs font-normal text-gray-400">
                        {category ? 'Modify the details of this category to keep your classifications up-to-date.' : 'Add a new category to expand your product classifications and improve catalog organization.'}
                    </Text>
                </Box>
                }
                bodyContent={
                    <VStack spacing={7} className="!items-start !justify-center">
                                                
                        <FieldControl error={errors?.name?.[0]} label={'Category name'}>
                            <Input 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                placeholder="Enter category name" 
                                value={data.name}  
                                onChange={(event) => onHandleChange('name', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.image?.[0]} label={'Category image'}>
                            <Box className="w-[90px] h-[90px] border p-2 relative">
                                {
                                    data?.image &&
                                    <HStack className="z-20 absolute top-2 right-2 cursor-pointer">
                                        <RiCloseFill onClick={() => onHandleChange('image', '')} size={20} className="text-red-500" />
                                    </HStack>
                                }
                                <VStack className="h-full !justify-center !items-center">
                                    <Box onClick={onMediaOpen} className="cursor-pointer">
                                        {
                                            data?.image ? 
                                            <img src={data?.image} width={50} /> :
                                            <img src={'/bg/products/no-image.png'} width={50} />
                                        }
                                    </Box>
                                </VStack>
                            </Box>
                        </FieldControl>

                        <FieldControl error={errors?.description?.[0]} label={'Category Description'}>
                            <Textarea 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                placeholder="Enter category description" 
                                value={data.description}  
                                onChange={(event) => onHandleChange('description', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.tags?.[0]} label={'Category tags (separate with commas)'}>
                            <Textarea 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                placeholder="Enter category tags" 
                                value={data.tags}  
                                onChange={(event) => onHandleChange('tags', event.target.value)} 
                            />
                        </FieldControl>


                        <FieldControl formProps={{className: 'mb-2'}} error={errors?.attributes?.[0]} label={'Category Attributes'}>
                            <VStack spacing={5} className="!justify-between !items-start w-full relative">
                                {
                                    data?.attributes.map((attribute_, index) => (
                                        
                                        <Stack direction={['column', 'row']} className="w-full">

                                            <FieldControl>      
                                                <SelectAsset
                                                    value={data?.attributes[index]?.name}
                                                    options={attributes
                                                        ?.filter((attribute, attributeIndex) => !isAttributeExists(attribute.name, attributeIndex) || attribute?.name === attribute_?.name)
                                                        ?.map(attribute => ({ value: attribute?.name, label: attribute?.name }))
                                                    }
                                                    placeholder={'Select Attribute'}
                                                    size={'md'}
                                                    isSearch
                                                    onChange={(selected) => {
                                                        onHandleAttributeChange('attribute', index, selected)
                                                        emptyAttributeOptions(index)
                                                    }}
                                                />
                                            </FieldControl> 

                                            <FieldControl>

                                                <SelectAsset
                                                    value={data?.attributes[index]?.options}
                                                    options={attributes?.find(attribute => attribute.name === data?.attributes[index]?.name)?.options.map(option => ({value: option, label: option}))}
                                                    placeholder={'Select Options'}
                                                    size={'md'}
                                                    isSearch
                                                    isMulti
                                                    onChange={(selected) => onHandleAttributeChange('options', index, selected)}
                                                />
                                                
                                            </FieldControl> 

                                            <HStack className="w-1/6 justify-end">
                                                <IconButton 
                                                    className="!bg-red-500 !text-white" 
                                                    size={'sm'} 
                                                    icon={<RiCloseFill size={20} />} 
                                                    onClick={() => {
                                                        removeAttribute(index)
                                                    }}
                                                />
                                            </HStack>

                                        </Stack>
                                            
                                    ))
                                }
                                <HStack className="items-center">
                                    <IconButton onClick={addAttribute} className="!bg-primary-950 !text-white" size={'sm'} icon={<RiAddFill size={20} />} />
                                    <Text className="text-xs">Add Attribute</Text>
                                </HStack>
                            </VStack>
                        </FieldControl>
                        


                    </VStack>
                }
                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={onClose} className="border">Cancel</Button>
                        <Button size={'sm'} onClick={() => {
                            submit()
                        }} isDisabled={isDisabled} isLoading={load} className="!bg-primary-950 !text-white">Save Changes</Button>
                    </ButtonGroup>
                }

            />

            <UploadMedia isOpen={isMediaOpen} onClose={onMediaClose} onSelectMedia={onSelectMedia} />

        </Box>

    )

}


export default CategoryForm