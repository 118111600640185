import { useState } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, LinkAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill, RiFileImageLine, RiFilePdf2Line} from "@remixicon/react"
import { Link } from "react-router-dom"
import { orderAmount } from '@modules/order/utils/OrderUtils';

const CMSList = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)

    const filters = ['unconfirmed', 'confirmed', 'decline']    
    
    const { results, SearchView, setItems } = SearchListAsset([])

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0"><Checkbox /> ID</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Page</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">TItle</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Content</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr> 
            }
            bodyContent={
                <>
                    {   

                        Array(3)?.fill(0)?.map((_, index) => (
                            <Tr key={index} className="font-semibold text-xs">
                                <Td>{index+1}</Td>
                                <Td>About Us</Td>
                                <Td>Title</Td>
                                <Td>Content</Td>
                                <Td>Oct 15th 2024, 8:39 PM</Td>
                                <Td isNumeric>
                                    <LinkAsset href={'/cms/about-us'}>Edit</LinkAsset>
                                </Td>
                            </Tr>
                        ))
                    }
                </>
            }
            footerContent={<></>}
        />
    )

    return (
        <Box>   
            
            {TableView()}
            
        </Box>
    )

}


export default CMSList