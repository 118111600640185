import { useMemo } from "react"
import { appURL } from "@utils/helpers"
import { useRequest } from "@handlers/index"


const OrderService = () => {

    const {errors: requestErrors, response, load, request} = useRequest()

    const endpoint = `${appURL}/api/order`

    //Get Orders
    const getOrders = async (data) => {

        request({
            method: 'get',
            url: `${endpoint}`,
            params: data
        },
        [
            ['logged'],
            ['verified']
        ]
        ).then(() => {}).catch(() => {})

    }  

    //Get Order
    const getOrder = async (data) => {

        request({
            method: 'get',
            url: `${endpoint}/order/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ]
        ).then(() => {}).catch(() => {})

    }  

    // Update Order
    const updateOrder = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Order
    const deleteOrder = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get Order Items
    const getOrderItems = async (data) => {
        
        request({
            method: 'get',
            url: `${endpoint}/order-items`,
            params: data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Order Item
    const deleteOrderItem = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/order-item/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Order Item Design
    const deleteOrderItemDesign = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/order-item/${data?.id}/design`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }


    const services = {
        requestErrors,
        response,
        load,
        getOrders,
        getOrder,
        updateOrder,
        deleteOrder,
        getOrderItems,
        deleteOrderItem,
        deleteOrderItemDesign
    }

    return services
    // return useMemo(() => (services), []);
}   

export default OrderService