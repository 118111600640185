import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/account/pages/Index'
import Security from '@modules/account/pages/Security'

const AccountRoutes = [
     <Route path={'/account'}> 
        <Route index element={<Index />} />
        <Route path="profile" element={<Index />} />
        <Route path="security" element={<Security />} />
    </Route>
]

export default AccountRoutes