import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import MediaList from "@modules/media/components/MediaList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import UploadMedia from "@modules/media/components/UploadMedia"
import { useMediaService } from "@services/index"
import { useEffect } from "react"

const Index = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const {getMedia, response} = useMediaService()
    const {deleteMedia, response:delete_esponse} = useMediaService()

    useEffect(() => {
        if (delete_esponse) {
            getMedia()
        }
    }, [delete_esponse])

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">
                Upload Media
            </Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Media Files | Browse and Manage Media Items'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Media Files'}
                        description={`View all media files in a structured list, with options for filtering, organizing, and managing content.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <MediaList getMedia={getMedia} media={response?.media} defaultGetMedia={(page) => getMedia({page})} deleteMedia={deleteMedia} />

                </Box>

               
           </Box>


           <UploadMedia isOpen={isOpen} onClose={onClose} defaultGetMedia={(page) => getMedia({page})} defaultMedia={response?.media} />
        
        </AuthenticatedLayout>
    )

}

export default Index