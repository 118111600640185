import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/support/pages/Index'
import Details from '@modules/support/pages/Details'
import List from '@modules/support/pages/List'


const SupportRoutes = [

    <Route path={'/support'}> 
        <Route index element={<Index />} />
        <Route path="list" element={<List />} />
        <Route path=":id" element={<Details />} />
    </Route>

]

export default SupportRoutes