import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/settings/pages/Index'
import Payment from '@modules/settings/pages/Payment'
import Shipping from '@modules/settings/pages/Shipping'
import Support from '@modules/settings/pages/Support'
import Coupon from '@modules/settings/pages/Coupon';
import Admin from '@modules/settings/pages/Admins'


const SettingsRoutes = [

    <Route path={'/settings'}> 
        <Route index element={<Payment />} />
        <Route path="company" element={<Index />} />
        <Route path="payment" element={<Payment />} />
        <Route path="shipping" element={<Shipping />} />
        <Route path="support" element={<Support />} />
        <Route path="coupon" element={<Coupon />} />
        <Route path="admins" element={<Admin />} />
    </Route>

]

export default SettingsRoutes