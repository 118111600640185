import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel, Input,
    VStack, useDisclosure, StackDivider, IconButton
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiCloseFill, RiAddFill} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset, SelectAsset, AccordionAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { Link } from "react-router-dom"
import SettingsLayout from "@modules/settings/components/SettingsLayout"
import UploadMedia from "@modules/media/components/UploadMedia"

const Index = () => {

    const { isOpen:isMediaOpen, onOpen:onMediaOpen, onClose:onMediaClose } = useDisclosure()

    const CompanyDetails = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Box className="text-left">
                    <Text className="text-sm">Company Details</Text>
                    <Text className="text-xs text-gray-400">Fill in all required info in the form fields below.</Text>
                </Box>
            </HStack>,
        description: 
            <VStack spacing={10} divider={<StackDivider />} className="!justify-between !items-start w-full relative">
                
                <FieldControl>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-y-4">
                        <Box>
                            <Text className="text-sm font-semibold">Company name</Text>
                            <Text className="text-xs text-gray-400">Enter the name of the company</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Input 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                type="text" 
                                placeholder="Enter company name" 
                            />
                        </Box>
                   </Box>
                </FieldControl>

                <FieldControl>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-4">
                        <Box>
                            <Text className="text-sm font-semibold">Domain name </Text>
                            <Text className="text-xs text-gray-400">Enter the domain name of the company</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Input 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                type="text" 
                                placeholder="Enter domain name" 
                            />
                        </Box>
                   </Box>
                </FieldControl>

                <FieldControl>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-4">
                        <Box>
                            <Text className="text-sm font-semibold">Logo (Dark) </Text>
                            <Text className="text-xs text-gray-400">Upload the company logo for dark background</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Box onClick={onMediaOpen} className="w-[80px] h-[80px] border p-2 cursor-pointer hover:bg-gray-100">
                                <VStack className="h-full !justify-center !items-center">
                                    <img src={'/bg/products/1.png'} width={50} />
                                </VStack>
                            </Box>
                        </Box>
                   </Box>
                </FieldControl>

                <FieldControl>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-4">
                        <Box>
                            <Text className="text-sm font-semibold">Logo (Light)</Text>
                            <Text className="text-xs text-gray-400">Upload the company logo for light background</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Box onClick={onMediaOpen} className="w-[80px] h-[80px] border p-2 cursor-pointer hover:bg-gray-100">
                                <VStack className="h-full !justify-center !items-center">
                                    <img src={'/bg/products/1.png'} width={50} />
                                </VStack>
                            </Box>
                        </Box>
                   </Box>
                </FieldControl>

                <Box className="ml-auto">
                    <Button size={'sm'} className="!bg-primary-950 !text-white">Update</Button>
                </Box>
            </VStack>
    }

    const PhoneDetails = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Box className="text-left">
                    <Text className="text-sm">Business Phone Numbers</Text>
                    <Text className="text-xs text-gray-400">Fill in all required info in the form fields below.</Text>
                </Box>
            </HStack>,
        description: 
            <VStack spacing={10} className="w-full justify-between !items-start">

                {
                    Array(3).fill(0).map((_, index) => (

                        <Box key={index} className="lg:w-4/6 w-full">

                            <HStack>

                                <FieldControl label={'Choose Category'}>
                                    <SelectAsset
                                        options={Array({value: 'Support', label: 'Support'})}
                                        placeholder={'Support'}
                                    />
                                </FieldControl>

                                <FieldControl label={'Phone Number'}>
                                    <Input 
                                        fontSize={'xs'} 
                                        type="text"
                                        placeholder="Enter support phone number"
                                    />
                                </FieldControl>

                                <IconButton className="!bg-red-500 !text-white" size={'sm'} icon={<RiCloseFill size={20} />} />
                                
                            </HStack>
                            
                        </Box>

                    ))
                }

                <Box className="ml-auto">
                    <Button size={'sm'} className="!bg-primary-950 !text-white">Update</Button>
                </Box>
                
            </VStack>
    }

    const EmailDetails = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Box className="text-left">
                    <Text className="text-sm">Business Email Addresses</Text>
                    <Text className="text-xs text-gray-400">Fill in all required info in the form fields below.</Text>
                </Box>
            </HStack>,
        description: 
            <VStack spacing={10} className="w-full justify-between !items-start">

                {
                    Array(3).fill(0).map((_, index) => (

                        <Box key={index} className="lg:w-4/6 w-full">

                            <HStack>

                                <FieldControl label={'Choose Category'}>
                                    <SelectAsset
                                        options={Array(
                                            {value: 'Sales', label: 'Support'},
                                            {value: 'Privacy', label: 'Privacy'},
                                            {value: 'Sales', label: 'Sales'}
                                        )}
                                        placeholder={'Support'}
                                    />
                                </FieldControl>

                                <FieldControl label={'Email Address'}>
                                    <Input 
                                        fontSize={'xs'} 
                                        type="text"
                                        placeholder="Enter support email address"
                                    />
                                </FieldControl>
                                
                                <IconButton className="!bg-red-500 !text-white" size={'sm'} icon={<RiCloseFill size={20} />} />
                                
                            </HStack>
                            
                        </Box>

                    ))
                }

                <Box className="ml-auto">
                    <Button size={'sm'} className="!bg-primary-950 !text-white">Update</Button>
                </Box>

            </VStack>
    }

    const AddressDetails = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Box className="text-left">
                    <Text className="text-sm">Business Addresses</Text>
                    <Text className="text-xs text-gray-400">Fill in all required info in the form fields below.</Text>
                </Box>
            </HStack>,
        description: 
            <VStack spacing={10} className="w-full justify-between !items-start">

                {
                    Array(3).fill(0).map((_, index) => (

                        <Box key={index} className="lg:w-4/6 w-full">

                            <HStack>

                                <FieldControl label={'Business Address'}>
                                    <Input 
                                        fontSize={'xs'} 
                                        type="text"
                                        placeholder="Enter business address"
                                    />
                                </FieldControl>

                                <IconButton className="!bg-red-500 !text-white" size={'sm'} icon={<RiCloseFill size={20} />} />
                                
                            </HStack>
                            
                        </Box>

                    ))
                }
                
                <Box className="ml-auto">
                    <Button size={'sm'} className="!bg-primary-950 !text-white">Update</Button>
                </Box>
            </VStack>
    }

    const SocialDetails = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Box className="text-left">
                    <Text className="text-sm">Social Handles</Text>
                    <Text className="text-xs text-gray-400">Fill in all required info in the form fields below.</Text>
                </Box>
            </HStack>,
        description: 
            <VStack spacing={10} className="w-full justify-between !items-start">

                {
                    Array(3).fill(0).map((_, index) => (

                        <Box key={index} className="lg:w-4/6 w-full">

                            <HStack>

                                <FieldControl label={'Choose Platform'}>
                                    <SelectAsset
                                        options={Array(
                                            {value: 'Facebook', label: 'Facebook'},
                                            {value: 'Twitter', label: 'Twitter'},
                                            {value: 'Instagram', label: 'Instagram'}
                                        )}
                                        placeholder={'Facebook'}
                                    />
                                </FieldControl>

                                <FieldControl label={'Facebook Handle'}>
                                    <Input 
                                        fontSize={'xs'} 
                                        type="text"
                                        placeholder="Enter facebook username"
                                    />
                                </FieldControl>
                                
                                <IconButton className="!bg-red-500 !text-white" size={'sm'} icon={<RiCloseFill size={20} />} />
                                
                            </HStack>
                            
                        </Box>

                    ))
                }

                <Box className="ml-auto">
                    <Button size={'sm'} className="!bg-primary-950 !text-white">Update</Button>
                </Box>

            </VStack>
    }


    return (
        <SettingsLayout title={'Settings | Manage Preferences and Configurations'}>

            <Box className="content relative">

                <AccordionAsset 
                    accordionProps={{allowMultiple: true}}
                    accordionItems={[CompanyDetails, PhoneDetails, EmailDetails, AddressDetails, SocialDetails]}
                    titleProps={{className: 'bg-gray-100 !py-5' }}
                    panelprops={{className: 'font-normal text-sm text-gray-500 !bg-gray-50 !py-5'}}
                    itemProps={{ className: 'border my-5'}}
                />

            </Box>

            
            <UploadMedia isOpen={isMediaOpen} onClose={onMediaClose} />
        
        </SettingsLayout>
    )

}

export default Index