import { useState, useEffect} from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select,
    Switch
} from "@chakra-ui/react"
import {RiCloseFill, RiAddFill, RiStarFill} from "@remixicon/react"
import { useNavigate } from "react-router-dom"
import {ModalAsset, SelectAsset, PromptAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import UploadMedia from "@modules/media/components/UploadMedia"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useProductService } from "@services/index"

const ReviewDetails = ({isOpen, onClose, review, getReviews}) => {

    const [prompt, setPrompt] = useState('');
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    const { updateReview, deleteReview, response:update_review, load } = useProductService()

    useEffect(() => {
        if (update_review ) {
            onClose()
            onClosePrompt()
            getReviews()
        }
    }, [update_review])

    const onHandlePromptCall = () => {
        
        switch (prompt) {
            case 'delete':
                deleteReview({id: review?.id})
                break;
            case 'approve':
                updateReview({status: true, id: review?.id})
                break;
            default:
                break;
        }

    }

    return (
        
        <Box>

            <ModalAsset
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                <Box>
                    <Text className="font-semibold">Review Details</Text>
                    <Text className="text-xs font-normal text-gray-400">Approve or delete the review below</Text>
                </Box>
                }
                bodyContent={
                    <VStack spacing={7} className="!items-start !justify-center">

                        <Box>
                            <Text className="font-semibold">Rating</Text>
                            <HStack>
                                <Text className="text-xs">({review?.rating})</Text>
                                {
                                    Array(review?.rating)?.fill(0)?.map((_, index) => (
                                        <RiStarFill 
                                            key={index}
                                            size={15} 
                                            className="text-amber-300" 
                                        />
                                    ))
                                }
                            </HStack>
                        </Box>

                        {
                            review?.title &&
                            <Box>
                                <Text className="font-semibold">Title</Text>
                                <Text className="text-sm">{review?.title}</Text>    
                            </Box>
                        }

                        <Box>
                            <Text className="font-semibold">Comment</Text>
                            <Text className="text-sm">{review?.comment}</Text>    
                        </Box>

                    </VStack>
                }
                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={() => {
                            setPrompt('delete')
                            onOpenPrompt()
                        }} className="border">Delete</Button>
                        {
                            !review?.status &&
                            <Button size={'sm'} onClick={() => {
                                setPrompt('approve')
                                onOpenPrompt()
                            }} isLoading={load} className="!bg-primary-950 !text-white">Approve</Button>
                        }
                    </ButtonGroup>
                }

            />


            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                load={load}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    onHandlePromptCall(prompt)
                }}
            />

        </Box>

    )

}


export default ReviewDetails