import { useState, useEffect} from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, VStack, Td, 
    Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PromptAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors, truncateText} from "@utils/helpers"
import { RiMoreLine, RiMoreFill } from "@remixicon/react"
import { Link } from "react-router-dom"
import SubCategoryForm from "@modules/product/components/SubCategory/SubCategoryForm"
import { useProductService } from "@services/index"


const CategoriesList = ({title, description, defaultItems}) => {

    const [item, setItem] = useState(null)

    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    
    const { getSubCategories, response } = useProductService()
    const { deleteSubCategory, response:deleted_sub_category, load:delete_sub_category_load } = useProductService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.sub_categories)

    useEffect(() => {
        if (!defaultItems) {
            getSubCategories()
        }else{
            setSearchItems(defaultItems)
        }
    }, [defaultItems])

    useEffect(() => {
        if (response?.sub_categories) {
            setSearchItems(response?.sub_categories)
        }
    }, [response?.sub_categories])

    useEffect(() => {
        if (deleted_sub_category) {
            onClosePrompt()
            getSubCategories()
        }
    }, [deleted_sub_category])


    const FilterView = () => (

        <HStack className="justify-end items-center w-full py-2" spacing={5}>

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for subcategories'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Name</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Category</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Description</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Tags</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                    {   

                        results?.map((sub_category, index) => (
                            
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index+1} </Td>
                                <Td>
                                    <Link className="underline" to={`/products/subcategories/${sub_category?.name}`}>{sub_category?.name}</Link>
                                </Td>
                                <Td>
                                    {
                                        sub_category?.image ? 
                                            <img src={sub_category?.image} alt={sub_category?.name} width={40} />
                                        :
                                            <img src={'/bg/products/no-image.png'} width={50} />

                                    }
                                </Td>
                                <Td>{sub_category?.category?.name}</Td>
                                <Td>{truncateText({text: sub_category?.description, length: 20})} </Td>
                                <Td>{truncateText({text: sub_category?.tags, length: 20})} </Td>
                                <Td isNumeric>
                                    <MenuAsset
                                        triggerProps={{className: '!text-xs', size: 'sm'}}
                                        trigger={Button}
                                        triggerContent={<RiMoreFill size={20} />}
                                        listProps={{
                                            className: '!min-w-[120px]'
                                        }}
                                        menuContent={
                                            <>  
                                                <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Link to={`/products/subcategories/${sub_category?.name}`}>More Details</Link>
                                                </MenuItem>

                                                <MenuItem onClick={() => {
                                                    setItem(sub_category)
                                                    onOpen()
                                                }} _hover={{bg: 'primary.950', color: 'white'}}>
                                                    Edit Sub Category
                                                </MenuItem>

                                                <MenuDivider />

                                                <Center>
                                                    <Button onClick={() => {
                                                        setItem(sub_category)
                                                        onOpenPrompt()
                                                    }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                                        Delete
                                                    </Button>
                                                </Center>
                                            </>
                                        }
                                    />
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={<></>}
        />
    )

    return (
        <Box>   
            
            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "Sucategory List"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "View a detailed table of product subcategories and management options."}</Text>
            </Box>

            {FilterView()}

            {TableView()}

            {
                item &&
                <SubCategoryForm isOpen={isOpen} onClose={onClose} sub_category={item} />
            }

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                load={delete_sub_category_load}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    deleteSubCategory({id: item?.id})
                }}
            />
            
        </Box>
    )

}


export default CategoriesList