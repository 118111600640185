//Set Auth Status
export const setAuth  = (data) => ({
    type: 'SET_AUTH',
    payload: data
})

//Set Auth Status
export const setUser  = (data) => ({
    type: 'SET_USER',
    payload: data
})
