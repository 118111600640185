import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select,
    Switch
} from "@chakra-ui/react"
import { RiCloseFill } from "@remixicon/react"
import {ModalAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import UploadMedia from "@modules/media/components/UploadMedia"
import { usePaymentService } from "@services/index"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"

const PaymentForm = ({isOpen, onClose, gateway, getGateways, setItem}) => {

    const {isOpen: isMediaOpen, onOpen: onMediaOpen, onClose:onMediaClose} = useDisclosure()

    const [errors, setError] = useState({})
    const [data, setData] = useState(
        { 
            image: '',
            description: '',
            status: false
        }
    );

    const { updateGateway, response:updated_gateway, requestErrors, load } = usePaymentService()
    const {validate, validationErrors} = useValidator(rules.update_payment_gateway)

    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        if (updated_gateway?.gateway) {
            onClose()
            setData({ 
                image: '',
                description: '',
                status: false
            })
            getGateways()
            setItem(null)
        }
    }, [updated_gateway])

    useEffect(() => {
        if (gateway) {
            setData({
                ...data,
                description: gateway?.description,
                image: gateway?.image,
                status: gateway?.status
            })
        }
    }, [gateway])

    const onHandleChange = (name, value) => {
        setData((prevData) => ({ ...prevData, [name]: value }));
        validate(name, value)
    };

    const onSelectMedia = (media) => {
        onHandleChange('image', media);
    }

    const submit = () => {
        if (!isDisabled) {
            updateGateway({...data,  id: gateway?.id})
        }
    }

    const isDisabled = load || Object.keys(data).some(field =>  data[field] === '' ) || Object.keys(errors).length > 0;

    return (
        
        <Box>

            <ModalAsset

                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                <Box>
                    <Text className="font-semibold capitalize">{gateway?.name} Configuration</Text>
                    <Text className="text-xs font-normal text-gray-400">Update and manage settings for {gateway?.name}.</Text>
                </Box>
                }
                bodyContent={
                    <VStack spacing={7} className="!items-start !justify-center">
                                                
                        <FieldControl label={'Gateway name'}>
                            <Input 
                                size={{base: 'lg', lg: 'md'}} 
                                className="capitalize"
                                fontSize={'xs'} 
                                placeholder="Enter gateway name" 
                                value={gateway?.name}
                                disabled
                            />
                        </FieldControl>

                        <FieldControl error={errors?.image?.[0]} label={'Gateway Logo'}>
                            <Box className="w-[90px] h-[90px] border p-2 relative">
                                {
                                    data?.image &&
                                    <HStack className="z-20 absolute top-2 right-2 cursor-pointer">
                                        <RiCloseFill onClick={() => onHandleChange('image', '')} size={20} className="text-red-500" />
                                    </HStack>
                                }
                                <VStack className="h-full !justify-center !items-center">
                                    <Box onClick={onMediaOpen} className="cursor-pointer">
                                        {
                                            data?.image ? 
                                            <img src={data?.image} width={50} /> :
                                            <img src={'/bg/products/no-image.png'} width={50} />
                                        }
                                    </Box>
                                </VStack>
                            </Box>
                        </FieldControl>

                        <FieldControl error={errors?.description?.[0]} label={'Gateway Description'}>
                            <Textarea 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                placeholder="Enter gateway description" 
                                value={data.description}  
                                onChange={(event) => onHandleChange('description', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl label={'Gateway Status'}>
                            <Switch isChecked={data?.status} onChange={(event) => onHandleChange('status', event.target.checked)}  />
                        </FieldControl>
                        
                    </VStack>
                }
                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={onClose} className="border">Cancel</Button>
                        <Button size={'sm'} onClick={submit} isDisabled={isDisabled} isLoading={load} className="!bg-primary-950 !text-white">Save Changes</Button>
                    </ButtonGroup>
                }

            />

        <UploadMedia isOpen={isMediaOpen} onClose={onMediaClose} onSelectMedia={onSelectMedia} />

        </Box>

    )

}


export default PaymentForm