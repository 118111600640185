import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel, Input, VStack,
    useDisclosure, Badge
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber, statusColors } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { Link } from "react-router-dom"
import SettingsLayout from "@modules/settings/components/SettingsLayout"
import PaymentForm from "@modules/settings/components/PaymentForm"
import { usePaymentService } from "@services/index"
import PageHeader from "@components/header/components/PageHeader"

const Payment = () => {

    const [item, setItem] = useState(null)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const { getGateways, response } = usePaymentService()

    useEffect(() => {
        getGateways()
    }, [])

    return (
        <SettingsLayout title={'Payment Settings | Configure Payment Methods and Preferences'}>

            <Box className="mb-5">
                <PageHeader
                    title={<Text className="text-sm">Payment Settings</Text>}
                    description={<Text className="text-xs text-gray-500">Set up and manage available payment methods, preferences, and payment gateway configurations.</Text>}
                />
            </Box>

            <Box className="w-full grid grid-cols-3 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-3 gap-4">
                {
                    response?.gateways?.map((gateway, index) => (
                        <Box onClick={() => {
                            setItem(gateway)
                            onOpen()
                        }} key={index} className="w-full h-[110px] mx-auto border p-2 cursor-pointer hover:bg-gray-100">
                            <Text className="capitalize text-center text-xs">{gateway?.name}</Text>
                            <VStack className="h-full mt-[-10px] !justify-center !items-center">
                                <img src={gateway?.image} width={80} />
                                <Badge className="!px-2" colorScheme={statusColors(gateway?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                    <Text className="font-normal" fontSize={8}>{gateway?.status ? "Active" : 'Inactive'}</Text>
                                </Badge>
                            </VStack>
                        </Box>
                    ))
                }
            </Box>

            <PaymentForm isOpen={isOpen} onClose={onClose} gateway={item} getGateways={getGateways} setItem={setItem} />
        </SettingsLayout>
    )

}

export default Payment