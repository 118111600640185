import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, 
    Undo, Underline, Link, List, BlockQuote, Image, ImageToolbar, 
    ImageCaption, ImageStyle, ImageResize, MediaEmbed, Table, TableToolbar, FontFamily,
    FontSize
} from 'ckeditor5';

const Edit = () => {

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button size={'sm'} className="!bg-primary-950 !text-white">
                Publish
            </Button>
            <Button size={'sm'} className="border">
                Save As Draft
            </Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Payments'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'About Us'}
                        description={`Edit about us page content`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content h-full">

                    <CKEditor
                        editor={ ClassicEditor }
                        config={{
                            toolbar: {
                                items: [
                                    'undo', 'redo', '|', 'fontFamily', '|' , 'fontSize', 'heading', '|', 'bold', 'italic', 'underline', '|',
                                    'link', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'insertTable', 
                                    'tableColumn', 'tableRow', 'mergeTableCells', '|', 'imageUpload', '|', 'mediaEmbed'
                                ],
                            },
                            plugins: [
                                Essentials, Bold, Italic, Underline, Link, List, BlockQuote, 
                                Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, MediaEmbed, Table, TableToolbar, Paragraph,
                                FontSize, FontFamily
                            ],
                            image: {
                                toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
                            },
                            table: {
                                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                            },
                            fontFamily: {
                                options: [
                                    'default',
                                    'Arial, Helvetica, sans-serif',
                                    'Courier New, Courier, monospace',
                                    'Georgia, serif',
                                    'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                    'Tahoma, Geneva, sans-serif',
                                    'Times New Roman, Times, serif',
                                    'Verdana, Geneva, sans-serif'
                                ]
                            },
                            fontSize: {
                                options: [10, 12, 14, 'default', 18, 20, 24, 30, 36]
                            }
                        }}
                        style={{ minHeight: '400px'}}
                    />

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Edit