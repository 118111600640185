import { useEffect, useState } from 'react'
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button, 
    MenuItem, Avatar, Center, MenuDivider, IconButton
} from '@chakra-ui/react'
import { RiNotification2Fill} from '@remixicon/react'
import { MenuAsset, LinkAsset} from '@utils/assets.jsx'
import { useNavigate } from 'react-router-dom'
import { useUserService } from '@services/index'
import { formatDate, toSingular } from '@utils/helpers'


const Notifications = () => {

    const navigate = useNavigate()

    const [activities, setActivities] = useState([])
    const {getActivities, response, load} = useUserService()

    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.activities || {}

    useEffect(() => {
        
        if (!activities?.length) {
            getActivities({status: ['active']})
        }

    }, [])

    useEffect(() => {
        
        if (data) {
            setActivities(data)
        }

    }, [data])

    const NotificationMenuView = () => (
        <MenuAsset
            arrowless
            trigger={Button}
            triggerContent={<RiNotification2Fill size={15} />}
            listProps={{className: '!p-3 w-[350px]'}}
            menuContent={
                <Box>
                    <Text className='text-sm font-medium mb-3'>Notifications ({total})</Text>
                    <VStack spacing={2} className='mb-3 !items-center h-[300px] overflow-y-auto'>
                        {
                            activities?.map((activity, index) => (
                                <HStack onClick={() => navigate(activity?.metadata?.link)} key={index} className='cursor-pointer hover:bg-gray-100 p-2 justify-between items-center w-full'>
                                    <HStack>
                                        <Center className='bg-primary-950 rounded-full w-[20px] h-[20px]'>
                                            <RiNotification2Fill size={10} className='text-white' />
                                        </Center>
                                        <Box>
                                            <Box>
                                                <Text className='text-xs font-medium capitalize'>{`${toSingular(activity?.object_type)?.replace('_', ' ')} ${activity?.action}`}</Text>
                                                <Text className='text-xs text-gray-400'>{activity?.user?.first_name} {activity?.description}</Text>
                                            </Box>
                                        </Box>
                                    </HStack>
                                    <Box>
                                        <Text className='text-xs'>{formatDate({date: activity?.created_at, format: 'human'})}</Text>
                                    </Box>
                                </HStack>
                            ))
                        }
                    
                    </VStack>

                    <Center className='w-full'>
                        <LinkAsset href={'/notifications'} linkProps={{className: 'w-full text-center'}}>More</LinkAsset>
                    </Center>

                </Box>
            }
            triggerProps={{
                className: '!w-auto mt-2',
                _active: {
                    color: 'primary.950',
                    textDecoration: 'none',
                },
                _hover: {
                    color: 'primary.950',
                    textDecoration: 'none'
                },
                size: 'sm', 
                variant: 'link', 
                rounded: 20,
            }}
        />
    )

    return (
        <>
            <NotificationMenuView />
            {
                activities?.length ?
                <Box className='relative'>
                    <Center className='absolute top-[-30px] right-[10px] bg-red-500 rounded-full  w-[15px] h-[15px] p-1'>
                        <Text className='right-0 text-white' fontSize={8}>{total}</Text>
                    </Center>
                </Box>
                : <></>
            }
        </>
    )

}

export default Notifications
