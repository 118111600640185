import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center,
    ButtonGroup
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PromptAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill} from "@remixicon/react"
import { Link } from "react-router-dom"
import { orderAmount } from '@modules/order/utils/OrderUtils';
import CouponForm from "./CouponForm"
import CouponDetails from "./CouponDetails"
import { useProductService } from "@services/index"


const CouponList = () => {

    const [item, setItem] = useState(null)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const { isOpen:isCouponOpen, onOpen:onCouponOpen, onClose:onCouponClose } = useDisclosure()
    const { isOpen:isConfigOpen, onOpen:onConfigOpen, onClose:onConfigClose } = useDisclosure()
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
        
    const filters = ['active', 'inactive']    
    const [filter, setFilter] = useState({})

    const { getCoupons, response } = useProductService()
    const { deleteCoupon, response:deleted_coupon, load:delete_coupon_load } = useProductService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.coupons?.data)
    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.coupons || {}

    useEffect(() => {
        getCoupons(filter)
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    useEffect(() => {
        if (deleted_coupon) {
            onClosePrompt()
            onCouponClose()
            getCoupons(filter)
        }
    }, [deleted_coupon])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            if (value) {
                updatedFilter[name] = value;
            } else {
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    }

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            <ButtonGroup>
                
                <Button onClick={() => {
                    onConfigOpen()
                    setItem(null)
                }} size={'sm'} className="!bg-primary-950 !text-white">Add Coupon</Button>
            
                <MenuAsset
                    triggerProps={{className: '!text-xs', size: 'sm'}}
                    trigger={Button}
                    triggerContent={<Text>Status</Text>}
                    menuContent={
                        <CheckboxGroup onChange={(value) => onHandleFilter('status', value)} value={filter?.status}>
                            {
                                filters.map((filter, index) => (
                                    <MenuItem key={index}>
                                        <Checkbox value={filter} size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                    </MenuItem>
                                ))
                            }
                        </CheckboxGroup>
                    }
                />

                <MenuAsset
                    triggerProps={{className: '!text-xs', size: 'sm'}}
                    trigger={Button}
                    triggerContent={<Text>Date/Time</Text>}
                    menuContent={
                        <VStack spacing={5} className="!px-4 !py-2 items-center">
                            <Box>
                                <Text className="text-center text-xs">From</Text>
                                <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                            </Box>

                            <Box>
                                <Text className="text-center text-xs">To</Text>
                                <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                            </Box>
                        </VStack>
                    }
                />

            </ButtonGroup>

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for coupons'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">ID</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Code</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Discounts</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Valid</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Limit/Used</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>

                    {
                        results?.map((coupon, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{coupon?.id}</Td>
                                <Td>{coupon?.code}</Td>
                                <Td>
                                    <Box>
                                        <Text className="text-xs">Amount: ₦{coupon?.discount_amount}</Text>
                                        <Text className="text-xs">Pecent: {coupon?.discount_percentage}%</Text>
                                    </Box>
                                </Td>
                                <Td>{`${coupon?.valid_from} - ${coupon?.valid_until}`}</Td>
                                <Td>
                                    <Box>
                                        <Text className="text-xs">Limit: {coupon?.usage_limit}</Text>
                                        <Text className="text-xs">Used: {coupon?.used_count}</Text>
                                    </Box>
                                </Td>
                                <Td>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(coupon?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{coupon?.status ? 'Active' : 'Inactive'}</Text>
                                    </Badge>
                                </Td>

                                <Td isNumeric>

                                    <MenuAsset
                                        triggerProps={{className: '!text-xs', size: 'sm'}}
                                        trigger={Button}
                                        triggerContent={<RiMoreFill size={20} />}
                                        listProps={{
                                            className: '!min-w-[120px]'
                                        }}
                                        menuContent={
                                            <>  
                                                <MenuItem onClick={() => {
                                                    onCouponOpen()
                                                    setItem(coupon)
                                                }} _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Text className="text-xs font-medium capitalize">More Details</Text>
                                                </MenuItem>

                                                <MenuItem onClick={() => {
                                                    onConfigOpen()
                                                    setItem(coupon)
                                                }} _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Text className="text-xs font-medium capitalize">Edit Coupon</Text>
                                                </MenuItem>

                                                <MenuDivider />

                                                <Center>
                                                    <Button onClick={() => {
                                                        onOpenPrompt()
                                                        setItem(coupon)
                                                    }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                                        Delete
                                                    </Button>
                                                </Center>
                                            </>
                                        }
                                    />
                                    
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getCoupons({filter, page})}
                />
            }
        />
    )

    return (
        <Box>   
            
            {FilterView()}

            {TableView()}

            <CouponDetails 
                isOpen={isCouponOpen} 
                onClose={onCouponClose} 
                coupon={item} 
                getCoupons={getCoupons} 
                onOpenPrompt={onOpenPrompt} 
            />

            <CouponForm 
                isOpen={isConfigOpen} 
                onClose={onConfigClose} 
                coupon={item} 
                getCoupons={getCoupons} 
                setItem={setItem}
            />

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                load={delete_coupon_load}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    deleteCoupon({id: item?.id})
                }}
            />
            
        </Box>
    )

}


export default CouponList