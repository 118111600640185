import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, VStack, Td, Badge, AvatarGroup, Avatar, useDisclosure} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, LinkAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine } from "@remixicon/react"
import { Link } from "react-router-dom"
import OrderItemDetails from "./OrderItemDetails"
import { orderAmount } from '@modules/order/utils/OrderUtils';
import { useOrderService } from "@services/index"

const OrderList = ({title, description, defaultItems}) => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const filters = ['placed', 'submitted', 'confirmed', 'shipped', 'delivered', 'declined']    

    const [filter, setFilter] = useState({})
    const {getOrders, response, load} = useOrderService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.orders?.data ?? defaultItems)

    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.orders || {}

    useEffect(() => {
        if (!defaultItems) {
            getOrders(filter)
        }else{
            setSearchItems(defaultItems)
        }
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            if (value) {
                updatedFilter[name] = value;
            } else {
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    }

    const FilterView = () => {

        return (

            
            <HStack className="justify-between items-center w-full py-2" spacing={5}>

                {
                    !defaultItems &&

                    <HStack spacing={5}>
                    
                        <MenuAsset
                            triggerProps={{className: '!text-xs', size: 'sm'}}
                            trigger={Button}
                            triggerContent={<Text>Status</Text>}
                            menuContent={
                                <CheckboxGroup onChange={(value) => onHandleFilter('status', value)} value={filter?.status}>
                                    {
                                        filters.map((filter, index) => (
                                            <MenuItem key={index}>
                                                <Checkbox value={filter} size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                            </MenuItem>
                                        ))
                                    }
                                </CheckboxGroup>
                            }
                        />

                        <MenuAsset
                            triggerProps={{className: '!text-xs', size: 'sm'}}
                            trigger={Button}
                            triggerContent={<Text>Date/Time</Text>}
                            menuContent={
                                <VStack spacing={5} className="!px-4 !py-2 items-center">
                                    <Box>
                                        <Text className="text-center text-xs">From</Text>
                                        <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                    </Box>

                                    <Box>
                                        <Text className="text-center text-xs">To</Text>
                                        <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                    </Box>
                                </VStack>
                            }
                        />

                    </HStack>

                }

                <Box className="lg:block hidden" spacing={5}>

                    {SearchView({placeholder: 'Search for orders'})}
                    
                </Box>

            </HStack>

        )
    }

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Reference</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Items</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Total Quantity</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">User</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Amount</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                    {   

                        results?.map((item, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index + 1 + (per_page || 0) * ((current_page || 1) - 1)}</Td>
                                <Td>
                                    <Link to={`/orders/${item?.reference?.toLowerCase()}`}>
                                        <Text className="underline">{item?.reference}</Text>
                                    </Link>
                                </Td>
                                <Td>
                                    <AvatarGroup size='md' max={2}>
                                        {
                                            item?.order_items?.map((order_item, index) => (
                                                <Avatar key={index} name={order_item?.product?.name} src={order_item?.product?.images[0]} />
                                            ))
                                        }
                                    </AvatarGroup>
                                </Td>
                                <Td>
                                    { item?.quantity } Piece(s)
                                </Td>
                                <Td>
                                    <Link to={`/users/${item?.user?.username}`}>
                                        <HStack>
                                            <Avatar size={'sm'} name={`${item?.user?.first_name} ${item?.user?.last_name}`} />
                                            <Text className="underline">{`${item?.user?.first_name} ${item?.user?.last_name}`}</Text>
                                        </HStack>
                                    </Link>
                                </Td>
                                <Td>₦{localNumber({number: item?.amount })}</Td>
                                <Td textAlign={{base: 'right', lg: 'left'}}>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(item?.status)} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{item?.status}</Text>
                                    </Badge>
                                </Td>
                                <Td>{formatDate({date: item?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>
                                <Td isNumeric>
                                    <LinkAsset href={`/orders/${item?.reference?.toLowerCase()}`}>
                                        More Details
                                    </LinkAsset>
                                </Td>
                            </Tr>
                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getOrders({filter, page})}
                />
            }
        />
    )

    return (
        <Box>

            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "Orders Table"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "Detailed list of all orders, including statuses, items, and actions for efficient management."}</Text>
            </Box>

            {FilterView()}

            {TableView()}
            
        </Box>
    )

}


export default OrderList