import { useState, useEffect} from "react"
import { useNotification } from ".."
import response from '@utils/response.json'
import Cookies from 'js-cookie'
import { setStore } from "@utils/helpers"
import { setUser } from "@modules/account/state/action"
import { useNavigate } from "react-router-dom"

const ErrorHandler = () => {
    
    const [errors, setError] = useState({})
    const { notify } = useNotification()
    const navigate = useNavigate()

    //On Logout
    const onLogout = () => {
        Cookies.remove('auth')
        Cookies.remove('verification')
        setStore({}, setUser)
        setTimeout(() => {
            navigate('/auth/login')
        }, 2000)
    }


    const handleError = (error) => {
        
        const { data, status } = error?.response || {};

        // Check for network error
        if (error.message === 'Network Error') {
            error.message = "Please check, you've lost your internet connection!";
        }

        //Navigate to Login Screen If Unauthenticated
        if (status === 401 || status === 419) {
            onLogout()
        }
    
        //Set Satus 409 Errors
        status !== 409 && data?.errors && setError(data?.errors);
        status === 409 && data?.message && setError({error: data?.message});
        
        const msg = status === 409 ? data?.message : ( data?.errors?.message || response?.error?.[status?.toString()] || error?.message)

        // Notify on error
        if (status != 401 && status != 419) {
            notify({
                status: 'error',
                msg
            })
        }

    }

    return {
        errors,
        handleError
    }

}

export default ErrorHandler