import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/media/pages/Index'


const MediaRoutes = [

    <Route path={'/media'}> 
        <Route index element={<Index />} />
    </Route>

]

export default MediaRoutes