import { useEffect, useState } from "react"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, Divider
} from "@chakra-ui/react"
import { appURL } from "@utils/helpers"
import { RiArrowRightSLine, RiArrowLeftSLine } from "@remixicon/react"
import { UploadAsset, ModalAsset, PaginateAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { useMediaService } from "@services/index"
import Slider from "react-slick";
import MediaList from "./MediaList"


const MediaGallery = ({isOpen, onClose, media, defaultGetMedia, selected, setSelected}) => {

    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = media || {}

    return (
            <ModalAsset
                modalProps={{isOpen, onClose: () => {
                    onClose()
                    setSelected(null)
                }, isCentered: true, size: {base: 'full', lg: '4xl'}, closeOnOverlayClick: false}}
                headerContent={
                   <Box>
                        <Text className="font-semibold">Media Gallery</Text>
                        <Text className="text-xs font-normal text-gray-400">
                            Browse and select media files from your gallery for use in projects or updates.
                        </Text>
                   </Box>
                }
                bodyContent={

                    <>
                
                        <HStack className="mt-4 w-full flex-wrap"> 
                            {
                                data?.map((media, index) => (
                                    <Box  onClick={() => {
                                        setSelected(media?.link)
                                        onClose()
                                    }} key={index}>
                                        <Box px={2} className="min-w-[80px] min-h-[80px] overflow-hidden">
                                            <Center className={`border cursor-pointer hover:bg-gray-100 w-[70px] h-[70px] ${selected === media?.link && 'bg-gray-100'}`}>
                                                <img 
                                                    src={media?.link} 
                                                    alt={`Media ${index}`} 
                                                    className="w-[60px] h-[60px] object-cover" 
                                                />
                                            </Center>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </HStack>

                        <Box className="mt-10">
                            <PaginateAsset
                                total={total}
                                prev_page_url={prev_page_url}
                                next_page_url={next_page_url}
                                current_page={current_page}
                                last_page={last_page}
                                per_page={per_page}
                                onNavigate={(page) =>  defaultGetMedia(page)}
                            />
                        </Box>

                    </>

                }

                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={onClose} className="!bg-primary-950 !text-white">Close</Button>
                    </ButtonGroup>
                }

            />
    )

}


export default MediaGallery