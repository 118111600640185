import { useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber, removeEncodedSpace } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset, PromptAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link, useLocation, useNavigate} from "react-router-dom"
import CategoryForm from "@modules/product/components/Category/CategoryForm"
import { useProductService } from "@services/index"

const Details = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    const name = pathname?.split('/')[3]
    
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()

    const { getCategory, response:category } = useProductService()
    const { deleteCategory, response:deleted_category, load:delete_category_load } = useProductService()

    const { products } = category?.category || {}

    useEffect(() => {
        if (name) {
            getCategory({id: name})
        }
    }, [name])

    useEffect(() => {
        if (deleted_category) {
            onClosePrompt()
            navigate('/products/categories ')
            
        }
    }, [deleted_category])

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">Edit Category</Button>
            <Button onClick={onOpenPrompt} size={'sm'}>Delete</Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={`${removeEncodedSpace(name)} Details | View and Manage Category`}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={`${removeEncodedSpace(name)} Details`}
                        description={`Detailed view of ${removeEncodedSpace(name)}'s information and related data.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Products'}
                                number={localNumber({number: products?.length, decimals: 0})}
                                link={'/products'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Ordered Products'}
                                number={`${localNumber({ 
                                    number: products?.reduce((total, product) => 
                                        total + (product?.order_items?.reduce((sum, item) => sum + (item.quantity || 0), 0) || 0), 0
                                    ), 
                                    decimals: 0 
                                })}`}
                                link={'/orders'}
                            />
                        </Box>

                    </Box>

                    <Box>
                        {
                            products &&
                            <ProductList defaultItems={products} />
                        }
                    </Box>

                </Box>

               
           </Box>
           
           <CategoryForm isOpen={isOpen} onClose={onClose} category={category?.category} />

           <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                load={delete_category_load}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    deleteCategory({id: category?.category?.id})
                }}
            />
        
        </AuthenticatedLayout>
    )

}


export default Details