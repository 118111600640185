import { Checkbox, extendTheme } from '@chakra-ui/react'

export const primary = {
  '50': '#E0E7FF',
  '100': '#C9D2FF',
  '200': '#ADBBFF',
  '300': '#92A5FF',
  '400': '#778EFF',
  '500': '#121833',
  '600': '#4D64D4',
  '700': '#3D50AA',
  '800': '#2E3C80',
  '900': '#1F2855',
  '950': '#121833'
}

export const theme = extendTheme({

  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },

  styles: {
    global: {
      '*::-webkit-scrollbar': {
        width: '2px',
      },
      '*::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '*::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '2px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
      '.slick-dots li':{
        margin: '0px 2px!important'
      },
      '.slick-dots li button:before':{
        color: '#D9D9D9',
        opacity: 1
      },
      '.slick-dots li button:before':{
        fontSize: '8px!important'
      },
      '.slick-dots li.slick-active button:before':{
        color: primary[400],
        opacity: 1
      },
      '.slick-dots .slick-active >  button:before':{
        fontSize: '10px!important'
      },
      '.slick-next:before':{
        content: '""'
      },
      '.slick-prev:before':{
        content: '""'
      }

    }
  },

  colors: {
    primary
  },

  components: {
    Button: {
      sizes: {
        sm: {
          fontSize: "xs",
        },
        md: {
          fontSize: "sm",
        },
        lg: {
          fontSize: "md",
        },
      },
      variants: {
        solid: {
          bg: 'gray.50',
          color: 'primary.950',
          borderRadius: 0,
          _hover: {
            bg: 'primary.950',
            color: 'gray.50'
          },
          _active: {
            bg: 'primary.950',
            color: 'gray.50'
          },
        },
        outline: {
          borderRadius: 0
        },
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "primary"
      },
      baseStyle: {
        control: {
          _hover: {
            borderColor: "primary.950",
          },
          _checked: {
            bg: "primary.950",
            borderColor: "primary.950",
            _hover: {
              bg: "primary.950",
              borderColor: "primary.950",
            },
          },
        }
      }
    },
    Switch: {
      defaultProps: {
        colorScheme: "primary"
      },
      baseStyle: {
        control: {
          _hover: {
            borderColor: "primary.950",
          },
          _checked: {
            bg: "primary.950",
            borderColor: "primary.950",
            _hover: {
              bg: "primary.950",
              borderColor: "primary.950",
            },
          },
        }
      }
    },
    Avatar: {
      baseStyle: {
        bg: 'primary.960'
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: "0",
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          borderRadius: "0",
        },
      },
    },
    Textarea: {
      baseStyle: {
        borderRadius: "0"
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: "0",
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          borderRadius: "0",
        },
      },
    }
  },

  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },

})