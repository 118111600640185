import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/analytics/pages/Index'
import Users from '@modules/analytics/pages/Users'
import Orders from '@modules/analytics/pages/Orders'
import Products from '@modules/analytics/pages/Products'
import Sales from '@modules/analytics/pages/Sales'
import Designs from '@modules/analytics/pages/Designs';
import Support from '@modules/analytics/pages/Support';
import Payments from '@modules/analytics/pages/Payments';


const AnalyticsRoutes = [
    <Route path={'/analytics'}> 
        <Route index element={<Index />} />
        <Route path="overview" element={<Index />} />
        <Route path="users" element={<Users />} />
        <Route path="orders" element={<Orders />} />
        <Route path="products" element={<Products />} />
        <Route path="sales" element={<Sales />} />
        <Route path="designs" element={<Designs />} />
        <Route path="support" element={<Support />} />
        <Route path="payments" element={<Payments />} />
    </Route>
]

export default AnalyticsRoutes