import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack,
    Tab, TabPanel, TabPanels
} from "@chakra-ui/react"
import {SelectAsset, usePasswordToggle} from "@utils/assets"
import { FieldControl, clearInputErrors } from "@components/form"
import countries from '@utils/countries.json'
import { useUserService } from "@services/index"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"


const SecurityForm = ({user}) => {

    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            new_password: '',
            new_password_confirmation: ''
        }
    );

    const {togglePass:togglePassNew, PasswordToggle:PasswordToggleNew} = usePasswordToggle()
    const {togglePass:togglePassConfirm, PasswordToggle:PasswordToggleConfirm} = usePasswordToggle()

    const { updateUserPassword, requestErrors, load, response } = useUserService()
    
    const {validate, validationErrors} = useValidator(rules.change_password)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

        //Adds Validation Errors to Error State
        useEffect(() => {
            setError(validationErrors)
        }, [validationErrors])
    
        //Adds Request Errors to Error State
        useEffect(() => {
            setError(requestErrors)
        }, [requestErrors])

        useEffect(() => {
            response && setData(
                { 
                    new_password: '', 
                    new_password_confirmation: ''
                }
            )
        }, [response])
    
        //Disable Submit Button On Conditions
        const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;
    
        const submit = () => {
            if (!isDisabled) {
                clearInputErrors(setError)
                updateUserPassword({...data, id: user?.id})   
            }
        }

    return (

        <Box>
            
            <Box className="mb-5 lg:w-3/6 w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">

                <FieldControl 
                    error={errors?.new_password?.[0]}
                    label={'New Password'}
                    rightElementProps={{pr: 3}}
                    rightElement={
                        <PasswordToggleNew />
                    }
                >
                    <Input 
                        size={{base: 'lg', lg: 'md'}}
                        value={data.new_password} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}  
                        onChange={(event) => onHandleChange('new_password', event.target.value)} 
                        type={togglePassNew ? 'password': 'text'} 
                        fontSize={'xs'} 
                        placeholder="Enter new password"
                    />
                </FieldControl>

                <FieldControl 
                    error={errors?.new_password_confirmation?.[0]}
                    label={'Confirm New Password'}
                    rightElementProps={{pr: 3}}
                    rightElement={
                        <PasswordToggleConfirm />
                    }
                >
                    <Input 
                        size={{base: 'lg', lg: 'md'}}
                        value={data.new_password_confirmation} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}  
                        onChange={(event) => onHandleChange('new_password_confirmation', event.target.value)} 
                        type={togglePassConfirm ? 'password': 'text'} 
                        fontSize={'xs'} 
                        placeholder="Enter new password"
                    />
                </FieldControl>

            </Box>

            <HStack className="justify-end lg:w-3/6 w-full">
                <Button size={'sm'} onClick={submit} isLoading={load} isDisabled={isDisabled} className="!bg-primary-950 !text-white">
                    Change Password
                </Button>
            </HStack>

        </Box>

    )
    
}


export default SecurityForm