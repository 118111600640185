import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel, Input, Textarea, VStack} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { Link } from "react-router-dom"
import SettingsLayout from "@modules/settings/components/SettingsLayout"

const Support = () => {

    return (
        <SettingsLayout title={'Support'}>

            <VStack className="lg:w-3/6 w-full">
                
                <Box className="w-full">
                    <FieldControl label={'LiveChat Widget'}>
                        <Textarea 
                            size={'md'} 
                            fontSize={'xs'} 
                            placeholder="Paste livechat widget code" 
                        />
                    </FieldControl>
                </Box>

                <HStack className="w-full !justify-end !items-end">
                    <Button size={'sm'} className="!bg-primary-950 !text-white">
                        Update
                    </Button>
                </HStack>

            </VStack>
        
        </SettingsLayout>
    )

}

export default Support