"use client";
import { useEffect, useState } from "react";
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
  Menu, MenuButton, MenuList, MenuItem, useColorMode, Center, Heading, StackDivider,
  IconButton, Divider, Avatar, Input, Textarea, Checkbox
} from '@chakra-ui/react'
import { RiGoogleFill, RiAppleFill, RiFacebookCircleFill, RiStarFill, RiEyeLine, RiEyeOffLine } from "@remixicon/react";
import { FieldControl, clearInputErrors } from "@components/form";
import { BreadcrumbAsset, SearchAsset, ButtonAsset, usePasswordToggle } from "@utils/assets";
import { useAuthService, useAccountService } from "@services/index";
import { useValidator } from "@handlers/index";
import rules from "@utils/rules.json"
import GuestLayout from "@components/layout/Guest";


const NewPassword = () => {

    const {verifyToken, response:verification} = useAccountService()
    const {getVerificationCookie, requestErrors, load, newPassword, response} = useAuthService()

    const {token, otp} = getVerificationCookie()

    useEffect(() => {
        token && verifyToken({token: token})
    }, [token])

    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            new_password: '', 
            new_password_confirmation: ''
        }
    );

    const {togglePass, PasswordToggle} = usePasswordToggle()
    const {togglePass:togglePassConfirm, PasswordToggle:PasswordToggleConfirm} = usePasswordToggle()
    
    const {validate, validationErrors} = useValidator(rules.new_password)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {
        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            newPassword({...data, otp, target: verification?.target, channel: verification?.channel})
        }
    }


    return (
        <GuestLayout title={'Create A New Password'}>

            <VStack spacing={5} className="lg:w-10/12 w-full">

                <VStack spacing={4}>

                    <Box mb={5} className="w-10/12">
                        <Text className="font-bold text-2xl">Password Reset</Text>
                        <Text className="text-sm text-gray-400 ">
                            Create and confirm a new password to reset your AP Print Out account.
                        </Text>
                    </Box>

                </VStack>

                <VStack alignItems={'flex-start'} w={'100%'} spacing={8}>

                    <FieldControl 
                        error={errors?.new_password?.[0]}
                        labelProps={{fontSize: 'sm'}}
                        label={'New Password'} 
                        rightElementProps={{pr: 3}}
                        rightElement={
                            <PasswordToggle />
                        }
                    >
                        <Input 
                            value={data.new_password} 
                            onChange={(event) => onHandleChange('new_password', event.target.value)} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            type={togglePass ? 'password': 'text'} 
                            size={{base: 'lg', lg: 'md'}} 
                            fontSize={'sm'} 
                            placeholder="Create a new password" 
                            variant='flushed'
                        />
                    </FieldControl>

                    <FieldControl 
                        error={errors?.new_password_confirmation?.[0]}
                        labelProps={{fontSize: 'sm'}}
                        label={'Re-Type Password'} 
                        rightElementProps={{pr: 3}}
                        rightElement={
                            <PasswordToggleConfirm />
                        }
                    >
                        <Input 
                            value={data.new_password_confirmation} 
                            onChange={(event) => onHandleChange('new_password_confirmation', event.target.value)} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            type={togglePassConfirm ? 'password': 'text'} 
                            size={{base: 'lg', lg: 'md'}} 
                            fontSize={'sm'} 
                            placeholder="Re-enter new password" 
                            variant='flushed'
                        />
                    </FieldControl>
                
                </VStack>

                <VStack mt={10} spacing={4} w={'100%'}>
                    <Center w={'100%'}>
                        <ButtonAsset isLoading={load} onClick={submit} buttonProps={{isDisabled, w: '100%'}}>
                            Reset Password
                        </ButtonAsset>
                    </Center>
                </VStack>
            
            </VStack>

        </GuestLayout>
    )

}

export default NewPassword