import { useState, useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select,
    Switch
} from "@chakra-ui/react"
import {RiCloseFill, RiAddFill} from "@remixicon/react"
import {ModalAsset, SelectAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import UploadMedia from "@modules/media/components/UploadMedia"
import { useNavigate } from "react-router-dom"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useProductService } from "@services/index"

const SubCategoryForm = ({isOpen, onClose, sub_category}) => {

    const {isOpen: isMediaOpen, onOpen: onMediaOpen, onClose:onMediaClose} = useDisclosure()

    const navigate = useNavigate()
    
    const [errors, setError] = useState({})
    const [categories, setCategories] = useState([])
    const [data, setData] = useState(
        { 
            name: '', 
            description: '',
            tags: '',
            image: '',
            category_id: ''
        }
    );

    const { createSubCategory, response:updated_sub_category, requestErrors, load } = useProductService()
    const { getCategories, response:product_categories } = useProductService()
    const {validate, validationErrors} = useValidator(rules.create_sub_category)

    useEffect(() => {
        if (!categories?.length) getCategories() 
    }, [categories])

    useEffect(() => {
        if (product_categories && !categories?.length) setCategories(product_categories?.categories)
    }, [product_categories])

    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        if (updated_sub_category?.sub_category) {
            onClose()
            setData({ 
                name: '', 
                description: '',
                tags: '',
                image: '',
                category_id: ''
            })
            navigate(`/products/subcategories/${updated_sub_category?.sub_category?.name}`)
        }
    }, [updated_sub_category])

    useEffect(() => {
        if (sub_category) {
            setData({
                ...data,
                name: sub_category?.name,
                description: sub_category?.description,
                tags: sub_category?.tags,
                image: sub_category?.image,
                category_id: sub_category?.category_id
            })
        }
    }, [sub_category])

    const onHandleChange = (name, value) => {
        setData((prevData) => ({ ...prevData, [name]: value }));
        validate(name, value)
    };

    const onSelectMedia = (media) => {
        onHandleChange('image', media);
    }

    const submit = () => {
        if (!isDisabled) {
            createSubCategory({...data,  id: sub_category?.id})
        }
    }

    const isDisabled = load || Object.keys(data).some(field => 
        Array.isArray(data[field]) ? data[field].length === 0 : !data[field]
    ) || Object.keys(errors).length > 0;


    return (
        
        <Box>

            <ModalAsset
                arrowless
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                <Box>
                    <Text className="font-semibold">{sub_category ? `Edit ${sub_category?.name}` : 'Create Subcategory'}</Text>
                    <Text className="text-xs font-normal text-gray-400">
                        {sub_category ? 'Modify the details of this subcategory to keep your classifications up-to-date.' : 'Add a new subcategory to expand your product classifications and improve catalog organization.'}
                    </Text>
                </Box>
                }
                bodyContent={
                    <VStack spacing={7} className="!items-start !justify-center">
                                                
                        <FieldControl error={errors?.name?.[0]} label={'Sub Category name'}>
                            <Input 
                                fontSize={'xs'} 
                                placeholder="Enter sub category name" 
                                value={data.name}  
                                onChange={(event) => onHandleChange('name', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.image?.[0]} label={'Sub Category image'}>
                            <Box className="w-[90px] h-[90px] border p-2 relative">
                                {
                                    data?.image &&
                                    <HStack className="z-20 absolute top-2 right-2 cursor-pointer">
                                        <RiCloseFill onClick={() => onHandleChange('image', '')} size={20} className="text-red-500" />
                                    </HStack>
                                }
                                <VStack className="h-full !justify-center !items-center">
                                    <Box onClick={onMediaOpen} className="cursor-pointer">
                                        {
                                            data?.image ? 
                                            <img src={data?.image} width={50} /> :
                                            <img src={'/bg/products/no-image.png'} width={50} />
                                        }
                                    </Box>
                                </VStack>
                            </Box>
                        </FieldControl>

                        <FieldControl error={errors?.category_id?.[0]} label={'Category name'}>
                                <Select
                                    onChange={(event) => onHandleChange('category_id', event.target.value)} 
                                    value={data?.category_id} 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    placeholder="Select Category"
                                >
                                    {
                                        categories?.map((category, index) => (
                                            <option value={category?.id} className="capitalize">{category?.name}</option>
                                        ))
                                    }
                                </Select>
                        </FieldControl>

                        <FieldControl error={errors?.description?.[0]} label={'Sub Category Description'}>
                            <Textarea 
                                fontSize={'xs'} 
                                placeholder="Enter sub category description" 
                                value={data.description}  
                                onChange={(event) => onHandleChange('description', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.tags?.[0]} label={'Sub category tags (separate with commas)'}>
                            <Textarea 
                                fontSize={'xs'} 
                                placeholder="Enter sub category tags" 
                                value={data.tags}  
                                onChange={(event) => onHandleChange('tags', event.target.value)} 
                            />
                        </FieldControl>

                    </VStack>
                }
                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={onClose} className="border">Cancel</Button>
                        <Button size={'sm'} onClick={submit} isDisabled={isDisabled} isLoading={load} className="!bg-primary-950 !text-white">Save Changes</Button>
                    </ButtonGroup>
                }

            />

            <UploadMedia isOpen={isMediaOpen} onClose={onMediaClose} onSelectMedia={onSelectMedia} />

        </Box>

    )

}


export default SubCategoryForm