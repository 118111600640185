import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PromptAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill} from "@remixicon/react"
import { Link } from "react-router-dom"
import { useUserService } from "@services/index"

const UsersList = ({title, description, defaultItems}) => {

    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)

    const filters = ['active', 'inactive']    
    
    const [filter, setFilter] = useState({})
    const {getUsers, response} = useUserService()
    const {deleteUser, response:deleted_user, load} = useUserService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.users?.data)

    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.users || {}

    useEffect(() => {
        if (!defaultItems) {
            getUsers(filter)
        }else{
            setSearchItems(defaultItems)
        }
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    useEffect(() => {
        if (deleted_user) {
            getUsers()
            setItem(null)
            onClosePrompt()
        }
    }, [deleted_user])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            if (value) {
                updatedFilter[name] = value;
            } else {
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    }

    const FilterView = () => {

        return (

            
            <HStack className="justify-between items-center w-full py-2" spacing={5}>

                <HStack spacing={5}>
                
                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Status</Text>}
                        menuContent={
                            <CheckboxGroup onChange={(value) => onHandleFilter('status', value)} value={filter?.status}>
                                {
                                    filters.map((filter, index) => (
                                        <MenuItem key={index}>
                                            <Checkbox value={filter} size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                        </MenuItem>
                                    ))
                                }
                            </CheckboxGroup>
                        }
                    />

                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Date/Time</Text>}
                        menuContent={
                            <VStack spacing={5} className="!px-4 !py-2 items-center">
                                <Box>
                                    <Text className="text-center text-xs">From</Text>
                                    <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>

                                <Box>
                                    <Text className="text-center text-xs">To</Text>
                                    <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>
                            </VStack>
                        }
                    />

                </HStack>

                <Box className="lg:block hidden" spacing={5}>

                    {SearchView({placeholder: 'Search for users'})}
                    
                </Box>

            </HStack>

        )
    }

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Username</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Full Name</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Email Address</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Phone</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Country</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Reg Date</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>

                    {
                        results?.map((user, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index + 1 + (per_page || 0) * ((current_page || 1) - 1)}</Td>
                                <Td>{user?.username}</Td>
                                <Td>
                                    <HStack>
                                        <Avatar size={'sm'} name={`${user?.first_name} ${user?.last_name}`} />
                                        <Link to={`/users/${user?.username}`}>
                                            <Text className="underline">{`${user?.first_name} ${user?.last_name}`}</Text>
                                        </Link>
                                    </HStack>
                                </Td>
                                <Td>{user?.email}</Td>
                                <Td>{user?.phone}</Td>
                                <Td>{user?.country}</Td>
                                <Td>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(user?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{user?.status ? 'Active' : 'Inactive'}</Text>
                                    </Badge>
                                </Td>
                                <Td>{formatDate({date: user?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>

                                <Td isNumeric>

                                    <MenuAsset
                                        triggerProps={{className: '!text-xs', size: 'sm'}}
                                        trigger={Button}
                                        triggerContent={<RiMoreFill size={20} />}
                                        listProps={{
                                            className: '!min-w-[120px]'
                                        }}
                                        menuContent={
                                            <>  
                                                <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Link to={`/users/${user.username.toLowerCase()}`}>
                                                        <Text className="text-xs font-medium capitalize">More Details</Text>
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Link to={`/users/edit/${user.username.toLowerCase()}`}>
                                                        <Text className="text-xs font-medium capitalize">Edit User</Text>
                                                    </Link>
                                                </MenuItem>

                                                <MenuDivider />

                                                <Center>
                                                    <Button onClick={() => {
                                                        setItem(user)
                                                        onOpenPrompt()
                                                    }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                                        Delete
                                                    </Button>
                                                </Center>
                                            </>
                                        }
                                    />
                                    
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getUsers({filter, page})}
                />
            }
        />
    )

    return (
        <Box>   

            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "User List"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "View and manage all user accounts, including details and status."}</Text>
            </Box>
            
            {FilterView()}

            {TableView()}

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={() => {
                    onClosePrompt()
                    setItem(null)
                }}
                load={load}
                title={'Please confirm'}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    deleteUser({id: item?.id})
                }}
            />
            
        </Box>
    )

}


export default UsersList