"use client";
import { useState, useEffect } from "react";
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
  Menu, MenuButton, MenuList, MenuItem, useColorMode, Center, Heading, StackDivider,
  IconButton, Divider, Avatar, Input, Textarea, Checkbox, useDisclosure
} from '@chakra-ui/react'
import { Link } from "react-router-dom";
import { RiGoogleFill, RiAppleFill, RiFacebookCircleFill } from "@remixicon/react";
import { FieldControl, clearInputErrors } from "@components/form";
import { ButtonAsset, usePasswordToggle } from "@utils/assets";
import rules from "@utils/rules.json"
import { useAuthService } from "@services/index";
import { useValidator } from "@handlers/index";
import GuestLayout from "@components/layout/Guest";


const Login = () => {
    
    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            email: '', 
            password: ''
        }
    );

    const { requestErrors, load, login, response } = useAuthService()

    const {togglePass, PasswordToggle} = usePasswordToggle()
    
    const {validate, validationErrors} = useValidator(rules.login)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            login(data)   
        }
    }

    return (
        <GuestLayout title={'Login To Your Account'}>

            <VStack spacing={5} className="lg:w-10/12 w-full">

                <VStack spacing={4}>

                    <Box mb={5} className="w-10/12">
                        <Text className="font-bold text-2xl">Welcome Back</Text>
                        <Text className="text-sm text-gray-400">Enter your admin login credentials to continue.</Text>
                    </Box>

                </VStack>

                <VStack alignItems={'flex-start'} w={'100%'} spacing={8}>

                    <FieldControl error={errors?.email?.[0]} label={'Email Address'} labelProps={{fontSize: 'sm'}}>
                        <Input 
                            size={{base: 'lg', lg: 'md'}} 
                            value={data.email} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('email', event.target.value)} 
                            type="email" 
                            fontSize={'sm'} 
                            placeholder="Enter your email address" 
                            variant='flushed'
                        />
                    </FieldControl>

                    <FieldControl 
                        error={errors?.password?.[0]}
                        labelProps={{fontSize: 'sm', me: 0}}
                        label={
                            <HStack justifyContent={'space-between'}>
                                <Text>Password</Text>
                                <Link to={'/auth/reset-password'} className="text-primary-500">Forgot Password</Link>
                            </HStack>
                        } 
                        rightElementProps={{pr: 3}}
                        rightElement={
                            <PasswordToggle />
                        }
                    >
                        <Input 
                            size={{base: 'lg', lg: 'md'}} 
                            value={data.password} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}  
                            onChange={(event) => onHandleChange('password', event.target.value)} 
                            type={togglePass ? 'password': 'text'} 
                            fontSize={'sm'} 
                            placeholder="Enter your password" 
                            variant='flushed'
                        />
                    </FieldControl>

                    <Checkbox size={'sm'}>Keep me logged in</Checkbox>
                
                </VStack>

                <VStack mt={10} spacing={4} w={'100%'}>
                    <Center w={'100%'}>
                        <ButtonAsset onClick={submit} buttonProps={{w: '100%', isLoading: load, isDisabled}}>
                            Log In
                        </ButtonAsset>
                    </Center>
                </VStack>

            </VStack>
        
        </GuestLayout>
    )

}

export default Login