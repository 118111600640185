import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import TicketList from "@modules/support/components/TicketList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"

const Index = () => {

    const PageHeaderAction = () => (

        <MenuAsset
            triggerProps={{className: '!text-xs', size: 'sm'}}
            trigger={Button}
            triggerContent={<Text>Switch Date</Text>}
            listProps={{
                className: '!min-w-[180px]'
            }}
            menuContent={
                <>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">24Hrs</Text>
                    </MenuItem>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">1 Week</Text>
                    </MenuItem>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">1 Month</Text>
                    </MenuItem>
                </>
            }
        />
    )

    return (
        <AuthenticatedLayout title={'Payments'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Support Tickets'}
                        description={`Manage support tickets on this page`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Pending Tickets'}
                                number={localNumber({number: 1582})}
                                helper={'26% In 24 Hours'}
                                arrow={'increase'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Open Tickets'}
                                number={localNumber({number: 1582})}
                                helper={'10% In 24 Hours'}
                                arrow={'decrease'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Closed Tickets'}
                                number={localNumber({number: 1582})}
                                helper={'10% In 24 Hours'}
                                arrow={'decrease'}
                            />
                        </Box>

                    </Box>

                    <Box>

                        <TicketList />

                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Index