import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, VStack, Td, 
    Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { RiArrowRightSLine, RiArrowLeftSLine } from "@remixicon/react"
import { TableAsset, MenuAsset, SearchListAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill } from "@remixicon/react"
import { Link } from "react-router-dom"
import { useProductService } from "@services/index"

const ProductList = ({title, description, defaultItems}) => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)
    const [filters, setFilters] = useState({
        status: ['active', 'inactive'],
        sort: ['highest', 'lowest', 'new arrivals'],
        category: [],
        sub_category: []
    })

    const [ filter, setFilter ] = useState({})
    const { getProducts, response, load } = useProductService()
    const { getCategories, response:categories } = useProductService()
    const { getSubCategories, response:sub_categories } = useProductService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.products?.data ?? defaultItems)

    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.products || {}

    useEffect(() => {
        if (!defaultItems) {
            getCategories()
            getSubCategories()
        }
    }, [])

    useEffect(() => {
        
        if (categories?.categories && sub_categories?.sub_categories) {
            setFilters((prevData) => ({
                ...prevData,
                category: categories?.categories?.map(({ name }) => name),
                sub_category: sub_categories?.sub_categories?.map(({ name }) => name),
            }));
        }

    }, [categories, sub_categories])

    useEffect(() => {
        if (!defaultItems) {
            getProducts(filter)
        }else{
            setSearchItems(defaultItems)
        }
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const nextPage = (page) => {
        
        getProducts({filter, page})

    }

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            // If value is not empty, set the filter state
            if (value && value.length > 0) {
                updatedFilter[name] = value;
            } else {
                // If value is empty or falsy, delete the filter key
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    };
    
    const FilterView = () => (

        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            {
                !defaultItems && 

                <HStack spacing={5}>
                    { 
                        Object.keys(filters)?.map((title, index) => (
                            <Box key={index}>
                                <MenuAsset
                                    triggerProps={{className: '!text-xs capitalize', size: 'sm'}}
                                    trigger={Button}
                                    triggerContent={<Text>{title.replace("_", " ")}</Text>}
                                    menuContent={
                                        <CheckboxGroup 
                                            onChange={(value) => onHandleFilter(title, value)} 
                                            value={filter[title] || []} // Pass the corresponding filter state here
                                        >
                                            {filters[title].map((filterItem, filterIndex) => (
                                                <MenuItem key={filterIndex}>
                                                    <Checkbox value={filterItem} size={'lg'}>
                                                        <Text className="text-xs font-medium capitalize">{filterItem}</Text>
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                        </CheckboxGroup>
                                    }
                                />
                            </Box>
                        ))
                    }


                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Date/Time</Text>}
                        menuContent={
                            <VStack spacing={5} className="!px-4 !py-2 items-center">
                                <Box>
                                    <Text className="text-center text-xs">From</Text>
                                    <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>

                                <Box>
                                    <Text className="text-center text-xs">To</Text>
                                    <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>
                            </VStack>
                        }
                    />

                </HStack>

            }

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for products'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Name</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Image</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Category</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Sub Category</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                    {   

                        results?.map((product, index) => (
                            
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index + 1 + (per_page || 0) * ((current_page || 1) - 1)}</Td>
                                <Td>
                                    <Link className="underline" to={`/products/${product?.name}`}>{product?.name}</Link>
                                </Td>
                                <Td>
                                    {
                                        product?.images?.length ? 
                                            <Link to={`/products/${product?.name}`}>
                                                <img src={product?.images[0]} alt={product?.name} width={40} />
                                            </Link>
                                        :
                                            <img src={'/bg/products/no-image.png'} width={50} />

                                    }
                                </Td>
                                <Td>
                                    {
                                        product?.category?.name ? 
                                            <Link className="underline" to={`/products/categories/${product?.category?.name}`}>{product?.category?.name}</Link>
                                        :
                                            'None'
                                    }
                                </Td>
                                <Td>
                                    {
                                        product?.sub_category?.name ?
                                            <Link className="underline" to={`/products/subcategories/${product?.sub_category?.name}`}>{product?.sub_category?.name}</Link>
                                        :
                                            'None'
                                    }
                                </Td>
                                <Td textAlign={{base: 'right', lg: 'left'}}>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(product?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{product?.status ? "Active" : 'Inactive'}</Text>
                                    </Badge>
                                </Td>
                                <Td>{formatDate({date: product?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>
                                <Td isNumeric>
                                    
                                    <MenuAsset
                                        triggerProps={{className: '!text-xs', size: 'sm'}}
                                        trigger={Button}
                                        triggerContent={<RiMoreFill size={20} />}
                                        listProps={{
                                            className: '!min-w-[120px]'
                                        }}
                                        menuContent={
                                            <>  
                                                <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Link to={`/products/${product.name.toLowerCase()}`}>
                                                        More Details
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Link to={`/products/edit/${product?.name}`}>
                                                        Edit Product
                                                    </Link>
                                                </MenuItem>
                                            </>
                                        }
                                    />

                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getProducts({filter, page})}
                />
            }
        />
    )

    return (
        <Box>   

            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "Product Inventory"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "View a detailed table of product listings and management options."}</Text>
            </Box>

            {FilterView()}

            {TableView()}
            
        </Box>
    )

}


export default ProductList