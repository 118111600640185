import "@styles/globals.css";
import 'ckeditor5/ckeditor5.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import AppRoute from './Routes';
import { Provider } from 'react-redux'
import store from "@redux/store";
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { theme } from "@utils/theme";

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={'light'} />
        <AppRoute />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
