import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiUploadCloudFill, RiExpandUpDownLine, RiCloseFill,
    RiAddFill, RiSubtractFill, RiPaintBrushFill, RiPrinterFill, RiFilterFill, RiSparklingFill,
    RiArrowDownSLine
} from "@remixicon/react"
import {ModalAsset, SelectAsset} from "@utils/assets"
import { FieldControl, clearInputErrors } from "@components/form"
import rules from "@utils/rules.json"
import countries from '@utils/countries.json'
import { useUserService } from "@services/index"
import { useValidator } from "@handlers/index"

const AddressForm = ({isOpen, onClose, shipping_address, user, getUser, setItem}) => {

    const { first_name, last_name, address, city, state, country, zip_code, phone } = shipping_address || {}
    
    const [errors, setError] = useState({})

    const [data, setData] = useState({});

    const { createUserAddress, requestErrors, load, response } = useUserService()
    
    const {validate, validationErrors} = useValidator(rules.create_shipping_info)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        if(name === 'state'){
            countries?.find(country => country?.name === data?.country)?.states?.map((state) => {
                if(state.name === value){
                    return setData((prevData) => ({ ...prevData, city: state.cities?.[0] ?? data?.city }));
                }
            })
        }

        if(name === 'country'){

            countries.map((country) => {
                if(country.name === value){
                    return setData((prevData) => ({ ...prevData, state: country.states?.[0]?.name ?? '' }));
                }
            })

            countries.map((country) => {
                if(country.name === value){
                    return setData((prevData) => ({ ...prevData, city: country.states?.[0]?.cities?.[0] ?? data?.city }));
                }
            })
        }

        //Validate Input
        validate(name, value)
    };

    //Add User Data to State
    useEffect(() => {
       if (shipping_address) {
            setData(data => (
                {
                    ...data,
                    first_name, 
                    last_name, 
                    address, 
                    city, 
                    state, 
                    country,
                    zip_code, 
                    phone
                }
            ))
       }
    }, [shipping_address])

    useEffect(() => {
        if (response) {
            setData({})
            onClose()
            setItem(null)
            getUser({username: user?.username})
        }
    }, [response])

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])
    

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => (field != 'phone' && field != 'zip_code') && !data[field] ) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            createUserAddress({...data, user_id: user?.id, address_id: shipping_address?.id})   
        }
    }

    return (
        
        <ModalAsset
            arrowless
            modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
            headerContent={
            <Box>
                <Text className="font-semibold">Shipping Form</Text>
                <Text className="text-xs font-normal text-gray-400">Fill in all required info in the form fields below.</Text>
            </Box>
            }
            bodyContent={
                <Box>
                    
                    <Box className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">
                        
                        <FieldControl error={errors?.first_name?.[0]} label={'First Name'}>
                            <Input 
                                size={{base: 'lg', lg: 'md'}}  
                                fontSize={'xs'} 
                                placeholder="Enter first name" 
                                value={data?.first_name}
                                onKeyDown={(e) => e.code === "Enter" && submit()}   
                                onChange={(event) => onHandleChange('first_name', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.last_name?.[0]} label={'Last name'}>
                            <Input 
                                size={{base: 'lg', lg: 'md'}}  
                                fontSize={'xs'} 
                                placeholder="Enter last name" 
                                value={data?.last_name}
                                onKeyDown={(e) => e.code === "Enter" && submit()}   
                                onChange={(event) => onHandleChange('last_name', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.country?.[0]} label={'Country'}>
                            <SelectAsset 
                                value={data?.country}
                                options={countries?.map(country => ({value: country?.name, label: country?.name}))}
                                isSearch
                                size={'md'}
                                placeholder={'Select Country'}
                                onChange={(selected) => onHandleChange('country', selected)}
                            />
                        </FieldControl>

                        <FieldControl error={errors?.state?.[0]} label={'State/Province'}>
                            <SelectAsset 
                                value={data?.state}
                                options={countries?.find(country => country?.name === data?.country)?.states?.map(state => ({value: state?.name, label: state?.name}))}
                                onChange={(selected) => onHandleChange('state', selected)}
                                isSearch
                                size={'md'}
                                placeholder={'Select State/Province'}
                            />
                        </FieldControl>

                        <FieldControl error={errors?.city?.[0]} label={'City'}>

                            {
                                countries?.find(country => country?.name === data?.country)?.states?.filter(state => state?.cities)?.length ? 

                                    <SelectAsset 
                                        value={data?.city}
                                        options={
                                            countries?.find(country => country?.name === data?.country)
                                            ?.states?.find(state => state?.name === data?.state)
                                            ?.cities?.map(city => ({value: city, label: city}))
                                        }
                                        onChange={(selected) => onHandleChange('city', selected)}
                                        isSearch
                                        size={'md'}
                                        placeholder={'Select State/Province'}
                                    />
                                :

                                <Input 
                                    size={{base: 'lg', lg: 'md'}}  
                                    fontSize={'xs'} 
                                    placeholder="Enter city" 
                                    value={data?.city}
                                    onChange={(event) => onHandleChange('city', event.target.value)} 
                                />

                            }
                            
                        </FieldControl>

                        <FieldControl error={errors?.zip_code?.[0]} label={'Zip Code'}>
                            <Input 
                                size={{base: 'lg', lg: 'md'}}  
                                fontSize={'xs'} 
                                placeholder="Enter zip code" 
                                value={data?.zip_code}
                                onKeyDown={(e) => e.code === "Enter" && submit()}   
                                onChange={(event) => onHandleChange('zip_code', event.target.value)} 
                            />
                        </FieldControl>

                    </Box>

                    <Box className="my-5">
                        <FieldControl label={'Phone Number'}>
                            <Input 
                                size={{base: 'lg', lg: 'md'}}  
                                fontSize={'xs'} 
                                placeholder="Enter phone number" 
                                value={data?.phone}
                                onKeyDown={(e) => e.code === "Enter" && submit()}   
                                onChange={(event) => onHandleChange('phone', event.target.value)} 
                            />
                        </FieldControl>
                    </Box>
                    
                    <Box className="mb-10">
                        <FieldControl error={errors?.phone?.[0]} label={'Address'}>
                            <Textarea 
                                size={{base: 'lg', lg: 'md'}}  
                                fontSize={'xs'} 
                                placeholder="Enter address" 
                                value={data?.address}
                                onKeyDown={(e) => e.code === "Enter" && submit()}   
                                onChange={(event) => onHandleChange('address', event.target.value)} 
                            />
                        </FieldControl>
                    </Box>

                </Box>
            }
            footerContent={
                <ButtonGroup>
                    <Button size={'sm'} onClick={() => {
                        onClose()
                        setData({})
                        setItem(null)
                    }} className="border">Cancel</Button>
                    <Button size={'sm'} onClick={submit} isLoading={load} isDisabled={isDisabled} className="!bg-primary-950 !text-white">
                        Save Changes
                    </Button>
                </ButtonGroup>
            }

        />

    )

}


export default AddressForm