import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PromptAsset, PaginateAsset, LinkAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors, toSingular} from "@utils/helpers"
import { RiMoreLine, RiMoreFill} from "@remixicon/react"
import { Link } from "react-router-dom"
import { useUserService } from "@services/index"

const ActivityList = () => {

    const [ filter, setFilter ] = useState({})

    const filters = {
        status: ['active'],
        type: ['orders', 'payments', 'designs', 'users', 'reviews'],
    } 
    
    const {getActivities, response, load} = useUserService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.activities?.data)

    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.activities || {}

    useEffect(() => {
        getActivities(filter)
    }, [filter])

    useEffect(() => {
        if (data) setSearchItems(data)
    }, [data])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            if (value) {
                updatedFilter[name] = value;
            } else {
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    }

    const FilterView = () => {

        return (

            
            <HStack className="justify-between items-center w-full py-2" spacing={5}>

                <HStack spacing={5}>
                    { 
                        Object.keys(filters)?.map((title, index) => (
                            <Box key={index}>
                                <MenuAsset
                                    triggerProps={{className: '!text-xs capitalize', size: 'sm'}}
                                    trigger={Button}
                                    triggerContent={<Text>{title.replace("_", " ")}</Text>}
                                    menuContent={
                                        <CheckboxGroup 
                                            onChange={(value) => onHandleFilter(title, value)} 
                                            value={filter[title] || []} // Pass the corresponding filter state here
                                        >
                                            {filters[title].map((filterItem, filterIndex) => (
                                                <MenuItem key={filterIndex}>
                                                    <Checkbox value={filterItem} size={'lg'}>
                                                        <Text className="text-xs font-medium capitalize">{filterItem}</Text>
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                        </CheckboxGroup>
                                    }
                                />
                            </Box>
                        ))
                    }


                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Date/Time</Text>}
                        menuContent={
                            <VStack spacing={5} className="!px-4 !py-2 items-center">
                                <Box>
                                    <Text className="text-center text-xs">From</Text>
                                    <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>

                                <Box>
                                    <Text className="text-center text-xs">To</Text>
                                    <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>
                            </VStack>
                        }
                    />

                </HStack>

                <Box className="lg:block hidden" spacing={5}>

                    {SearchView({placeholder: 'Search for notifications'})}
                    
                </Box>

            </HStack>

        )
    }

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Title</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Description</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">User</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date/Time</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>

                    {
                        results?.map((activity, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td className="capitalize">{`${toSingular(activity?.object_type)?.replace('_', ' ')} ${activity?.action}`}</Td>
                                <Td>{activity?.user?.first_name} {activity?.description}</Td>
                                <Td>
                                    <HStack>
                                        <Avatar size={'sm'} name={`${activity?.user?.first_name} ${activity?.user?.last_name}`} />
                                        <Link to={`/users/${activity?.user?.username}`}>
                                            <Text className="underline">{`${activity?.user?.first_name} ${activity?.user?.last_name}`}</Text>
                                        </Link>
                                    </HStack>
                                </Td>
                                <Td>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(activity?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{activity?.status ? 'Active' : 'Inactive'}</Text>
                                    </Badge>
                                </Td>
                                <Td>{formatDate({date: activity?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>

                                <Td isNumeric>

                                    <LinkAsset href={activity?.metadata?.link}>
                                        Link
                                    </LinkAsset>
                                    
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getActivities({filter, page})}
                />
            }
        />
    )

    return (
        <Box>   

            {FilterView()}

            {TableView()}
            
        </Box>
    )

}


export default ActivityList