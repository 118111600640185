import { useEffect, useRef , useMemo } from 'react';
import {Box} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom';

const Layout = ({children, pathname, title, description}) => {

    const location = useLocation()
    const authRoutes = location.pathname.startsWith('/auth')

    useEffect(() => {
        document.title = `${title} | AP Print Out Admin`;
    }, []);

    return (
        <Box className={'w-full h-screen'}>

            {children}

        </Box>
    )

}

export default Layout