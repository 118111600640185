export const DarkFavicon = (props) => {
    return (
        <img
            width={141.46}
            height={40}
            {...props}
            src={require('../public/logos/dark-favicon.png')}
            alt="Light Logo"
        />
    )
}


export const DarkLogo = (props) => {
    return (
        <img
            width={141.46}
            height={40}
            {...props}
            src={require('../public/logos/dark-logo.png')}
            alt="Dark Logo"
        />
    )
}

export const LightLogo = (props) => {
    return (
        <img
            width={141.46}
            height={40}
            {...props}
            src={require('../public/logos/light-logo.png')}
            alt="Light Logo"
        />
    )
}

export const DarkFooterLogo = (props) => {
    return (
        <img
            {...props}
            src={require('../public/logos/dark-logo-footer.png')}
            alt="Light Logo"
        />
    )
}