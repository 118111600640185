import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel, Input} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import {connect} from 'react-redux'
import { StatAsset, StatCard, MenuAsset, TabAsset, ButtonAsset} from "@utils/assets"
import { FieldControl, clearInputErrors} from "@components/form"
import AccountLayout from "@modules/account/components/AccountLayout"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useAccountService } from "@services/index"
import PageHeader from "@components/header/components/PageHeader"

const Index = ({accountReducer}) => {

    const { user } = accountReducer || {}
    const { first_name, last_name, email, username } = user || {}

    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            first_name: first_name, 
            last_name: last_name
        }
    );

    const { updateAccount, requestErrors, load, response } = useAccountService()
    
    const {validate, validationErrors} = useValidator(rules.update_profile)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Add User Data to State
    useEffect(() => {
       if (first_name && last_name) {
            onHandleChange('first_name', first_name)
            onHandleChange('last_name', last_name)
       }
    }, [user])

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            updateAccount(data)   
        }
    }

    return (
        <AccountLayout title={'Profile | Manage Your Personal Information'}>

            <Box className="mb-5">
                <PageHeader
                    title={<Text className="text-sm">Profile</Text>}
                    description={
                        <Text className="text-xs text-gray-500">Update and manage your personal details, including name, contact information, and preferences.</Text>
                    }
                />
            </Box>

            <Box className="mb-5 lg:w-3/6 w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">
                
                <FieldControl error={errors?.first_name?.[0]} label={'First Name'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}} 
                        fontSize={'xs'} 
                        placeholder="Enter first name" 
                        value={data.first_name} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('first_name', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl error={errors?.last_name?.[0]} label={'Last name'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}} 
                        fontSize={'xs'} 
                        placeholder="Enter last name" 
                        value={data.last_name} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('last_name', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl label={'Email Address'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}} 
                        fontSize={'xs'} 
                        placeholder="Enter email address" 
                        value={email}
                        disabled
                    />
                </FieldControl>

                <FieldControl label={'Username'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}} 
                        fontSize={'xs'} 
                        placeholder="Enter username" 
                        value={username}
                        disabled
                    />
                </FieldControl>

            </Box>

            <HStack className="justify-end lg:w-3/6 w-full">
                <ButtonAsset onClick={submit} buttonProps={{isLoading: load, isDisabled, size: 'sm'}}>
                    Update Profile
                </ButtonAsset>
            </HStack>
        
        </AccountLayout>
    )

}

const mapStateToProps = (state) => {
    const { accountReducer } = state
    return { accountReducer }
};

export default connect(mapStateToProps)(Index)