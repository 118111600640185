import { useState } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, VStack, Td, Badge, Avatar, useDisclosure} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine } from "@remixicon/react"
import { Link } from "react-router-dom"
import OrderItemDetails from '@modules/order/components/OrderItemDetails'
import { orderAmount } from '@modules/order/utils/OrderUtils';

const CartList = ({title, description, defaultItems}) => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)
    
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(defaultItems)

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">ID</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Item</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Amount</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                    {
                        results?.map((item, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{item?.id}</Td>
                                <Td>
                                    <Link to={`/products/${item?.product?.name}`}>
                                        <HStack>
                                            <img src={item?.product?.images[0]} alt={item?.product?.name} width={40} />
                                            <Box>
                                                <Text className="underline">{item?.product?.name}</Text>
                                                <Text className="text-gray-400">{item?.metadata?.sizes.reduce((quantity, size) => quantity + size.quantity, 0)} Piece(s)</Text>
                                            </Box>
                                        </HStack>
                                    </Link>
                                </Td>
                                <Td>₦{localNumber({number: orderAmount({...item.metadata, product: item.product}) })}</Td>
                                <Td>{formatDate({date: item?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>
                                <Td isNumeric>
                                    <Button onClick={() => {
                                        setItem(item)
                                        onOpen()
                                    }} size={'sm'}>More Details</Button>
                                </Td>
                            </Tr>
                        ))
                    }
                </>
            }
            footerContent={<></>}
        />
    )

    return (
        <Box>   
            
            {TableView()}

            <OrderItemDetails isOpen={isOpen} onClose={onClose} item={item} />
            
        </Box>
    )

}


export default CartList