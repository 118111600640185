import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import DesignList from "@modules/design/components/DesignList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"

const List = () => {

    return (
        <AuthenticatedLayout title={'Design Submissions | Comprehensive List of Uploaded Designs'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Design Submissions'}
                        description={`Browse all user-submitted designs in a structured list, with options to review and manage each.`}
                    />
                </Box>


                <Box className="content">

                    <DesignList title={''} description={''} />

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default List