import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Authentication Routes
import AuthRoutes from '@modules/authentication/routes';

//Admin Routes
import AccountRoutes from '@modules/account/routes';

//Analytics Routes
import AnalyticsRoutes from '@modules/analytics/routes';

//CMS Routes
import CMSRoutes from '@modules/cms/routes';

//Order Routes
import OrderRoutes from '@modules/order/routes';

//Design Routes
import DesignRoutes from '@modules/design/routes';

//Payment Routes
import PaymentRoutes from '@modules/payment/routes';

//Product Routes
import ProductRoutes from '@modules/product/routes';

//Support Routes
import SupportRoutes from '@modules/support/routes';

//Users Routes
import UserRoutes from '@modules/users/routes';

//Settings Routes
import SettingsRoutes from '@modules/settings/routes';

//Media Routes
import MediaRoutes from '@modules/media/routes';

//Dashboard Route
import Dashboard from '@pages/Dashboard';

//Activity Route
import Activity from '@modules/users/pages/Activity';

//404 Not Found Route
import NotFound from '@pages/NotFound';

//Router Middleware
import Middleware from '@utils/middleware';


const AppRoute = () => {
    return (
      <Router>
        <Routes>
          
          <Route element={<Middleware />}>

              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/notifications" element={<Activity />} />

              {AuthRoutes}

              {AccountRoutes}

              {AnalyticsRoutes}

              {CMSRoutes}

              {OrderRoutes}

              {DesignRoutes}

              {PaymentRoutes}

              {ProductRoutes}

              {SupportRoutes}

              {UserRoutes}

              {SettingsRoutes}

              {MediaRoutes}

          </Route>

          <Route path="*" element={<NotFound />} />

        </Routes>
      </Router>
    );
};

export default AppRoute