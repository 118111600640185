import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiUploadCloudFill, RiExpandUpDownLine, RiCloseFill,
    RiAddFill, RiSubtractFill, RiPaintBrushFill, RiPrinterFill, RiFilterFill, RiSparklingFill,
    RiArrowDownSLine
} from "@remixicon/react"
import rules from "@utils/rules.json"
import {ModalAsset, ButtonAsset, SelectAsset} from "@utils/assets"
import { FieldControl, clearInputErrors} from "@components/form"
import { useValidator } from "@handlers/index"
import { useAccountService } from "@services/index"

const AdminInvite = ({isOpen, onClose, getAdmins}) => {

    const roles = [
        {
            label: "Super Admin",
            value: "super admin"
        },
        // {
        //     label: "Support Agent",
        //     value: "support agent"
        // },
        // {
        //     label: "Design Manager",
        //     value: "design manager"
        // },
        // {
        //     label: "Content Manager",
        //     value: "content manager"
        // },
        // {
        //     label: "Order Manager",
        //     value: "order manager"
        // },
        // {
        //     label: "Payment Admin",
        //     value: "payment admin"
        // },
        // {
        //     label: "Product Manager",
        //     value: "product manager"
        // },
        // {
        //     label: "Shipping Admin",
        //     value: "shipping admin"
        // },
        // {
        //     label: "User Manager",
        //     value: "user manager"
        // },
        // {
        //     label: "Operations Manager",
        //     value: "operations manager"
        // }
    ];

    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            first_name: '', 
            last_name: '',
            email: '',
            roles: [] 
        }
    );

    const { inviteAdmin, requestErrors, load, response } = useAccountService()
    
    const {validate, validationErrors} = useValidator(rules.invite_admin)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    useEffect(() => {
        if (response) {
            onClose()
            getAdmins()
        }
    }, [response])

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => 
        Array.isArray(data[field]) ? data[field].length === 0 : !data[field]
    ) || Object.keys(errors).length > 0;
    

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            inviteAdmin(data)   
        }
    }


    return (
        
        <ModalAsset

            modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
            headerContent={
              <Box>
                  <Text className="font-semibold">Invite New Admin</Text>
                  <Text className="text-xs font-normal text-gray-400">
                     Send an invitation to add a new admin with specific roles and permissions to the platform.
                  </Text>
              </Box>
            }
            bodyContent={
                <Box>
                    
                    <Box className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4">

                    <FieldControl error={errors?.first_name?.[0]} label={'First Name'}>
                        <Input 
                            size={{base: 'lg', lg: 'md'}} 
                            fontSize={'xs'} 
                            placeholder="Enter first name" 
                            value={data.first_name} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('first_name', event.target.value)} 
                        />
                    </FieldControl>

                    <FieldControl error={errors?.last_name?.[0]} label={'Last name'}>
                        <Input 
                            size={{base: 'lg', lg: 'md'}} 
                            fontSize={'xs'} 
                            placeholder="Enter last name" 
                            value={data.last_name} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('last_name', event.target.value)} 
                        />
                    </FieldControl>

                    <FieldControl error={errors?.email?.[0]} label={'Email Address'}>
                        <Input 
                            size={{base: 'lg', lg: 'md'}} 
                            fontSize={'xs'} 
                            placeholder="Enter email address" 
                            value={data.email} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('email', event.target.value)} 
                        />
                    </FieldControl>
                    
                    <FieldControl label={'Roles'}>
                        <SelectAsset
                            value={data?.roles}
                            options={roles}
                            placeholder={'Select Roles'}
                            isSearch
                            isMulti
                            onChange={(selected) =>  onHandleChange('roles', selected)}
                        />
                    </FieldControl>

                    </Box>
                
                </Box>
            }
            footerContent={
                <ButtonGroup>
                    <Button size={'sm'} onClick={onClose} className="border">Cancel</Button>
                    <ButtonAsset onClick={submit} buttonProps={{isLoading: load, isDisabled, size: 'sm'}}>
                        Invite Admin
                    </ButtonAsset>
                </ButtonGroup>
            }

    />

    )

}


export default AdminInvite