import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import AnalyticsLayout from "@modules/analytics/components/AnalyticsLayout"
import UsersList from "@modules/users/components/UsersList"

const Users = () => {

    return (
        <AnalyticsLayout title={'Users'}>

            <Box className="mb-4 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4">
                
                <Box className="bg-gray-50 p-5">
                    <StatCard
                        label={'Total Registered Users'}
                        number={localNumber({number: 1582})}
                        helper={'26% In 24 Hours'}
                        arrow={'increase'}
                    />
                </Box>

                <Box className="bg-gray-50 p-5">
                    <StatCard
                        label={'Active Users'}
                        number={localNumber({number: 1582})}
                        helper={'10% In 24 Hours'}
                        arrow={'decrease'}
                    />
                </Box>

                <Box className="bg-gray-50 p-5">
                    <StatCard
                        label={'New Users'}
                        number={localNumber({number: 1582})}
                        helper={'10% In 24 Hours'}
                        arrow={'decrease'}
                    />
                </Box>
                
                <Box className="bg-gray-50 p-5">
                    <StatCard
                        label={'User Retention Rate'}
                        number={localNumber({number: 1582})}
                        helper={'10% In 24 Hours'}
                        arrow={'decrease'}
                    />
                </Box>

            </Box>

            <Box>
                <UsersList />
            </Box>

        </AnalyticsLayout>
    )

}

export default Users