import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import ReviewList from "@modules/product/components/Review/ReviewList"

const List = () => {

    return (
        <AuthenticatedLayout title={'Reviews List | Monitor Customer Feedback'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Review List'}
                        description={`Browse all customer reviews for insights and product improvement.`}
                    />
                </Box>

                <Box className="content">

                    <Box>
                        <ReviewList title={''} description={''} />
                    </Box>

                </Box>
               
           </Box>
        
        </AuthenticatedLayout>
    )

}


export default List