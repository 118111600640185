import { useMemo } from "react"
import { useRequest } from "@handlers/index"
import { setStore, sessionExpires, appURL } from "@utils/helpers"
import Cookies from 'js-cookie'

const PaymentService = () => { 

    const {errors: requestErrors, response, load, request} = useRequest()

    const endpoint = `${appURL}/api/payment`;

    // Get All Payments
    const getPayments = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}`,
            params: data
        }, 
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Get Payment by ID
    const getPayment = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/${data?.id}`,
        }, 
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Update Payment
    const updatePayment = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/${data?.id}`,
            data
        }, 
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Delete Payment
    const deletePayment = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/${data?.id}`,
        }, 
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    //Get All Payment Gateways
    const getGateways = async () => {
        request({
            method: 'get',
            url: `${endpoint}/gateways`
        }, 
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Payment Gateway
    const updateGateway = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/gateway/${data?.id}`,
            data
        }, 
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    const services = {
        requestErrors, 
        response, 
        load,
        getPayments,
        getPayment,
        updatePayment,
        deletePayment,
        getGateways,
        updateGateway
    };
    

    return services

    // return useMemo(() => (services), []);

}

export default PaymentService