import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/product/pages/Index'
import Details from '@modules/product/pages/Details'
import Form from '@modules/product/pages/Form'
import List from '@modules/product/pages/List'

import CategoryIndex from '@modules/product/pages/Category/Index'
import CategoryDetails from '@modules/product/pages/Category/Details'
import CategoryList from '@modules/product/pages/Category/List'

import SubCategoryIndex from '@modules/product/pages/SubCategory/Index'
import SubCategoryDetails from '@modules/product/pages/SubCategory/Details'
import SubCategoryList from '@modules/product/pages/SubCategory/List'

import AttributeIndex from '@modules/product/pages/Attribute/Index'

import ReviewIndex from '@modules/product/pages/Review/Index'
import ReviewList from '@modules/product/pages/Review/List'


const ProductRoutes = [

    <Route path={'/products'}> 

        <Route index element={<Index />} />
        <Route path="list" element={<List />} />
        <Route path=":id" element={<Details />} />
        <Route path="edit/:id" element={<Form type={'edit'} />} />
        <Route path="create" element={<Form type={'create'} />} />

        <Route path="categories" element={<CategoryIndex />} />
        <Route path="categories/list" element={<CategoryList />} />
        <Route path="categories/:id" element={<CategoryDetails />} />

        <Route path="subcategories" element={<SubCategoryIndex />} />
        <Route path="subcategories/list" element={<SubCategoryList />} />
        <Route path="subcategories/:id" element={<SubCategoryDetails />} />

        <Route path="attributes" element={<AttributeIndex />} />

        <Route path="reviews" element={<ReviewIndex />} />
        <Route path="reviews/list" element={<ReviewList />} />
        
    </Route>

]

export default ProductRoutes