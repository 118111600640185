import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel, Input, 
    useDisclosure, VStack, IconButton, Badge
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiTruckFill} from "@remixicon/react"
import { localNumber, statusColors } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { Link } from "react-router-dom"
import SettingsLayout from "@modules/settings/components/SettingsLayout"
import ShippingDetails from "@modules/settings/components/Shipping/ShippingDetails"
import ShippingForm from "@modules/settings/components/Shipping/ShippingForm"
import { useSettingsService } from "@services/index"
import PageHeader from "@components/header/components/PageHeader"

const Shipping = () => {

    const [item, setItem] = useState(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen:isConfigOpen, onOpen:onConfigOpen, onClose:onConfigClose } = useDisclosure()

    const { getShippings, response } = useSettingsService()

    useEffect(() => {
        getShippings()
    }, [])

    return (
        <SettingsLayout title={'Shipping Settings | Configure Shipping Methods and Rates'}>

            <Box className="mb-5">
                <PageHeader
                    title={<Text className="text-sm">Shipping Settings</Text>}
                    description={<Text className="text-xs text-gray-500">Manage shipping options, set shipping rates, and configure delivery preferences for orders.</Text>}
                />
            </Box>

            <Box className="w-full grid grid-cols-3 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-3 gap-4">
                {
                    response?.shipping?.map((shipping, index) => (
                        <Box onClick={() => {
                            onOpen()
                            setItem(shipping)
                        }} key={index} className="w-full h-[110px] mx-auto border p-2 cursor-pointer hover:bg-gray-100">
                            <Text className="uppercase text-center text-xs">{shipping?.name}</Text>
                            <VStack className="h-full mt-[-10px] !justify-center !items-center">
                                <img src={shipping?.image} width={30} />
                                <Badge className="!px-2" colorScheme={statusColors(shipping?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                    <Text className="font-normal" fontSize={8}>{shipping?.status ? "Active" : 'Inactive'}</Text>
                                </Badge>
                            </VStack>
                        </Box>
                    ))
                }
                <Box className="w-full h-[110px]">
                    <IconButton onClick={onConfigOpen} className="!h-full !w-full border" size={'sm'} icon={
                        <VStack spacing={1} className="!items-center !justify-center">
                            <RiTruckFill size={25} />
                            <Text>Add Platform</Text>
                        </VStack>
                    } />
                </Box>
            </Box>

            <ShippingForm 
                isOpen={isConfigOpen} 
                onClose={onConfigClose} 
                getShippings={getShippings} 
                onDetailOpen={onOpen} 
                setItem={setItem} 
            />

            {
                item &&
                <ShippingDetails 
                    isOpen={isOpen} 
                    onClose={onClose} 
                    shipping={item} 
                    getShippings={getShippings} 
                    setItem={setItem} 
                />
            }

        </SettingsLayout>
    )


}

export default Shipping