import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/order/pages/Index'
import OrderDetails from '@modules/order/pages/Details'
import OrderList from '@modules/order/pages/List'


const OrderRoutes = [

    <Route path={'/orders'}> 
        <Route index element={<Index />} />
        <Route path=":id" element={<OrderDetails />} />
        <Route path="list" element={<OrderList />} />
    </Route>

]

export default OrderRoutes