import AuthenticatedLayout from "@components/layout/Authenticated"
import { useState } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiMoreFill} from "@remixicon/react"
import { localNumber, statusColors, formatDate} from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset, TableAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import SettingsLayout from "@modules/settings/components/SettingsLayout"
import AdminList from "@modules/settings/components/Admin/AdminList"
import PageHeader from "@components/header/components/PageHeader"

const Admins = () => {

    return (
        <SettingsLayout title={'Admin Management | Manage Admin Accounts'}>

            <Box className="mb-5">
                <PageHeader
                    title={<Text className="text-sm">Admin Management</Text>}
                    description={
                        <Text className="text-xs text-gray-500">View and manage admin users, including roles, permissions, and access levels.</Text>
                    }
                />
            </Box>

            <AdminList />

        </SettingsLayout>
    )

}

export default Admins