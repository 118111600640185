import { useCallback, useMemo } from "react"
import { appURL } from "@utils/helpers"
import { useRequest } from "@handlers/index"
import Cookies from 'js-cookie'


const UserService = () => {

    const {errors: requestErrors, response, load, request} = useRequest()   


    const endpoint = `${appURL}/api/users`;

    // Get All Users
    const getUsers = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}`,
            params: data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Get User by Username
    const getUser = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/${data?.username}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update User
    const updateUser = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Delete User
    const deleteUser = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update User Password
    const updateUserPassword = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/${data?.id}/password`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Get User Shipping Addresses
    const getUserAddresses = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/${data?.id}/addresses`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Get User Shipping Address by ID
    const getUserAddress = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/${data?.id}/address/${data?.address_id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Create User Shipping Address
    const createUserAddress = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/${data?.user_id}/address`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update User Shipping Address
    const updateUserAddress = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/${data?.id}/address/${data?.address_id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Delete User Shipping Address
    const deleteUserAddress = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/${data?.id}/address/${data?.address_id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    //Get User Activities
    const getActivities = async (params) => {
        request({
            method: 'get',
            url: `${endpoint}/activities`,
            params
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    //Get User Activity By ID
    const getActivity = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/${data?.id}/activity`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    //Get User Activity By ID
    const updateActivity = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/${data?.id}/activity`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Services Object with all methods
    const services = {
        requestErrors, 
        response, 
        load,
        getUsers,
        getUser,
        updateUser,
        deleteUser,
        updateUserPassword,
        getUserAddresses,
        getUserAddress,
        createUserAddress,
        updateUserAddress,
        deleteUserAddress,
        getActivities,
        getActivity,
        updateActivity
    };



    return services

    // return useMemo(() => (services), []);

}

export default UserService