import { useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import PaymentList from "@modules/payment/components/PaymentList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import Statistics from "@components/header/components/StatDate"

const Index = () => {

    const [stat, setStat] = useState({})

    const { successful_payments, pending_payments } = stat || {}

    const PageHeaderAction = () => (
        <Statistics setStat={setStat} />
    )

    return (
        <AuthenticatedLayout title={'Payments | Secure and Comprehensive Transaction Management'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Payments'}
                        description={`Manage and track all payment transactions, ensuring accurate records and seamless operations.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Successful Payments'}
                                number={`₦${localNumber({number: successful_payments?.amount})}`}
                                helper={`${localNumber({number: successful_payments?.trend?.percentage, decimals: 2})}% In ${successful_payments?.trend?.period}`}
                                arrow={successful_payments?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Pending Payments'}
                                number={`₦${localNumber({number: pending_payments?.amount})}`}
                                helper={`${localNumber({number: pending_payments?.trend?.percentage, decimals: 2})}% In ${pending_payments?.trend?.period}`}
                                arrow={pending_payments?.trend?.arrow}
                            />
                        </Box>

                    </Box>

                    <Box>

                        <PaymentList />

                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Index