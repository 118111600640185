import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, MenuDivider, Center, Badge, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiMoreFill} from "@remixicon/react"
import { localNumber, removeEncodedSpace, statusColors } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset, PromptAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link, useLocation, useNavigate } from "react-router-dom"
import OrderItemsList from "@modules/order/components/OrderItemsList"
import { useProductService } from "@services/index"

const Details = () => {

    const location = useLocation()
    const { pathname } = location
    const name = pathname?.split('/')[2]
    
    const navigate = useNavigate()

    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    const [prompt, setPrompt] = useState('');

    const { getProduct, updateProduct, response:product, load:product_load } = useProductService()
    const { deleteProduct, response:deleted_product, load:delete_product_load } = useProductService()
    
    const { category, sub_category, reviews, order_items, id, status} = product?.product || {}


    const onHandlePromptCall = () => {
        
        switch (prompt) {
            case 'delete':
                deleteProduct({id})
                break;
            case 'update':
                updateProduct({id, status: !status})
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        if (product || deleted_product) {
            onClosePrompt();
            if (deleted_product) navigate('/products');
        }
    }, [product, deleted_product]);
    

    useEffect(() => {
        if (name) {
            getProduct({id: name})
        }
    }, [name])
    

    const PageHeaderAction = () => (
        <ButtonGroup>
            <LinkAsset linkProps={{className: '!bg-primary-950 !text-white'}} href={`/products/edit/${product?.product?.name}`}>Edit Product</LinkAsset>
            <MenuAsset
                arrowless
                triggerProps={{className: '!text-xs', size: 'sm'}}
                trigger={Button}
                triggerContent={<RiMoreFill size={20} />}
                listProps={{
                    className: '!min-w-[120px]'
                }}
                menuContent={
                    <>
                        <MenuItem onClick={() => {
                            onOpenPrompt()
                            setPrompt('update')
                        }} _hover={{bg: 'primary.950', color: 'white'}}>
                            <Text className="text-xs font-medium capitalize">{status ? 'Deactivate' : 'Activate'}</Text>
                        </MenuItem>

                        <MenuDivider />

                        <Center>
                            <Button onClick={() => {
                                onOpenPrompt()
                                setPrompt('delete')
                            }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                Delete
                            </Button>
                        </Center>
                    </>
                }
            />
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={`${removeEncodedSpace(name)} Details | View and Manage Product`}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={
                            <HStack className="mb-1">
                                <Text>{`${removeEncodedSpace(name)} Details`}</Text>
                                <Badge className="!px-2" colorScheme={statusColors(status ? 'active' : 'inactive')} variant={'subtle'}>
                                    <Text className="font-normal" fontSize={10}>{status ? "Active" : 'Inactive'}</Text>
                                </Badge>
                            </HStack>
                        }
                        description={`Detailed view of ${removeEncodedSpace(name)}'s information, pricing, and related inventory data.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Sold Products'}
                                number={`${localNumber({number: order_items?.reduce((sum, item) => sum + (item.quantity || 0), 0), decimals: 0})}`}
                                link={'/orders'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Shipped Products'}
                                number={`${order_items?.filter(item => item?.status === 'shipped').length}`}
                                link={'/orders'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Products Pending Shipment'}
                                number={`${order_items?.filter(item => item?.status === 'confirmed').length}`}
                                link={'/orders'}
                            />
                        </Box>

                    </Box>

                    <Box>
                        {
                            order_items &&
                            <OrderItemsList title={`${removeEncodedSpace(name)} Order Items`} defaultItems={order_items?.map((item) => ({...item, product: product?.product}))} />
                        }
                    </Box>

                </Box>

               
           </Box>

           <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                load={product_load || delete_product_load}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    onHandlePromptCall(prompt)
                }}
            />
        
        </AuthenticatedLayout>
    )

}


export default Details