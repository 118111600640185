import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import CategoriesList from "@modules/product/components/Category/CategoryList"
import CategoryForm from "@modules/product/components/Category/CategoryForm"

const List = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">Create Category</Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Categories List | Manage Product Classifications'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Categories List'}
                        description={`View all product categories in a comprehensive list for efficient organization and management.`}
                        action={PageHeaderAction}
                    />
                </Box>

                <Box className="content">

                    <Box>
                        <CategoriesList title={''} description={''} />
                    </Box>

                </Box>
               
           </Box>

           <CategoryForm isOpen={isOpen} onClose={onClose} />
        
        </AuthenticatedLayout>
    )

}


export default List