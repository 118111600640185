import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, VStack, Td, Badge, Avatar, useDisclosure} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine } from "@remixicon/react"
import { Link } from "react-router-dom"
import OrderItemDetails from "./OrderItemDetails"
import { orderAmount } from '@modules/order/utils/OrderUtils';
import { useOrderService } from "@services/index"

const OrderItemsList = ({title, description, defaultItems}) => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)
    
    const {getOrderItems, response, load} = useOrderService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.order_items?.data ?? defaultItems)
    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.order_items || {}

    useEffect(() => {
        if (!defaultItems) {
            getOrderItems()
        }else{
            setSearchItems(defaultItems)
        }
    }, [defaultItems])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const FilterView = () => (
        <HStack className="justify-end items-center w-full py-2" spacing={5}>

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for order items'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">ID</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Item</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">User</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Amount</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                    {
                        results?.map((item, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index + 1 + (per_page || 0) * ((current_page || 1) - 1)}</Td>
                                <Td>{item?.metadata?.id}</Td>
                                <Td>
                                    <Link to={`/products/${item?.product?.name}`}>
                                        <HStack>
                                            <img src={item?.product?.images[0]} alt={item?.product?.name} width={40} />
                                            <Box>
                                                <Text className="underline">{item?.product?.name}</Text>
                                                <Text className="text-gray-400">{item?.quantity} Piece(s)</Text>
                                            </Box>
                                        </HStack>
                                    </Link>
                                </Td>
                                <Td>
                                    <Link to={`/users/${item?.user?.username}`}>
                                        <HStack>
                                            <Avatar size={'sm'} name={`${item?.user?.first_name} ${item?.user?.last_name}`} />
                                            <Text className="underline">{`${item?.user?.first_name} ${item?.user?.last_name}`}</Text>
                                        </HStack>
                                    </Link>
                                </Td>
                                <Td>₦{localNumber({number: orderAmount({...item.metadata, product: item.product}) })}</Td>
                                <Td textAlign={{base: 'right', lg: 'left'}}>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(item?.status)} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{item?.status}</Text>
                                    </Badge>
                                </Td>
                                <Td>{formatDate({date: item?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>
                                <Td isNumeric>
                                    <Button onClick={() => {
                                        setItem(item)
                                        onOpen()
                                    }} size={'sm'}>More Details</Button>
                                </Td>
                            </Tr>
                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getOrderItems({page})}
                />
            }
        />
    )

    return (
        <Box>   

            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold capitalize">
                    {title ?? "Order Items Table"}
                </Text>
                <Text className="text-xs text-gray-500">
                    {description ?? "A detailed table of items in this order, providing insights for inventory and fulfillment management."}
                </Text>
            </Box>

            {FilterView()}

            {TableView()}

            <OrderItemDetails isOpen={isOpen} onClose={onClose} item={item} />
            
        </Box>
    )

}


export default OrderItemsList