import { useState, useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, StackDivider, 
    useDisclosure, Center, MenuDivider, Badge
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiMoreFill} from "@remixicon/react"
import { localNumber, statusColors } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, DrawerAsset, LinkAsset, PromptAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import ShippingPricingList from "./ShippingPricingList"
import ShippingForm from "./ShippingForm"
import ShippingPricingForm from "./ShippingPricingForm"
import { useSettingsService } from "@services/index"


const ShippingDetails = ({isOpen, onClose, shipping, getShippings, setItem}) => {

    const [country, setCountry] = useState(null)
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    const { isOpen:isConfigOpen, onOpen:onConfigOpen, onClose:onConfigClose } = useDisclosure()
    const { isOpen:isPricingOpen, onOpen:onPricingOpen, onClose:onPricingClose } = useDisclosure()

    const { deleteShipping, response:deleted_shipping, requestErrors, load } = useSettingsService()

    useEffect(() => {
        if (deleted_shipping) {
            getShippings()
            onClosePrompt()
            onClose()
        }
    }, [deleted_shipping])

    return (

        <DrawerAsset
            drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: 'full'}}
            bodyContent={
                <VStack spacing={10} className="w-full h-full !items-start mt-5">

                    <VStack className="w-full !items-start" spacing={5}>
                        <Box>
                            <HStack className="mb-1">
                                <img src={shipping?.image} width={20} />
                                <Text className="text-sm uppercase">{shipping?.name} Details</Text>
                                <Badge className="!px-2" colorScheme={statusColors(shipping?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                    <Text className="font-normal" fontSize={10}>{shipping?.status ? "Active" : 'Inactive'}</Text>
                                </Badge>
                            </HStack>
                            <Text className="text-xs font-normal text-gray-400">
                                 Review the complete details of {shipping?.name?.toUpperCase()}, including delivery zones, rates, and configurations.
                             </Text>
                        </Box>
                        <ButtonGroup>
                            <Button onClick={onPricingOpen} size={'sm'} className="!bg-primary-950 !text-white">Update Pricing</Button>
                            <MenuAsset
                                triggerProps={{className: '!text-xs', size: 'sm'}}
                                trigger={Button}
                                triggerContent={<RiMoreFill size={20} />}
                                listProps={{
                                    className: '!min-w-[120px]'
                                }}
                                menuContent={
                                    <>
                                        <MenuItem onClick={onConfigOpen} _hover={{bg: 'primary.950', color: 'white'}}>
                                            <Text className="text-xs font-medium capitalize">Edit</Text>
                                        </MenuItem>

                                        <MenuDivider />

                                        <Center>
                                            <Button onClick={onOpenPrompt} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                                Delete
                                            </Button>
                                        </Center>
                                    </>
                                }
                            />
                        </ButtonGroup>
                    </VStack>

                    <Box className="w-full grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-4">

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Delivery Countries'}
                                number={`${localNumber({
                                    number: Object.keys(shipping?.pricing ?? {})?.length ?? 0, 
                                    decimals: 0
                                })}`}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Delivery States'}
                                number={`${localNumber({
                                    number: Object.values(shipping?.pricing ?? {}).reduce((total, countryPrices) => {
                                        return total + countryPrices.length;
                                    }, 0), 
                                    decimals: 0
                                })}`}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Delivery Fees'}
                                number={`₦${localNumber({
                                    number: Object?.values(shipping?.pricing ?? {})?.reduce((total, countryPrices) => {
                                        const countryTotal = countryPrices.reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0);
                                        return total + parseFloat(countryTotal);
                                      }, 0), 
                                    decimals: 2
                                })}`}
                            />
                        </Box>
        
                    </Box>
                
                    <Box className="w-full">

                        <ShippingPricingList shipping={shipping} onPricingOpen={onPricingOpen} setCountry={setCountry} />
                        
                    </Box>

                    <ShippingForm 
                        isOpen={isConfigOpen} 
                        onClose={onConfigClose} 
                        shipping={shipping} 
                        setItem={setItem} 
                        getShippings={getShippings}
                    />

                    {
                        isPricingOpen &&
                        <ShippingPricingForm 
                            isOpen={isPricingOpen} 
                            onClose={onPricingClose} 
                            shipping={shipping} 
                            country={country}
                            setItem={setItem} 
                            getShippings={getShippings}
                        />
                    }

                    <PromptAsset
                        isOpen={isOpenPrompt}
                        onClose={onClosePrompt}
                        title={'Please confirm'}
                        load={load}
                        body={'Are you sure you want to proceed with this action?'}
                        onSubmit={() => {
                            deleteShipping({id: shipping?.id})
                        }}
                    />

                </VStack>
            }
        />

    )

}


export default ShippingDetails