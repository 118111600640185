const validationRules = {
    
        accepted: (attribute, input) => {
            const result = ['yes', 'on', '1', 1, true].includes(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        active_url: (attribute, input) => {
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            const result = urlRegex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
    
        after: (attribute, input, date) => {
            const inputDate = new Date(input);
            const comparisonDate = new Date(date);
            const result = inputDate > comparisonDate;
            return {
                result,
                pattern: {
                    attribute,
                    date
                }
            }
        },
        
        after_or_equal: (attribute, input, date) => {
            const inputDate = new Date(input);
            const comparisonDate = new Date(date);
            const result = inputDate >= comparisonDate;
            return {
                result,
                pattern: {
                    attribute,
                    date
                }
            }
        },
        
        alpha: (attribute, input) => {
            const result = /^[a-zA-Z]+$/.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        alpha_dash: (attribute, input) => {
            const result = /^[a-zA-Z0-9-_]+$/.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        alpha_num: (attribute, input) => {
            const result = /^[a-zA-Z0-9]+$/.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        array: (attribute, input) => {
            const result = Array.isArray(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        ascii: (attribute, input) => {
            const result = /^[\x00-\x7F]+$/.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        before: (attribute, input, date) => {
            const inputDate = new Date(input);
            const comparisonDate = new Date(date);
            const result = inputDate < comparisonDate;
            return {
                result,
                pattern: {
                    attribute,
                    date
                }
            }
        },
        
        before_or_equal: (attribute, input, date) => {
            const inputDate = new Date(input);
            const comparisonDate = new Date(date);
            const result = inputDate <= comparisonDate;
            return {
                result,
                pattern: {
                    attribute,
                    date
                }
            }
        },
        
        between: (attribute, input, min, max) => {
            const type = typeof input
            let result = null
            switch (type) {
                case 'object':
                    result = input.length >= min && input.length <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            max,
                            type,
                        },
                    }
                case 'file':
                    // Assuming input is the file size in kilobytes
                    result = input >= min && input <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            max,
                            type,
                        }
                    }
                case 'number':
                    result = input >= min && input <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            max,
                            type,
                        }
                    }
                case 'string':
                    result = input.length >= min && input.length <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            max,
                            type,
                        }
                    }
                default:
                    // Default behavior, assuming numeric comparison
                    result = input >= min && input <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            max
                        }
                    }
            }
        },
        
        boolean: (attribute, input) => {
            const result = input === true || input === false || input === 'true' || input === 'false';
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        confirmed: (attribute, input, confirmationInput) => {
            const result = input === confirmationInput;
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        password: (attribute, input, type) => {

            let result = false

            switch (type) {
                case 'letters':
                    result = /[a-zA-Z]/.test(input);
                    return {
                        result,
                        pattern: {
                            attribute,
                            type,
                        }
                    }
                case 'mixed':
                    result = (/[a-z]/.test(input) && /[A-Z]/.test(input));
                    return {
                        result,
                        pattern: {
                            attribute,
                            type,
                        }
                    }
                case 'numbers':
                    result = /[0-9]/.test(input);
                    return {
                        result,
                        pattern: {
                            attribute,
                            type,
                        }
                    }
                case 'symbols':
                    result = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(input);
                    return {
                        result,
                        pattern: {
                            attribute,
                            type
                        }
                    }
                default:
                    // Default behavior, assuming numeric comparison
                    return {
                        result,
                        pattern: {
                            attribute
                        }
                    }
            }
        },
        
        date: (attribute, input) => {
            // This function can vary significantly based on how dates are represented and parsed in your application.
            // You might use a library like moment.js or Date.parse() depending on your requirements.
            const result = !isNaN(Date.parse(input));
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        date_format: (attribute, input, format) => {
            // This function would involve parsing the input date string according to the specified format and validating it.
            // You might use a library like moment.js to handle date formatting and parsing.
            // For example:
            // return moment(input, format, true).isValid();
            // This is a simplified implementation and may need adjustment based on your specific requirements.
            const result = true;
            return {
                result,
                pattern: {
                    attribute,
                    format
                }
            }
        },
        
        decimal: (attribute, decimal) => {
            // Check if the input is a valid decimal number
            const result = !isNaN(parseFloat(decimal)) && isFinite(decimal);
            return {
                result,
                pattern: {
                    attribute,
                    decimal
                }
            }
        },
        
        declined: (attribute, input) => {
            // This rule is typically handled at the level of the validation engine,
            // where it stops validating other rules for the attribute if this rule fails.
            // In JavaScript, you might handle this at the level of your validation engine/library.
            const result = false;
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        digits: (attribute, input, digits) => {
            // Check if the input contains exactly the specified number of digits
            const regex = new RegExp(`^\\d{${digits}}$`);
            const result = regex.test(input);
            return {
                result,
                pattern: {
                    attribute,
                    digits
                }
            }
        },
        
        digits_between: (attribute, input, min, max) => {
            // Check if the input contains a number of digits within the specified range
            const regex = new RegExp(`^\\d{${min}${max}}$`);
            const result = regex.test(input);
            return {
                result,
                pattern: {
                    attribute,
                    min,
                    max
                }
            }
        },
        
        distinct: (attribute, input, array) => {
            // Check if the input is distinct from all other elements in the array
            const result = array.indexOf(input) === array.lastIndexOf(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        doesnt_start_with: (attribute, input, value) => {
            // Check if the input does not start with the specified value
            const result = !input.startsWith(value);
            return {
                result,
                pattern: {
                    attribute,
                    value
                }
            }
        },
        
        doesnt_end_with: (attribute, input, value) => {
            // Check if the input does not end with the specified value
            const result = !input.endsWith(value);
            return {
                result,
                pattern: {
                    attribute,
                    value
                }
            }
        },
        
        email: (attribute, input) => {
            // Check if the input is a valid email address
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const result = regex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        phone: (attribute, input) => {
            // Check if the input is a valid phone number
            const regex = /^(?:\+[0-9]{1,4}\s?)?[0-9]{7,15}$/;
            const result = regex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
    
        ends_with: (attribute, input, value) => {
            // Check if the input ends with the specified value
            const result = input.endsWith(value);
            return {
                result,
                pattern: {
                    attribute,
                    value
                }
            }
        },
        
        enum: (attribute, input, enumValues) => {
            // Check if the input value is one of the specified enum values
            const result = enumValues.includes(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        exclude: (attribute, input, value) => {
            // Check if the input value is not one of the specified excluded values
            const result = !value.includes(input);
            return {
                result,
                pattern: {
                    attribute,
                    value
                }
            }
        },
        
        file: (attribute, input) => {
            // Check if the input is a file object
            const result = input instanceof File;
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        filled: (attribute, input) => {
            // Check if the input is not empty (i.e., not null, undefined, or an empty string)
            const result = input !== null && input !== undefined && input !== '';
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        greater_than: (attribute, input, value) => {
            const type = typeof input
            let result = null
            switch (type) {
                case 'object':
                    result = input.length > value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'file':
                    result = input > value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'number':
                    result = input > value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'string':
                    result = input.length > value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                default:
                    // Default behavior, assuming numeric comparison
                    result = input > value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value
                        }
                    }
            }
        },
        
        greater_than_or_equal: (attribute, input, value) => {
            const type = typeof input
            let result = null
            switch (type) {
                case 'object':
                    result = input.length >= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'file':
                    // Assuming input is the file size in kilobytes
                    result = input >= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'number':
                    result = input >= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'string':
                    result = input.length >= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                default:
                    // Default behavior, assuming numeric comparison
                    result = input >= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value
                        }
                    }
            }
        },
        
        image: (attribute, file) => {
            // Get the file extension
            const extension = file.split('.').pop().toLowerCase();
            
            // Check if the file extension corresponds to an image format
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
            const result = imageExtensions.includes(extension);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        hex_color: (attribute, input) => {
            // Check if the input is a valid hexadecimal color code
            const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
            const result = regex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        in: (attribute, input, allowedValues) => {
            // Check if the input value is in the specified list of allowed values
            const result = allowedValues.includes(input)
            return {
                result,
                pattern: {
                    attribute
                }
            };
        },
        
        integer: (attribute, input) => {
            // Check if the input value is an integer
            const result = Number.isInteger(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        ip: (attribute, input) => {
            // Check if the input is a valid IPv4 or IPv6 address
            const regexIPv4 = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
            const regexIPv6 = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
            const result = regexIPv4.test(input) || regexIPv6.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        json: (attribute, input) => {
            // Check if the input is a valid JSON string
            let result
            try {
                JSON.parse(input);
                result = true;
            } catch (error) {
                result = false;
            }
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        less_than_or_equal: (attribute, input, value) => {
            const type = typeof input
            let result = null
            switch (type) {
                case 'object':
                    result = input.length <= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'file':
                    result = input <= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'number':
                    result = input <= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                case 'string':
                    result = input.length <= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value,
                            type,
                        }
                    }
                default:
                    // Default behavior, assuming numeric comparison
                    result = input <= value;
                    return {
                        result,
                        pattern: {
                            attribute,
                            value
                        }
                    }
            }
        },
        
        lowercase: (attribute, input) => {
            // Check if the input consists of lowercase letters only
            const result = input === input.toLowerCase();
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        mac_address: (attribute, input) => {
            // Check if the input is a valid MAC address
            const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
            const result = regex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        max: (attribute, input, max) => {
            const type = typeof input
            let result = null
            switch (type) {
                case 'object':
                    result = input.length <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            max,
                            type,
                        }
                    }
                case 'file':
                    // Assuming input is the file size in kilobytes
                    result = input <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            max,
                            type,
                        }
                    }
                case 'number':
                    result = input <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            max,
                            type,
                        }
                    }
                case 'string':
                    result = input.length <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            max,
                            type,
                        }
                    }
                default:
                    // Default behavior, assuming numeric comparison
                    result = input <= max;
                    return {
                        result,
                        pattern: {
                            attribute,
                            max
                        }
                    }
            }
        },
        
        max_digits: (attribute, input, max) => {
            // Check if the input value has at most the specified number of digits
            const result = Number.isInteger(input) && input.toString().length <= max;
            return {
                result,
                pattern: {
                    attribute,
                    max
                }
            }
        },
        
        mimetypes: (attribute, input, value) => {
            // Check if the input file's MIME type is in the specified list of allowed MIME types
            // Note: This validation rule typically applies to file uploads
            // You'll need access to the file's MIME type, which may vary depending on the environment
            // In a web browser, you can use the `File.type` property
            const result = value.includes(input);
            return {
                result,
                pattern: {
                    attribute,
                    value
                }
            }
        },
        
        mime_type_by_file_extension: (attribute, input, extensions) => {
            // Check if the input file's MIME type matches the expected MIME type based on its file extension
            // Note: This validation rule typically applies to file uploads
            // You'll need access to both the file's extension and MIME type, which may vary depending on the environment
            // In a web browser, you can use the `File.type` property for MIME type and extract the extension from the file name
            const fileExtension = input.substring(input.lastIndexOf('.') + 1).toLowerCase();
            const result = extensions[fileExtension] === input;
            return {
                result,
                pattern: {
                    attribute,
                    extensions
                }
            }
        },
        
        min: (attribute, input, min) => {
            const type = typeof input
            let result = null
            switch (type) {
                case 'object':
                    result = input.length >= min;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            type,
                        }
                    }
                case 'file':
                    // Assuming input is the file size in kilobytes
                    result = input >= min;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            type,
                        }
                    }
                case 'number':
                    result = input >= min;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            type,
                        }
                    }
                case 'string':
                    result = input.length >= min;
                    return {
                        result,
                        pattern: {
                            attribute,
                            min,
                            type,
                        }
                    }
                default:
                    // Default behavior, assuming numeric comparison
                    result = input >= min;
                    return {
                        result,
                        type,
                        pattern: {
                            attribute,
                            min
                        }
                    }
            }
        },
        
        min_digits: (attribute, input, min) => {
            // Check if the input value has at least the specified number of digits
            const result = Number.isInteger(input) && input.toString().length >= min;
            return {
                result,
                pattern: {
                    attribute,
                    min
                }
            }
        },
        
        missing: (attribute, input) => {
            // Check if the input is missing (null, undefined, or empty string)
            const result = input === null || input === undefined || input === '';
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        multiple_of: (attribute, input, value) => {
            // Check if the input value is a multiple of the specified value
            const result = Number.isInteger(input) && input % value === 0;
            return {
                result,
                pattern: {
                    attribute,
                    value
                }
            }
        },
        
        not_in: (attribute, input, values) => {
            // Check if the input value is not included in the specified array of values
            const result = !values.includes(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        not_regex: (attribute, input, regex) => {
            // Check if the input value does not match the specified regular expression pattern
            const result = !regex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        nullable: (attribute, input) => {
            const result = input === null || input === undefined || input === '';
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        numeric: (attribute, input) => {
            // Check if the input value is numeric
            const result = !isNaN(parseFloat(input)) && isFinite(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        present: (attribute, input) => {
            // Check if the input is present (not null, undefined, or empty string)
            const result = input !== null && input !== undefined && input !== '';
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
            
        regex: (attribute, input, pattern) => {
            // Check if the input matches the regular expression pattern
            const result = pattern.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        required: (attribute, input) => {
            // Check if the input is required (not null, undefined, or empty string)
            const result = input !== null && input !== undefined && input !== '';
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        
        size: (attribute, input, size) => {
            const type = typeof input
            let result = null
            switch (type) {
                case 'object':
                    result = input.length === size;
                    return {
                        result,
                        pattern: {
                            attribute,
                            size,
                            type,
                        }
                    }
                case 'file':
                    // Assuming input is the file size in kilobytes
                    result = input === size;
                    return {
                        result,
                        pattern: {
                            attribute,
                            size,
                            type,
                        }
                    }
                case 'number':
                    result = input === size;
                    return {
                        result,
                        pattern: {
                            attribute,
                            size,
                            type,
                        }
                    }
                case 'string':
                    result = input.length === size;
                    return {
                        result,
                        pattern: {
                            attribute,
                            size,
                            type,
                        }
                    }
                default:
                    result = false;
                    return {
                        result,
                        pattern: {
                            attribute,
                            size
                        }
                    }
            }
        },
        
        sometimes: (attribute, input, condition) => {
            // Check if the input is validated sometimes based on the condition
            const result = condition ? true : true; // Modify the condition as needed
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        starts_with: (attribute, input, value) => {
            // Check if the input starts with the specified value
            const result = input.startsWith(value);
            return {
                result,
                pattern: {
                    attribute,
                    value
                }
            }
        },
        
        string: (attribute, input) => {
            // Check if the input is a string
            const result = typeof input === 'string';
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        timezone: (attribute, input) => {
            // Check if the input is a valid timezone
            // You may need to implement a more specific validation logic for timezones
            const result = true; // Placeholder logic
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        uppercase: (attribute, input) => {
            // Check if the input is in uppercase
            const result = input === input.toUpperCase();
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        url: (attribute, input) => {
            // Regular expression for validating URLs
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            // Test if the input matches the regex pattern
            const result = urlRegex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        uuid: (attribute, input) => {
            // Regular expression for validating UUIDs
            const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            // Test if the input matches the regex pattern
            const result = uuidRegex.test(input);
            return {
                result,
                pattern: {
                    attribute
                }
            }
        },
        
        bail: (attribute) => {
            // This rule is typically handled at the level of the validation engine,
            // where it stops validating other rules for the attribute after the first validation failure.
            // In JavaScript, you might handle this at the level of your validation engine/library.
            const result = true;
            return {
                result,
                pattern: {
                    attribute
                }
            }
        }
        

}


export default validationRules