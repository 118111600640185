import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import PaymentList from "../components/PaymentList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"

const List = () => {

    return (
        <AuthenticatedLayout title={'Payment Records | View and Manage Transactions'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Payment Records'}
                        description={`Access a detailed list of all payment transactions, including methods, amounts, and associated statuses.`}
                    />
                </Box>


                <Box className="content">

                    <Box>

                        <PaymentList title={''} description={''} />

                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default List