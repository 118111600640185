import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { useNotification, useResponse, useError } from ".."
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie'


const RequestHandler = () => {
    
    const {errors, handleError} = useError()
    const {response, handleResponse} = useResponse()
    const [load, setLoad] = useState(false)

    const navigate = useNavigate();  
    const location = useLocation();

    // Get Auth Cookie
    const getAuthCookie = () => {
        const auth = Cookies.get('auth') ?? '{}';
        return JSON?.parse(auth)
    }

    const request = async ({ url, method, data, params, config }, middleware) => {

      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
    
      try {
          const auth = middleware && getAuthCookie();
  
          const redirect = middleware?.reduce((redirect, [key, path]) => {
            if (!auth[key] && !redirect) {
              return path ? `${path}?redirect=${location.pathname}` : 'hold';
            }
            return redirect;
          }, null);
      
          if (redirect === 'hold') return;
          if (redirect) return navigate(redirect);
      
          setLoad(true);
          const result = await axios({ method, url, data, params, ...config});
          handleResponse(result.data);
          return result;
      
      } catch (error) {
        handleError(error);
        throw error;
      } finally {
        setLoad(false);
      }
    };
    
    
    return {
        load,
        request,
        errors,
        response
    }
    

}


export default RequestHandler