import {createStore, combineReducers, applyMiddleware} from 'redux'
import ThunkMiddleware from 'redux-thunk'


//Reducers
import accountReducer from '@modules/account/state/reducer/account'

const rootReducer = combineReducers({
    accountReducer
})

const store = createStore(rootReducer)

export default store