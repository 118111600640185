import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import TicketList from "@modules/support/components/TicketList"
import { StatAsset, StatCard, MenuAsset, TabAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import { useLocation } from "react-router-dom"

const SettingsLayout = ({children, title}) => {

    const location = useLocation()
    const {pathname} = location || {}

    const tabs = [
        // {
        //     title: 'Company',
        //     href: ['/settings/company', '/settings'],
        // },
        {
            title: 'Payment',
            href: ['/settings/payment', '/settings']
        },
        {
            title: 'Shipping',
            href: ['/settings/shipping']
        },
        {
            title: 'Coupon',
            href: ['/settings/coupon']
        },
        {
            title: 'Admins',
            href: ['/settings/admins']
        },
        // {
        //     title: 'Support',
        //     href: ['/settings/support']
        // },
    ]

    const isPathActive = (hrefs) => {
        return hrefs.some(href => {
          if (href.endsWith('/*')) {
            // Remove the '/*' and match the prefix
            return pathname.startsWith(href.replace('/*', ''));
          }
          // Exact match
          return pathname === href;
        });
    };

    return (
        <AuthenticatedLayout title={title}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Settings'}
                        description={`Adjust and manage your account preferences, application configurations, and system settings.`}
                    />
                </Box>

                <Box className="content">

                    <Box className="mb-5 w-full overflow-x-auto">
                        <HStack>
                            {tabs.map((tab, index) => (
                                <Link 
                                    key={index}
                                    to={tab.href[0]}
                                    className={`${isPathActive(tab.href) ? 'bg-primary-950 text-white' : ''} py-2 px-3 text-xs rounded-none font-medium`}
                                >
                                    {tab.title}
                                </Link>
                            ))}
                        </HStack>
                    </Box>
                    
                    <Box>
                        {children}
                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default SettingsLayout