import { useEffect, useState } from "react";
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
  Menu, MenuButton, MenuList, MenuItem, useColorMode, Center, Heading, StackDivider,
  IconButton, Divider, Avatar, Input, Textarea, Checkbox
} from '@chakra-ui/react'
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { RiStarFill } from "@remixicon/react";
import { LightLogo } from "@utils/images";
import { useAccountService } from "@services/index";
import Layout from ".";

const GuestLayout = ({ children, title}) => {

  const { userDataGuest } = useAccountService()

  useEffect(() => {
      userDataGuest()
  }, []);

  return (
    <Layout title={title}>

      <Box className="w-full h-full">

        <Box className="relative h-full text-center bg-black">
          <Box
              bgImage={{base: '', lg: `url(/bg/auth/1.jpg)`}}
              className={`bg-gray-100 bg-center bg-cover w-full h-full absolute`}
          />

              <Box className="h-full overflow-y-auto overflow-x-hidden">
                <HStack className="items-center justify-start lg:min-h-full h-auto">
                    <VStack spacing={{base: 10}} opacity={{base: 1, lg: 0.98}} className="lg:ml-20 bg-gray-100 2xl:w-[30%] xl:w-[35%] lg:w-[50%] w-full lg:mx-0 lg:px-0 px-10 py-10 backdrop-blur-xl">
                      
                      <Link to={'/'}>
                        <LightLogo />
                      </Link>

                      {children}

                    </VStack>
                </HStack>
              </Box>

        </Box>

      </Box>

    </Layout>
  );
};


export default GuestLayout