import { useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import OrderList from "@modules/order/components/OrderList"
import { StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import Statistics from "@components/header/components/StatDate"

const Index = () => {

    const [stat, setStat] = useState({})

    const { placed_orders, submitted_orders, shipped_orders, declined_orders } = stat || {}

    const PageHeaderAction = () => (
        <Statistics setStat={setStat} />
    )

    return (
        <AuthenticatedLayout title={'Manage Orders | Comprehensive Order Management System'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Order Management'}
                        description={'Oversee and manage user orders efficiently from this interface.'}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Placed Orders'}
                                number={`${localNumber({number: placed_orders?.number, decimals: 0})}`}
                                helper={`${localNumber({number: placed_orders?.trend?.percentage, decimals: 2})}% In ${placed_orders?.trend?.period}`}
                                arrow={placed_orders?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Submitted Orders'}
                                number={`${localNumber({number: submitted_orders?.number, decimals: 0})}`}
                                helper={`${localNumber({number: submitted_orders?.trend?.percentage, decimals: 2})}% In ${submitted_orders?.trend?.period}`}
                                arrow={submitted_orders?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Shipped Orders'}
                                number={`${localNumber({number: shipped_orders?.number, decimals: 0})}`}
                                helper={`${localNumber({number: shipped_orders?.trend?.percentage, decimals: 2})}% In ${shipped_orders?.trend?.period}`}
                                arrow={shipped_orders?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Declined Orders'}
                                number={`${localNumber({number: declined_orders?.number, decimals: 0})}`}
                                helper={`${localNumber({number: declined_orders?.trend?.percentage, decimals: 2})}% In ${declined_orders?.trend?.period}`}
                                arrow={declined_orders?.trend?.arrow}
                            />
                        </Box>

                    </Box>

                    <Box>
                        <OrderList />
                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Index