import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import UsersList from "@modules/users/components/UsersList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import ActivityList from "../components/ActivityList"

const Activity = () => {

    return (
        <AuthenticatedLayout title={'Notifications | Stay Updated with Alerts and Messages'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Notifications'}
                        description={`Stay updated with alerts and messages. Manage your notifications to never miss important updates.`}
                    />
                </Box>


                <Box className="content">

                    <ActivityList />

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Activity