// LOGGED_IN, LOGGED_OUT, NOT_VERIFIED
const INITIAL_STATE = {
    status: 'LOGGED_OUT',
    user: {}
}

const accountReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_AUTH':
            return{
                ...state,
                status: action.payload,
            }
        case 'SET_USER':
            return{
                ...state,
                user: action.payload
            }
        default: 
            return{
                ...state
            }
    }
}

export default accountReducer