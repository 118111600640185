import { useEffect, useState } from "react"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, RadioGroup, StackDivider, Input, 
    Textarea, Center, IconButton, Divider, List, ListItem, Select, useDisclosure
} from "@chakra-ui/react"
import { RiCloseFill, RiAddFill, RiArrowDownSLine, RiExpandUpDownLine, RiPaintBrushFill} from "@remixicon/react"
import { ModalAsset, MenuAsset, PopOverAsset, SelectAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { toOrdinal, colourCodeToName} from "@utils/helpers"
import { useProductService } from "@services/index"


const ProductColoursForm = ({data, onHandleChange, attributes}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const [editIndex, setIndex] = useState(null)
    const [colour, setColour] = useState({
        code: '#000',
        name: "black",
        sizes: [
            {
                name: "",
                code: "",
                price: 0
            },
        ]
    })

    const onHandleColourChange = (name, index, value) => {
        setColour(prevColour => {
            if (name === 'colour') {
                return { ...prevColour, code: value, name: colourCodeToName(value)};
            } else if (name === 'size' || name === 'price') {
                const updatedSize = {
                    ...prevColour.sizes[index],
                    [name === 'size' ? 'name' : 'price']: value
                };
                if (name === 'size') updatedSize.code = value;
    
                return {
                    ...prevColour,
                    sizes: [
                        ...prevColour.sizes.slice(0, index),
                        updatedSize,
                        ...prevColour.sizes.slice(index + 1)
                    ]
                };
            }
            return prevColour;
        });
    };

    const addSize = () => {
        const size = { name: "", code: "", price: 0 };
        setColour(prevColour => ({
            ...prevColour,
            sizes: [...(prevColour.sizes || []), size]
        }));
    };
    
    const removeSize = (sizeIndex) => {
        setColour(prevColour => ({
            ...prevColour,
            sizes: prevColour.sizes.filter((_, i) => i !== sizeIndex)
        }));
    };

    const saveColour = () => {
        if (!isDisabled) {
            const colours = [...data?.colours || []];
    
            if (editIndex !== null) {
                colours[editIndex] = colour;
            } else {
                colours.push(colour);
            }
            onHandleChange('colours', colours);
            setColour(null)
            onClose();
        }
    };
    
    const editColour = (index, colour) => {
        setIndex(index)
        setColour(colour)
        onOpen()
    }

    const removeColor = (colourIndex) => {
        const updatedColours = data?.colours?.filter((_, index) => index !== colourIndex);
        onHandleChange('colours', updatedColours);
    };

    const isSizeExists = (currentSize, currentSizeIndex) => 
        colour?.sizes.some((size, sizeIndex) => sizeIndex !== currentSizeIndex && size.code === currentSize);
    
    const isDisabled = colour?.sizes?.some(size => 
        (!size.name || !size.code) || (parseFloat(size.price) === 0 || size.price === "")
    ) || !colour?.sizes?.length;

    return (

        <>

            <Box className="w-full grid grid-cols-2 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-3 gap-4">
                {
                    data?.colours?.map((colour, index) => (
                        <Box key={index} className="w-full h-[110px] mx-auto border p-2 relative">
                            <HStack onClick={() => removeColor(index)} className="absolute top-2 right-2 cursor-pointer">
                                <RiCloseFill size={18} className="text-red-500" />
                            </HStack>
                            <VStack className="h-full !justify-center !items-center">
                                <Box 
                                    onClick={() => editColour(index, colour)}
                                    className={`w-[50px] h-[50px] rounded-lg cursor-pointer`} bg={colour?.code} 
                                />
                            </VStack>
                        </Box>
                    ))
                }
                <Box className="w-full h-[110px]">
                    <IconButton onClick={() => {
                        onOpen()
                        setIndex(null)
                    }} className="!h-full !w-full border" size={'sm'} icon={
                        <VStack spacing={1} className="!items-center !justify-center">
                            <RiPaintBrushFill size={25} />
                            <Text>Add Colours</Text>
                        </VStack>
                    } />
                </Box>
            </Box>
    
            <ModalAsset
                arrowless
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                    <Box>
                        <Text className="font-semibold">Add Product Colour</Text>
                        <Text className="text-xs font-normal text-gray-400">Add unique product colours and their available sizes.</Text>
                    </Box>
                }
                bodyContent={

                    <VStack spacing={5} className="!items-center !text-center !justify-center">

                        <FieldControl label={'Choose colour'} labelProps={{className: '!text-xs'}}>
                            <HStack className="w-full">
                                <Box className="w-2/6">
                                    <Input 
                                        size={{base: 'lg', lg: 'md'}} 
                                        fontSize={'xs'} 
                                        type="color"
                                        value={colour?.['code']}
                                        onChange={(e) => onHandleColourChange('colour', 0, e?.target?.value)}
                                    />
                                </Box>
                                <Box className="w-4/6">
                                    <Input 
                                        className="capitalize"
                                        size={{base: 'lg', lg: 'md'}} 
                                        fontSize={'xs'} 
                                        type="text"
                                        readOnly
                                        value={colour?.['name']}
                                    />
                                </Box>
                            </HStack>
                        </FieldControl>    

                        <FieldControl label={'Select colour sizes and their prices'} labelProps={{className: '!text-xs'}}>
                        
                            <VStack spacing={5} className="!items-start justify-between w-full">
                                
                                {
                                    colour?.sizes?.map((size, index) => (

                                        <HStack className="w-full justify-between items-center" key={index}>

                                            <HStack className="w-5/6 justify-between items-center">
                                                
                                                <FieldControl>
                                                    <SelectAsset
                                                        value={colour?.sizes[index]?.name}
                                                        onChange={(selected) => onHandleColourChange('size', index, selected)}
                                                        options={
                                                            attributes?.find(attribute => attribute.name === 'Sizes')
                                                            ?.options?.filter(option => !isSizeExists(option, index) || colour?.sizes[index]?.name === option)
                                                            ?.map(option => ({ value: option, label: option }))
                                                        }
                                                        placeholder="Select Size"
                                                        isSearch
                                                        size="md"
                                                    />
                                                </FieldControl>

                                                <FieldControl leftElement={
                                                    <Box className="px-2 border-r">
                                                        <Text className="text-sm">₦</Text>
                                                    </Box>
                                                }>
                                                    <Input 
                                                        size={{ base: 'lg', lg: 'md' }}
                                                        fontSize={'xs'} 
                                                        type="number"
                                                        placeholder="Price"
                                                        value={colour['sizes'][index]['price']}
                                                        onKeyDown={(e) => e.code === "Enter" && saveColour()}  
                                                        onChange={(e) => onHandleColourChange('price', index, e.target.value)}
                                                    />
                                                </FieldControl>

                                            </HStack>

                                            <HStack className="w-1/6 justify-end">
                                                <IconButton onClick={() => {
                                                    removeSize(index)
                                                }} className="!bg-red-500 !text-white" size={'sm'} icon={<RiCloseFill size={20} />} />
                                            </HStack>
                                            
                                        </HStack>
                                            
                                    ))
                                }

                                <HStack className="items-center">
                                    <IconButton onClick={addSize} className="!bg-primary-950 !text-white" size={'sm'} icon={<RiAddFill size={20} />} />
                                    <Text className="text-xs">Add Size</Text>
                                </HStack>
                                        
                            </VStack>

                        </FieldControl>
                                
                    </VStack>

                }

                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={() => {
                            onClose()
                            setColour(null)
                            setIndex(null)
                        }} className="border">Close</Button>
                        <Button size={'sm'} isDisabled={isDisabled} onClick={saveColour} className="!bg-primary-950 !text-white">Save</Button>
                    </ButtonGroup>
                }

            />

        </>
    )

}


export default ProductColoursForm