import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import OrderList from "@modules/order/components/OrderList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"

const List = () => {

    return (
        <AuthenticatedLayout title={'Orders List | Overview of All Customer Orders'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Order List'}
                        description={`Comprehensive page to view and manage all user orders with status updates.`}
                    />
                </Box>


                <Box className="content">

                    <Box>
                        <OrderList title={''} description={''} />
                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default List