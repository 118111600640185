import { useEffect, useState } from "react"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, Divider, useDisclosure
} from "@chakra-ui/react"
import { appURL } from "@utils/helpers"
import { RiArrowRightSLine, RiArrowLeftSLine} from "@remixicon/react"
import { UploadAsset, ModalAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { useMediaService } from "@services/index"
import Slider from "react-slick";
import MediaGallery from "./MediaGallery"


const UploadMedia = ({isOpen, onClose, defaultMedia, defaultGetMedia, onSelectMedia}) => {

    const {isOpen:isOpenGallery, onOpen:onOpenGallery, onClose:onCloseGallery} = useDisclosure()

    const [media, setMedia] = useState(defaultMedia?.media ?? [])

    const [files, setFiles] = useState([])

    const [selected, setSelected] = useState('')

    const {getMedia, response} = useMediaService()

    const config = {url: `${appURL}/api/media`, name: 'image'}

    const onHandleLoad = ({data}) => {
        if (defaultGetMedia) {
            defaultGetMedia()
        }else{
            getMedia()
        }

        if (onSelectMedia) onSelectMedia(data?.media?.link)
    }

    useEffect(() => {
        if (!defaultMedia && !defaultGetMedia) {
            getMedia()
        }else{
            setMedia(defaultMedia?.media)
        }
    }, [defaultMedia, defaultGetMedia])

    useEffect(() => {
        if (response?.media) setMedia(response?.media)
    }, [response?.media])

    useEffect(() => {
        setFiles([])
    }, [isOpen])

    useEffect(() => {
        if (selected && onSelectMedia){
            onSelectMedia(selected)
            setSelected(null)
        }
    }, [selected])

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Box className={`${className} !bg-white !shadow-md rounded-full lg:!w-[35px] lg:!h-[35px] !w-[25px] !h-[25px] lg:!right-[-5px] !right-[-10px]`}  style={{ ...style, display: "block" }} onClick={onClick}>
                <HStack className="w-full h-full items-center justify-center">
                    <RiArrowRightSLine size={25} className="text-primary-500" />
                </HStack>
            </Box>
        );
      }
      
      const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Box className={`${className} !bg-white !shadow-md rounded-full lg:!w-[35px] lg:!h-[35px] !w-[25px] !h-[25px] lg:!left-[-5px] !left-[-10px] z-10`}  style={{ ...style, display: "block" }} onClick={onClick}>
               <HStack className="w-full h-full items-center justify-center">
                    <RiArrowLeftSLine size={25} className="text-primary-500" />
               </HStack>
            </Box>
        );
      }

    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        draggable: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 2
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 7,
                slidesToScroll: 1
                },
            },
            {
                breakpoint: 500,
                settings: {
                slidesToShow: 4,
                slidesToScroll: 1
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }

    return (
        <>
    
            <ModalAsset
                arrowless
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                   <Box>
                        <Text className="font-semibold">Upload Media</Text>
                        <Text className="text-xs font-normal text-gray-400">Upload images to your media gallery. PNG, JPG and SVG formats only.</Text>
                   </Box>
                }
                bodyContent={

                    <VStack spacing={10} className="!items-center !text-center !justify-center">

                        <Box className="w-full max-h-[365px] overflow-y-auto">
                            <UploadAsset maxFiles={10} files={files} setFiles={setFiles} data={config} onUpload={(data) => onHandleLoad(data)} />
                        </Box>

                        {
                            media?.data?.length > 0 && onSelectMedia &&

                            <>
            
                                <HStack className="w-full items-center">
                                    <Divider />
                                    <Text className="text-sm">Or</Text>
                                    <Divider />
                                </HStack>

                                <Box className="w-full">
                                    <HStack className="py-2 justify-between items-center">
                                        <Text className="text-left text-xs font-medium mb-2">Choose from your gallery:</Text>
                                        <Button onClick={() => {
                                            onOpenGallery()
                                            onClose()
                                        }} size={'xs'} className="!bg-primary-950 !text-white !text-xs">More</Button>
                                    </HStack>
                                    <Slider {...settings}>
                                        {
                                            media?.data?.map((media, index) => (
                                                <Box  onClick={() => {
                                                    setSelected(media?.link)
                                                    onClose()
                                                }} key={index}>
                                                    <Box px={2} className="min-w-[80px] min-h-[80px] overflow-hidden">
                                                        <Center className={`border cursor-pointer hover:bg-gray-100 w-[70px] h-[70px] ${selected === media?.link && 'bg-gray-100'}`}>
                                                            <img 
                                                                src={media?.link} 
                                                                alt={`Media ${index}`} 
                                                                className="w-[60px] h-[60px] object-cover" 
                                                            />
                                                        </Center>
                                                    </Box>
                                                </Box>
                                            ))
                                        }
                                    </Slider>
                                </Box>

                            </>
                        }

                            
                </VStack>

                }

                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={onClose} className="border">Close</Button>
                        <Button size={'sm'} onClick={onClose} className="!bg-primary-950 !text-white">Save</Button>
                    </ButtonGroup>
                }

            />

            {
                media?.data?.length > 0 && onSelectMedia &&
                <MediaGallery isOpen={isOpenGallery} onClose={onCloseGallery} media={media} defaultGetMedia={(page) => getMedia({page})} selected={selected} setSelected={setSelected} />
            }

        </>
    )

}


export default UploadMedia