import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
    useColorMode, Center, Heading, StackDivider, IconButton, Divider, Avatar, MenuItem, MenuDivider,
    Tooltip
} from '@chakra-ui/react'
import { RiDashboardLine, RiFileListLine, RiStoreLine, RiBankCardLine, RiGroupLine, RiMarkupLine, RiTicketLine, 
    RiPieChartLine, RiSettingsLine, RiBloggerLine, RiSidebarFoldLine, RiSidebarUnfoldLine, RiMoreLine, RiAccountCircleLine,
    RiCloseLine, RiImageLine, RiArrowRightSLine, RiArrowDownSLine, RiNotificationBadgeLine, RiSearch2Line
} 
from '@remixicon/react'
import { LightLogo, DarkLogo, DarkFavicon } from '@utils/images'
import {primary} from '@utils/theme'
import { useEffect, useState } from 'react'
import { SearchAsset, PopOverAsset, MenuAsset } from '@utils/assets'
import { Link, useLocation } from 'react-router-dom'
import { useAuthService } from '@services/index'


const SideBar = ({user, isFold, toggleFold, isSideBar, toggleSideBar}) => {

    const { first_name, last_name } = user || {}

    const {pathname} = useLocation()

    const isCurrent = (href, order) => {
        const currentRoute = `/${pathname.split('/')[order]}`
        return href.find(href => href === currentRoute)
    }

    const { logout } = useAuthService()

    const sideBarMenu = [
        {   
            title: 'Dashboard', 
            href: ['/', '/dashboard'], 
            icon: <RiDashboardLine size={20} />
        },
        {
            title: 'Orders', 
            href: ['/orders'], 
            sub: [
                {
                    title: 'List',
                    href: ['/list']
                }
            ],
            icon: <RiFileListLine size={20} />
        },
        {
            title: 'Products', 
            href: ['/products'], 
            sub: [
                {
                    title: 'List',
                    href: ['/list']
                },
                {
                    title: 'Categories',
                    href: ['/categories']
                },
                {
                    title: 'Sub Categories',
                    href: ['/subcategories']
                },
                {
                    title: 'Attributes',
                    href: ['/attributes']
                },
                {
                    title: 'Reviews',
                    href: ['/reviews']
                }
            ],
            icon: <RiStoreLine size={20} />
        },
        {
            title: 'Payments', 
            href: ['/payments'], 
            sub: [
                {
                    title: 'List',
                    href: ['/list']
                }
            ],
            icon: <RiBankCardLine size={20} />
        },
        {
            title: 'Users', 
            href: ['/users'], 
            sub: [
                {
                    title: 'List',
                    href: ['/list']
                }
            ],
            icon: <RiGroupLine size={20} />
        },
        {
            title: 'Designs', 
            href: ['/designs'], 
            sub: [
                {
                    title: 'List',
                    href: ['/list']
                }
            ],
            icon: <RiMarkupLine size={20} />
        },
        {
            title: 'Notifications', 
            href: ['/notifications'], 
            icon: <RiNotificationBadgeLine size={20} />
        },
        {
            title: 'Media', 
            href: ['/media'], 
            icon: <RiImageLine size={20} />
        },
        // {
        //     title: 'Support', 
        //     href: ['/support'], 
        //     sub: [
        //         {
        //             title: 'List',
        //             href: ['/list']
        //         }
        //     ],
        //     icon: <RiTicketLine size={20} />
        // },
        // {
        //     title: 'Analytics', 
        //     href: ['/analytics'], 
        //     sub: [
        //         {
        //             title: 'Orders',
        //             href: ['/orders']
        //         },
        //         {
        //             title: 'Products',
        //             href: ['/products']
        //         },
        //         {
        //             title: 'Payments',
        //             href: ['/payments']
        //         },
        //         {
        //             title: 'Users',
        //             href: ['/users']
        //         },            
        //         {
        //             title: 'Designs',
        //             href: ['/designs']
        //         },
        //         {
        //             title: 'Sales',
        //             href: ['/sales']
        //         },
        //         {
        //             title: 'Support',
        //             href: ['/support']
        //         }

        //     ],
        //     icon: <RiPieChartLine size={20} />
        // },
        // {
        //     title: 'CMS', 
        //     href: ['/cms'], 
        //     icon: <RiBloggerLine size={20} />
        // },
        {
            title: 'Settings', 
            href: ['/settings'], 
            sub: [
                // {
                //     title: 'Company',
                //     href: ['/company']
                // },
                {
                    title: 'Payment',
                    href: ['/payment']
                },
                {
                    title: 'Shipping',
                    href: ['/shipping']
                },
                // {
                //     title: 'Products',
                //     href: ['/products']
                // },            
                {
                    title: 'Coupon',
                    href: ['/coupon']
                },
                {
                    title: 'Admins',
                    href: ['/admins']
                },
                // {
                //     title: 'Support',
                //     href: ['/support']
                // }

            ],
            icon: <RiSettingsLine size={20} />
        }
    ]
    
    const accountMenu = [
        {
            title: 'Account', 
            href: ['/account'], 
            icon: <RiAccountCircleLine size={20} />
        },
        {
            title: 'Settings', 
            href: ['/settings'], 
            sub: [
                {
                    title: 'Company',
                    href: ['/settings/company']
                },
                {
                    title: 'Payment',
                    href: ['/settings/payment']
                },
                {
                    title: 'Shipping',
                    href: ['/settings/shipping']
                },
                {
                    title: 'Products',
                    href: ['/settings/products']
                },
                {
                    title: 'Coupon',
                    href: ['/settings/coupon']
                },
                {
                    title: 'Support',
                    href: ['/settings/support']
                }
            ],
            icon: <RiSettingsLine size={20} />
        }
    ]

    const AccountTrigger = () => (
 
        <HStack className={`hover:bg-primary-900 w-full !items-center text-white ${!isFold ? 'justify-between' : 'justify-center'}`}>
            <HStack className={`${isFold && 'justify-center items-center'} bg-red`}>
                <Avatar size={'sm'} name={`${first_name} ${last_name}`} />
                {!isFold && <Text className='text-xs'>{`${first_name} ${last_name}`}</Text>}
            </HStack>
                
            {
                !isFold && 
                <Box className='cursor-pointer'>
                    <RiMoreLine size={20} />
                </Box>
            }
        </HStack>
    )

    const AccountMenuView = () => (
        <MenuAsset
            arrowless
            trigger={Button}
            triggerContent={<AccountTrigger />}
            menuContent={
                <>
                    {
                        accountMenu.map((item, index) => (
                            <Link to={item?.href[0]} key={index}>
                                <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                                    <Text className='text-sm font-medium'>{item?.title}</Text>
                                </MenuItem>
                            </Link>
                        ))
                    }

                    <MenuDivider />

                    <Center>
                        <Button variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'} onClick={logout}>
                            Logout
                        </Button>
                    </Center>
                </>
            }
            listProps={{
                className: '!min-w-[180px]'
            }}
            triggerProps={{
                className: '!w-full',
                _active: {
                    color: 'primary.950',
                    textDecoration: 'none',
                },
                _hover: {
                    color: 'primary.950',
                    textDecoration: 'none'
                },
                size: 'sm', 
                variant: 'link', 
                rounded: 20
            }}
        />
    )

    const SideBarMenuItem = ({item}) => (
        <Box key={item.title} className={`hover:bg-primary-900 hover:text-primary-50 text-primary-100 p-2 w-full ${isCurrent(item.href, 1) && '!text-primary-50 bg-primary-900'}`}>
            <Link to={item.href[0]}>
                <Tooltip placement='right-end' label={item.title} hasArrow isDisabled={!isFold} bg='white' color='black'>
                    <HStack className={`${isFold && 'justify-center items-center'}`}>
                        {item.icon}
                        {
                            !isFold &&
                            <>
                                <Text className='text-sm'>{item.title}</Text>
                                {
                                    item?.sub && 
                                    <>
                                        {
                                            isCurrent(item.href, 1) ? 
                                            <RiArrowDownSLine className='ml-auto' size={20} /> :
                                            <RiArrowRightSLine className='ml-auto' size={20} />
                                        }
                                    </>
                                }
                            </>
                        }
                    </HStack>
                </Tooltip>
            </Link>
            {
                !isFold && item?.sub && isCurrent(item.href, 1) &&
                <VStack className='ml-7 mt-3 !items-start !justify-center'>
                    
                    {
                        item?.sub?.map((sub, _) => (
                           <Link key={sub?.title} to={`${item.href[0]}${sub.href[0]}`} className={`text-[12px] text-primary-100 ${isCurrent(sub.href, 2) && '!text-primary-50'}`}>
                                {sub.title}
                           </Link>
                        ))
                    }

                </VStack>
            }
        </Box>
    )

    const SideBarMenuView = () => (
        
        <Box className='w-full h-full bg-primary-950'>

            <VStack className='px-3 py-5 w-full h-full justify-between items-start'>

                <VStack spacing={8} className='w-full h-11/12 overflow-y-auto'>

                    <HStack className='w-full justify-between items-center'>
                        <HStack>
                            {
                                isSideBar &&
                                <Box onClick={() => toggleSideBar(!isSideBar)} className='cursor-pointer lg:hidden block'>
                                    <RiCloseLine className='text-primary-50' size={24} />
                                </Box>
                            }
                            <Link to={'/'}>
                                {
                                    !isFold ?
                                    <DarkLogo width={80} /> :
                                    <DarkFavicon width={20} />
                                }
                            </Link>
                        </HStack>
                        <Box onClick={() => toggleFold(!isFold)} className='cursor-pointer lg:block hidden'>
                            {
                                !isFold ? 
                                <RiSidebarFoldLine className='text-white' size={20} /> : 
                                <RiSidebarUnfoldLine className='text-white' size={20} />
                            }
                        </Box>
                    </HStack>

                    {   
                        !isFold ? 
                        <Box className='w-full'>
                            <SearchAsset mini />
                        </Box>
                        :
                        <Box className='w-full'>
                            <SearchAsset sidebar />
                        </Box>
                    }

                    <VStack spacing={3} className='w-full'>

                        {
                            sideBarMenu.splice(0, 6).map((item, index) => (
                                <SideBarMenuItem key={index} item={item} />
                            ))
                        }   

                        <Divider className='!border-primary-800' />

                        {
                            sideBarMenu.splice(0, 5).map((item, index) => (
                                <SideBarMenuItem key={index} item={item} />
                            ))
                        }

                    </VStack>


                </VStack>

                <AccountMenuView />
                    
            </VStack>

        </Box>

    )

    return (
        <Box className='w-full h-full'>
            {SideBarMenuView()}
        </Box>
    )

}


export default SideBar