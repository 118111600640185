import { useState, useEffect} from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center, Tab,
    TabPanel, TabPanels, Select, ButtonGroup
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiMoreFill} from "@remixicon/react"
import { localNumber, statusColors, formatDate} from "@utils/helpers"
import { MenuAsset, TableAsset, DrawerAsset, TabAsset, PromptAsset, SelectAsset, ButtonAsset, usePasswordToggle} from "@utils/assets"
import { FieldControl, clearInputErrors} from "@components/form"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useAccountService } from "@services/index"

const AdminDetails = ({isOpen, onClose, admin, getAdmins}) => {

    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    const [prompt, setPrompt] = useState('');

    const { resendInvitation, deleteAdmin, toggleAdmin, response, load, requestErrors } = useAccountService()

    useEffect(() => {
        if (response || requestErrors) {
            onClose()
            onClosePrompt()
            if (response) getAdmins()
        }
    }, [response, requestErrors])
    
    const onHandlePromptCall = () => {
        
        switch (prompt) {
            case 'resend_invitation':
                resendInvitation({id: admin?.id})
                break;
            case 'delete_admin':
                deleteAdmin({id: admin?.id})
                break;
            case 'toggle_admin':
                toggleAdmin({id: admin?.id})
                break;
            default:
                break;
        }

    }

    const AdminProfile = () => {

        const roles = [
            {
                label: "Super Admin",
                value: "super admin"
            },
            // {
            //     label: "Support Agent",
            //     value: "support agent"
            // },
            // {
            //     label: "Design Manager",
            //     value: "design manager"
            // },
            // {
            //     label: "Content Manager",
            //     value: "content manager"
            // },
            // {
            //     label: "Order Manager",
            //     value: "order manager"
            // },
            // {
            //     label: "Payment Admin",
            //     value: "payment admin"
            // },
            // {
            //     label: "Product Manager",
            //     value: "product manager"
            // },
            // {
            //     label: "Shipping Admin",
            //     value: "shipping admin"
            // },
            // {
            //     label: "User Manager",
            //     value: "user manager"
            // },
            // {
            //     label: "Operations Manager",
            //     value: "operations manager"
            // }
        ];

        const [errors, setError] = useState({})
        const [data, setData] = useState({});

        const { updateAdmin, response, load, requestErrors } = useAccountService()
        const {validate, validationErrors} = useValidator(rules.update_admin_profile)
        
        //Handles Form Inputs
        const onHandleChange = (name, value) => {
            //Sets Input to State
            setData((prevData) => ({ ...prevData, [name]: value }));

            //Validate Input
            validate(name, value)
        };

        //Adds Validation Errors to Error State
        useEffect(() => {
            setError(validationErrors)
        }, [validationErrors])

        //Adds Request Errors to Error State
        useEffect(() => {
            setError(requestErrors)
        }, [requestErrors])

        //Add Admin Data to State
        useEffect(() => {
            if (admin) {
                setData({ 
                    first_name: admin?.first_name, 
                    last_name: admin?.last_name,
                    email: admin?.email,
                    username: admin?.username,
                    email_verified_at: admin?.email_verified_at,
                    roles: admin?.roles?.map((role) => role?.role)
                })
            }
        }, [admin])

        useEffect(() => {
            if (response) {
                getAdmins()
            }
        }, [response])

        //Disable Submit Button On Conditions
        const isDisabled = load || Object.keys(data).some(field => 
            Array.isArray(data[field]) ? data[field].length === 0 : !data[field]
        ) || Object.keys(errors).length > 0;

        const submit = () => {
            if (!isDisabled) {
                clearInputErrors(setError)
                updateAdmin({...data, id: admin?.id})   
            }
        }

        return (

            <>

                <Box className="mb-4 h-full w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">

                    <FieldControl error={errors?.first_name?.[0]} label={'First Name'}>
                        <Input 
                            size={'md'} 
                            fontSize={'xs'} 
                            placeholder="Enter first name" 
                            value={data.first_name} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('first_name', event.target.value)} 
                        />
                    </FieldControl>

                    <FieldControl error={errors?.last_name?.[0]} label={'Last Name'}>
                        <Input 
                            size={'md'} 
                            fontSize={'xs'} 
                            placeholder="Enter last name" 
                            value={data.last_name} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('last_name', event.target.value)} 
                        />
                    </FieldControl>

                    <FieldControl error={errors?.email?.[0]} label={'Email Address'}>
                        <Input 
                            size={'md'} 
                            fontSize={'xs'} 
                            placeholder="Enter email address" 
                            value={data.email} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('email', event.target.value)} 
                        />
                    </FieldControl>

                    <FieldControl error={errors?.username?.[0]} label={'Username'}>
                        <Input 
                            size={'md'} 
                            fontSize={'xs'} 
                            placeholder="Enter username" 
                            value={data.username} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('username', event.target.value)} 
                        />
                    </FieldControl>

                    <FieldControl error={errors?.email_verified_at?.[0]} label={'Email Verified At'}>
                        <Input 
                            size={'md'} 
                            fontSize={'xs'} 
                            type={'date'}
                            value={formatDate({date: data?.email_verified_at, format: 'YYYY-MM-DD'})} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}   
                            onChange={(event) => onHandleChange('email_verified_at', event.target.value)} 
                        />
                    </FieldControl>

                </Box>

                <Box className="mb-5">
                    <FieldControl error={errors?.roles?.[0]} label={'Roles'}>
                        <SelectAsset
                            value={data?.roles}
                            options={roles}
                            placeholder={'Select Roles'}
                            isSearch
                            isMulti
                            onChange={(selected) =>  onHandleChange('roles', selected)}
                        />
                    </FieldControl>
                </Box>

                <Box className="mr-auto">
                    <ButtonAsset onClick={submit} buttonProps={{isLoading: load, isDisabled, size: 'sm'}}>
                        Update Profile
                    </ButtonAsset>
                </Box>

            </>

        )

    }

    const AdminSecurity = () => {

        const [errors, setError] = useState({})
        const [data, setData] = useState(
            { 
                new_password: '',
                new_password_confirmation: ''
            }
        );

        const { changeAdminPassword, response, load, requestErrors } = useAccountService()

        const {togglePass:togglePassNew, PasswordToggle:PasswordToggleNew} = usePasswordToggle()
        const {togglePass:togglePassConfirm, PasswordToggle:PasswordToggleConfirm} = usePasswordToggle()

        const {validate, validationErrors} = useValidator(rules.change_password)
        
        //Handles Form Inputs
        const onHandleChange = (name, value) => {
            //Sets Input to State
            setData((prevData) => ({ ...prevData, [name]: value }));

            //Validate Input
            validate(name, value)
        };

        //Adds Validation Errors to Error State
        useEffect(() => {
            setError(validationErrors)
        }, [validationErrors])

        //Adds Request Errors to Error State
        useEffect(() => {
            setError(requestErrors)
        }, [requestErrors])

        useEffect(() => {
            if (response) {
                setData({ 
                    new_password: '', 
                    new_password_confirmation: ''
                })
            }
        }, [response])


        //Disable Submit Button On Conditions
        const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

        const submit = () => {
            if (!isDisabled) {
                clearInputErrors(setError)
                changeAdminPassword({...data, id: admin?.id})   
            }
        }

        return (

            <>

                <Box className="mb-5 h-full w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">

                    <FieldControl 
                        error={errors?.new_password?.[0]}
                        label={'New Password'}
                        rightElementProps={{pr: 3}}
                        rightElement={
                            <PasswordToggleNew />
                        }
                    >
                        <Input 
                            size={{base: 'lg', lg: 'md'}}
                            value={data.new_password} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}  
                            onChange={(event) => onHandleChange('new_password', event.target.value)} 
                            type={togglePassNew ? 'password': 'text'} 
                            fontSize={'xs'} 
                            placeholder="Enter new password"
                        />
                    </FieldControl>

                    <FieldControl 
                        error={errors?.new_password_confirmation?.[0]}
                        label={'Confirm New Password'}
                        rightElementProps={{pr: 3}}
                        rightElement={
                            <PasswordToggleConfirm />
                        }
                    >
                        <Input 
                            size={{base: 'lg', lg: 'md'}}
                            value={data.new_password_confirmation} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}  
                            onChange={(event) => onHandleChange('new_password_confirmation', event.target.value)} 
                            type={togglePassConfirm ? 'password': 'text'} 
                            fontSize={'xs'} 
                            placeholder="Enter new password"
                        />
                    </FieldControl>

                </Box>

                <Box className="mr-auto">
                    <ButtonAsset onClick={submit} buttonProps={{isLoading: load, isDisabled, size: 'sm'}}>
                        Change Password
                    </ButtonAsset>
                </Box>

            </>

        )

    }
      
    return (

        <Box>

            <DrawerAsset
                headerContent={
                    <Box>
                        <Text className="text-sm">{`${admin?.first_name} ${admin?.last_name}`} Details</Text>
                        <Text className="text-xs font-normal text-gray-400">
                            View detailed information about {`${admin?.first_name} ${admin?.last_name}`}, including roles, permissions, and activity logs.
                        </Text>
                        <ButtonGroup className="mt-5">
                            {
                                admin?.roles?.[0]?.status == true &&
                                <Button onClick={() => {
                                    onOpenPrompt()
                                    setPrompt('toggle_admin')
                                }} size={'sm'} className="!bg-primary-950 !text-white">{admin?.status ? 'Disable' : 'Enable'}</Button>
                            }
                            <MenuAsset
                                triggerProps={{className: '!text-xs', size: 'sm'}}
                                trigger={Button}
                                triggerContent={<RiMoreFill />}
                                listProps={{className: '!min-w-[120px]'}}
                                menuContent={
                                    <> 
                                        {
                                            !admin?.status && admin?.roles?.[0]?.status == false &&
                                            <>
                                                <MenuItem onClick={() => {
                                                    onOpenPrompt()
                                                    setPrompt('resend_invitation')
                                                }} _hover={{bg: 'primary.950', color: 'white'}}>
                                                    <Text className="text-xs font-medium capitalize">Resend Invitation</Text>
                                                </MenuItem>

                                                <MenuDivider />
                                            </>
                                        }



                                        <Center>
                                            <Button onClick={() => {
                                                onOpenPrompt()
                                                setPrompt('delete_admin')
                                            }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                                Delete
                                            </Button>
                                        </Center>
                                    </>
                                }
                        />
                        </ButtonGroup>
                    </Box>
                }
                drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'md'}}}
                bodyContent={
                    <Box className="mt-5">

                        <TabAsset
                            listProps={{ className: '!border-b-0 !p-1'}}
                            listContent={
                                <>
                                    <Tab _selected={{borderBottom: 0, bg: 'primary.950', color: 'white'}}>
                                        <Text fontSize={{base: 10, lg: 12}}>Profile</Text>
                                    </Tab>
                                    <Tab _selected={{borderBottom: 0, bg: 'primary.950', color: 'white'}}>
                                        <Text fontSize={{base: 10, lg: 12}}>Security</Text>
                                    </Tab>
                                </>
                            }
                            panelContent={
                                <TabPanels>

                                    <TabPanel className="!px-0">

                                        {AdminProfile()}

                                    </TabPanel>

                                    <TabPanel className="!px-0">

                                        {AdminSecurity()}

                                    </TabPanel>

                                </TabPanels>
                            }
                        />
                        
                    </Box>
                }
            />

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                load={load}
                title={'Please confirm'}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    onHandlePromptCall(prompt)
                }}
            />

        </Box>

    )

}

export default AdminDetails