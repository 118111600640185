import { useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import Statistics from "@components/header/components/StatDate"

const Index = () => {

    const [stat, setStat] = useState({})

    const { total_products, ordered_products, shipped_products, products_pending_shipment } = stat || {}

    const PageHeaderAction = () => (
        <ButtonGroup className="w-full">
            <LinkAsset linkProps={{className: '!bg-primary-950 !text-white'}} href={'/products/create'}>Create</LinkAsset>
            <Statistics setStat={setStat} />
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Products | Manage Inventory and Listings'}>

           <Box className="w-full h-full">

                <Box className="mb-5 w-full">
                    <PageHeader
                        title={'Products'}
                        description={`Explore and manage the complete inventory of products, with tools for tracking, editing, and updating listings.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Products'}
                                number={`${localNumber({number: total_products?.number, decimals: 0})}`}
                                helper={`${localNumber({number: total_products?.trend?.percentage, decimals: 2})}% In ${total_products?.trend?.period}`}
                                arrow={total_products?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Ordered Products'}
                                number={`${localNumber({number: ordered_products?.number, decimals: 0})}`}
                                helper={`${localNumber({number: ordered_products?.trend?.percentage, decimals: 2})}% In ${ordered_products?.trend?.period}`}
                                arrow={ordered_products?.trend?.arrow}
                                link={'/orders'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Shipped Products'}
                                number={`${localNumber({number: shipped_products?.number, decimals: 0})}`}
                                helper={`${localNumber({number: shipped_products?.trend?.percentage, decimals: 2})}% In ${shipped_products?.trend?.period}`}
                                arrow={shipped_products?.trend?.arrow}
                                link={'/orders'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Products Pending Shipment'}
                                number={`${localNumber({number: products_pending_shipment?.number, decimals: 0})}`}
                                helper={`${localNumber({number: products_pending_shipment?.trend?.percentage, decimals: 2})}% In ${products_pending_shipment?.trend?.period}`}
                                arrow={products_pending_shipment?.trend?.arrow}
                                link={'/orders'}
                            />
                        </Box>

                    </Box>

                    <Box>
                        <ProductList />
                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}


export default Index