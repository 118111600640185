import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import TicketList from "@modules/support/components/TicketList"
import { StatAsset, StatCard, MenuAsset, TabAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import { useLocation } from "react-router-dom"

const AnalyticsLayout = ({children}) => {

    const location = useLocation()
    const {pathname} = location || {}

    const tabs = [
        {
            title: 'Overview',
            href: ['/analytics/overview', '/analytics'],
        },
        {
            title: 'Orders',
            href: ['/analytics/orders']
        },
        {
            title: 'Products',
            href: ['/analytics/products']
        },
        {
            title: 'Payments',
            href: ['/analytics/payments']
        },
        {
            title: 'Users',
            href: ['/analytics/users']
        },
        {
            title: 'Designs',
            href: ['/analytics/designs']
        },
        {
            title: 'Sales',
            href: ['/analytics/sales']
        },
        {
            title: 'Support',
            href: ['/analytics/support']
        }
    ]

    const isPathActive = (hrefs) => {
        return hrefs.some(href => {
          if (href.endsWith('/*')) {
            // Remove the '/*' and match the prefix
            return pathname.startsWith(href.replace('/*', ''));
          }
          // Exact match
          return pathname === href;
        });
    };

    const PageHeaderAction = () => (

        <MenuAsset
            triggerProps={{className: '!text-xs', size: 'sm'}}
            trigger={Button}
            triggerContent={<Text>Switch Date</Text>}
            listProps={{
                className: '!min-w-[180px]'
            }}
            menuContent={
                <>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">24Hrs</Text>
                    </MenuItem>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">1 Week</Text>
                    </MenuItem>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">1 Month</Text>
                    </MenuItem>
                </>
            }
        />
    )

    return (
        <AuthenticatedLayout title={'Analytics and Reports'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Analytics and Reports'}
                        description={`Read analytics on this page`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 w-full overflow-x-auto">
                        <HStack>
                            {tabs.map((tab, index) => (
                                <Link 
                                    key={index}
                                    to={tab.href[0]}
                                    className={`${isPathActive(tab.href) ? 'bg-primary-950 text-white' : ''} py-2 px-3 text-xs rounded-none font-medium`}
                                >
                                    {tab.title}
                                </Link>
                            ))}
                        </HStack>
                    </Box>
                    
                    <Box>
                        {children}
                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default AnalyticsLayout