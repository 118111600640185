import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, 
    Input, VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center, ButtonGroup
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PromptAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill} from "@remixicon/react"
import { Link } from "react-router-dom"
import AddressForm from "./AddressForm"
import { useUserService } from "@services/index"

const AddressList = ({user, getUser}) => {
        
    const { addresses } = user || []

    const [item, setItem] = useState(null)    

    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()

    const {deleteUserAddress, response, load} = useUserService()

    useEffect(() => {
        if (response) {
            onClosePrompt()
            getUser({username: user?.username})
            setItem(null)
        }
    }, [response])

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-4" spacing={5}>

            <ButtonGroup>
                <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">Add Address</Button>  
            </ButtonGroup>

        </HStack>
    )

    return (
        <Box className="w-full">

            {FilterView()}

            <Box className="w-full grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4"> 
            
                {
                    addresses?.map((address, index) => (
                        <Box className="mb-10 bg-gray-100 p-5">

                            <HStack className="w-full !justify-between !items-start">

                                <VStack className="!items-start">
                                    <Text className="text-xs">{address?.first_name} {address?.last_name}</Text>
                                    <Text className="text-xs lg:w-11/12 w-full">{`${address?.address}, ${address?.city} ${address?.state} State, ${address?.country}`}.</Text>
                                    <Text className="text-xs">{address?.email}</Text>
                                    <Text className="text-xs">{address?.phone}</Text>
                                </VStack>

                                <ButtonGroup>
                                    <Button onClick={() => {
                                        onOpen()
                                        setItem(address)
                                    }} size={'sm'} className="border">Update</Button>
                                    <Button onClick={() => {
                                        setItem(address)
                                        onOpenPrompt()
                                    }} size={'sm'} className="!bg-red-500 !text-white">Delete</Button>
                                </ButtonGroup>

                            </HStack>

                        </Box>
                    ))
                }
                
            </Box>
            
            <AddressForm isOpen={isOpen} onClose={onClose} getUser={getUser} user={user} shipping_address={item} setItem={setItem} />

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={() => {
                    onClosePrompt()
                    setItem(null)
                }}
                load={load}
                title={'Please confirm'}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    deleteUserAddress({id: item?.user_id, address_id: item?.id})
                }}
            />

        </Box>
    )

}


export default AddressList