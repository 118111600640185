import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import SubCategoryList from "@modules/product/components/SubCategory/SubCategoryList"
import SubCategoryForm from "@modules/product/components/SubCategory/SubCategoryForm"

const List = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">Create Subcategory</Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Subcategories List | Manage Product Classifications'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Subcategories List'}
                        description={`View all product subcategories in a comprehensive list for efficient organization and management.`}
                        action={PageHeaderAction}
                    />
                </Box>

                <Box className="content">

                    <Box>
                        <SubCategoryList title={''} description={''} />
                    </Box>

                </Box>
               
           </Box>

           <SubCategoryForm isOpen={isOpen} onClose={onClose} />
        
        </AuthenticatedLayout>
    )

}


export default List