import { useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import SubCategoryList from "@modules/product/components/SubCategory/SubCategoryList"
import SubCategoryForm from "@modules/product/components/SubCategory/SubCategoryForm"
import { useProductService } from "@services/index"

const Index = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const { getSubCategories, response:sub_categories } = useProductService()

    useEffect(() => {
        getSubCategories()
    }, [])

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">Create Subcategory</Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Subcategories | Refine Product Organization'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Product Subcategories'}
                        description={`View and manage subcategories to provide detailed and structured classification for your products.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Sub Categories'}
                                number={localNumber({number: sub_categories?.sub_categories?.length, decimals: 0})}
                            />
                        </Box>

                    </Box>

                    <Box>
                        {
                            sub_categories &&
                            <SubCategoryList defaultItems={sub_categories?.sub_categories} />
                        }
                    </Box>

                </Box>

               
           </Box>
        
        <SubCategoryForm isOpen={isOpen} onClose={onClose} />

        </AuthenticatedLayout>
    )

}


export default Index