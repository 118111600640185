import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/payment/pages/Index'
import PaymentList from '@modules/payment/pages/List'


const PaymentRoutes = [

    <Route path={'/payments'}> 
        <Route index element={<Index />} />
        <Route path="list" element={<PaymentList />} />
    </Route>

]

export default PaymentRoutes