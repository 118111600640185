import { useEffect, useState } from "react"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, RadioGroup, StackDivider, Input, 
    Textarea, Center, IconButton, Divider, List, ListItem, useDisclosure, Select
} from "@chakra-ui/react"
import { RiCloseFill, RiAddFill, RiArrowDownSLine, RiExpandUpDownLine, RiSparklingFill} from "@remixicon/react"
import { ModalAsset, MenuAsset, PopOverAsset, SelectAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { toOrdinal } from "@utils/helpers"
import UploadMedia from "@modules/media/components/UploadMedia"


const ProductFinishingForm = ({data, onHandleChange, attributes}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isMediaOpen, onOpen: onMediaOpen, onClose:onMediaClose } = useDisclosure()

    const [editIndex, setIndex] = useState(null)
    const [mediaDetails, setMediaDetails] = useState(null)
    const [finishing, setFinishing] = useState({
        name: "",
        image: "",
        sizes: [
            {
                name: "",
                price: ""
            }
        ],
        placements: [
            {
                name: "",
                image: ""
            }
        ]
    })

    const onHandleFinishChange = (name, index, value) => {

        setFinishing((prevFinish) => {
            if (name === 'name' || name === 'image') {
                // Update the top-level 'name' or 'image' fields in finishing
                return { ...prevFinish, [name]: value };
            } else if (name === 'size' || name === 'price') {
                // Update sizes array, specifically the 'name' or 'price' of a specific index in sizes
                const updatedSize = {
                    ...prevFinish.sizes[index],
                    [name === 'size' ? 'name' : 'price']: value
                };
                return {
                    ...prevFinish,
                    sizes: [
                        ...prevFinish.sizes.slice(0, index),
                        updatedSize,
                        ...prevFinish.sizes.slice(index + 1)
                    ]
                };
            } else if (name === 'placementName' || name === 'placementImage') {
                // Update placements array, specifically the 'name' or 'image' of a specific index in placements
                const updatedPlacement = {
                    ...prevFinish.placements[index],
                    [name === 'placementName' ? 'name' : 'image']: value
                };
                return {
                    ...prevFinish,
                    placements: [
                        ...prevFinish.placements.slice(0, index),
                        updatedPlacement,
                        ...prevFinish.placements.slice(index + 1)
                    ]
                };
            }
            return prevFinish;
        });
    };

    const addPlacement = () => {
        const newPlacement = { name: "", image: "" };
        setFinishing(prevFinish => ({
            ...prevFinish,
            placements: [...(prevFinish.placements || []), newPlacement]
        }));
    };

    const removePlacement = (placementIndex) => {
        setFinishing(prevFinish => ({
            ...prevFinish,
            placements: prevFinish.placements.filter((_, i) => i !== placementIndex)
        }));
    };

    const addSize = () => {
        const newSize = { name: "", price: "" };
        setFinishing(prevFinish => ({
            ...prevFinish,
            sizes: [...(prevFinish.sizes || []), newSize]
        }));
    };

    const removeSize = (sizeIndex) => {
        setFinishing(prevFinish => ({
            ...prevFinish,
            sizes: prevFinish.sizes.filter((_, i) => i !== sizeIndex)
        }));
    };

    const onSelectMedia = (media) => {
        if (mediaDetails?.type === 'finish') {
            onHandleFinishChange('image', 0, media)
        }else if(mediaDetails?.type === 'placement'){
            onHandleFinishChange('placementImage', mediaDetails?.index, media)
        }
        setMediaDetails(null)
        onMediaClose()
    }

    const saveFinishing = () => {
        if (!isDisabled) {
            const finishings = [...(data?.finishings || [])];
    
            if (editIndex !== null) {
                finishings[editIndex] = finishing;
            } else {
                finishings.push(finishing);
            }
    
            onHandleChange('finishings', finishings);
            setFinishing(null)
            onClose();
        }
    };
    
    const editFinishing = (index, finishing) => {
        setIndex(index);
        setFinishing(finishing);
        onOpen();
    };
    
    const removeFinishing = (finishingIndex) => {
        const updatedFinishings = data?.finishings?.filter((_, index) => index !== finishingIndex);
        onHandleChange('finishings', updatedFinishings);
    };

    const isFinishingExists = (currentFinishing) =>
        data?.finishings?.some((finishing) => finishing.name === currentFinishing);

    const isSizeExists = (currentSize, currentSizeIndex) => 
        finishing?.sizes.some((size, sizeIndex) => sizeIndex !== currentSizeIndex && size.name === currentSize);

    const isPlacementExists = (currentPlacement, currentPlacementIndex) => 
        finishing?.placements.some((placement, placementIndex) => placementIndex !== currentPlacementIndex && placement.name === currentPlacement);

    const isDisabled = finishing?.sizes?.some(size => 
        !size.name || parseFloat(size.price) === 0 || size.price === ""
    ) || finishing?.placements?.some(placement => 
        !placement.name
    ) || !finishing?.sizes?.length || !finishing?.placements?.length;

    return (
        <>

            <Box className="w-full grid grid-cols-2 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-3 gap-4">
                {
                    data?.finishings?.map((finishing, index) => (
                        <VStack spacing={1} key={index} className="w-full h-[110px] mx-auto border p-2 relative">

                            <HStack className="w-full justify-end items-center">
                                <RiCloseFill onClick={() => removeFinishing(index)} size={18} className="cursor-pointer text-red-500" />
                            </HStack>

                            <VStack spacing={1} className="!justify-center !items-center cursor-pointer">
                                {
                                    finishing?.image ? 
                                    <img onClick={() => editFinishing(index, finishing)} src={finishing?.image} width={50} /> :
                                    <img onClick={() => editFinishing(index, finishing)} src={'/bg/products/no-image.png'} width={50} />
                                }
                                <Text className="text-xs">{finishing?.name}</Text>
                            </VStack>
                        </VStack>
                    ))
                }
                <Box className="w-full h-[110px]">
                    <IconButton onClick={onOpen} className="!h-full !w-full border" size={'sm'} icon={
                        <VStack spacing={1} className="!items-center !justify-center">
                            <RiSparklingFill size={25} />
                            <Text>Add Finishing</Text>
                        </VStack>
                    } />
                </Box>
            </Box>

            <ModalAsset
                arrowless
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                    <Box>
                        <Text className="font-semibold">Add Product Finishing</Text>
                        <Text className="text-xs font-normal text-gray-400">Add unique product finishing and their available sizes.</Text>
                    </Box>
                }
                bodyContent={

                    <VStack spacing={8} className="!items-center !text-center !justify-center">

                        <FieldControl label={'Choose finishing type'} labelProps={{className: '!text-xs'}}>

                            <Box className="lg:w-3/6 w-full">
                                <SelectAsset
                                    size="md"
                                    isSearch
                                    placeholder="Select Finishing"
                                    value={finishing?.['name']}
                                    options={attributes
                                        ?.find(attribute => attribute.name === 'Finishes')?.options?.filter(option => !isFinishingExists(option) || finishing?.['name'] === option)
                                        ?.map(option => ({ value: option, label: option }))
                                    }
                                    onChange={(selected) => onHandleFinishChange('name', 0, selected)}
                                />
                            </Box>

                        </FieldControl>    

                        <FieldControl label={'Add finishing image (optional)'} labelProps={{className: '!text-xs'}}>
                            <Box onClick={() => {
                                onMediaOpen()
                                setMediaDetails({type: 'finish', index: 0})
                            }} className="w-1/6 border p-2 cursor-pointer hover:bg-gray-100">
                                <Center>
                                    {
                                        finishing?.image ? 
                                        <img src={finishing?.image} width={70} /> :
                                        <img src={'/bg/products/no-image.png'} width={70} />
                                    }
                                </Center>
                            </Box>
                        </FieldControl>   

                        <FieldControl label={'Select finishing sizes and their prices'} labelProps={{className: '!text-xs'}}>
                        
                            <VStack spacing={5} className="!items-start justify-between w-full">
                                
                                {
                                   finishing?.sizes?.map((size, index) => (

                                        <HStack className="justify-between items-center w-full" key={index}>

                                            <Box className="w-3/6">
                                                <SelectAsset
                                                    size="md"
                                                    isSearch
                                                    placeholder="Select Size"
                                                    value={finishing?.sizes[index]?.name}
                                                    onChange={(selected) => onHandleFinishChange('size', index, selected)}
                                                    options={
                                                        attributes?.find(attribute => attribute.name === 'Sizes')
                                                        ?.options?.filter(option => !isSizeExists(option, index) || size?.name === option)
                                                        ?.map(option => ({ value: option, label: option }))
                                                    }
                                                />
                                            </Box>

                                            <HStack className="w-3/6">
                                            
                                                <FieldControl leftElement={
                                                    <Box className="px-2 border-r">
                                                        <Text className="text-sm">₦</Text>
                                                    </Box>
                                                }>
                                                    <Input 
                                                        size={{ base: 'lg', lg: 'md' }}
                                                        fontSize={'xs'} 
                                                        type="number"
                                                        placeholder="Price"
                                                        value={finishing?.sizes[index]?.price}
                                                        onChange={(e) => onHandleFinishChange('price', index, e.target.value)}
                                                    />
                                                </FieldControl>

                                            </HStack>

                                            <HStack className="w-1/6 justify-end">
                                                <IconButton 
                                                    className="!bg-red-500 !text-white" 
                                                    size={'sm'} 
                                                    icon={<RiCloseFill size={20} />} 
                                                    onClick={() => {
                                                        removeSize(index)
                                                    }}
                                                />
                                            </HStack>
                                            
                                        </HStack>
                                            
                                    ))
                                }

                                <HStack className="items-center">
                                    <IconButton onClick={addSize} className="!bg-primary-950 !text-white" size={'sm'} icon={<RiAddFill size={20} />} />
                                    <Text className="text-xs">Add Size</Text>
                                </HStack>
                            </VStack>

                        </FieldControl>

                        <FieldControl label={'Select finishing placements and their images'} labelProps={{className: '!text-xs'}}>
                        
                            <VStack spacing={5} className="!items-start justify-between w-full">
                                
                                {
                                    finishing?.placements?.map((placement, index) => (

                                        <HStack className="justify-between items-center w-full" key={index}>

                                            <HStack className="w-5/6">
                                                <FieldControl>

                                                    <SelectAsset
                                                        size="md"
                                                        isSearch
                                                        placeholder="Select Placement"
                                                        value={finishing?.placements[index]?.name}
                                                        onChange={(selected) => onHandleFinishChange('placementName', index, selected)}
                                                        options={
                                                            attributes?.find(attribute => attribute.name === 'Placements')
                                                            ?.options?.filter(option => !isPlacementExists(option, index) || placement?.name === option)
                                                            ?.map(option => ({ value: option, label: option }))
                                                        }
                                                    />
                                                    
                                                </FieldControl>
                                                
                                                <FieldControl>
                                                    <Box onClick={() => {
                                                        onMediaOpen()
                                                        setMediaDetails({type: 'placement', index})
                                                    }} className="border p-2 cursor-pointer hover:bg-gray-100">
                                                        <Center>
                                                            {
                                                                placement?.image ? 
                                                                <img src={placement?.image} width={22} /> :
                                                                <img src={'/bg/products/no-image.png'} width={22} />
                                                            }
                                                        </Center>
                                                    </Box>
                                                </FieldControl>

                                            </HStack>

                                            <HStack className="w-1/6 justify-end">
                                                <IconButton 
                                                    className="!bg-red-500 !text-white" 
                                                    size={'sm'} 
                                                    icon={<RiCloseFill size={20} />}
                                                    onClick={() => {
                                                        removePlacement(index)
                                                    }}
                                                 />
                                            </HStack>
                                            
                                        </HStack>
                                            
                                    ))
                                }

                                <HStack className="items-center">
                                    <IconButton onClick={addPlacement} className="!bg-primary-950 !text-white" size={'sm'} icon={<RiAddFill size={20} />} />
                                    <Text className="text-xs">Add Placement</Text>
                                </HStack>
                            </VStack>

                        </FieldControl>
                                
                    </VStack>

                }

                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={() => {
                            onClose()
                            setIndex(null)
                            setFinishing(null)
                        }} className="border">Close</Button>
                        <Button size={'sm'} isDisabled={isDisabled} onClick={saveFinishing} className="!bg-primary-950 !text-white">Save</Button>
                    </ButtonGroup>
                }

            />

            <UploadMedia isOpen={isMediaOpen} onClose={onMediaClose} onSelectMedia={onSelectMedia} />
            
        </>
    )

}


export default ProductFinishingForm