import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button, 
    MenuItem, Avatar, Center, MenuDivider
} from '@chakra-ui/react'
import { RiMenuLine, RiCloseLine, RiAccountCircleLine, RiSettingsLine, RiNotification2Fill} from '@remixicon/react'
import {primary} from '@utils/theme'
import { ButtonAsset, SearchAsset, MenuAsset, BreadcrumbAsset} from '@utils/assets.jsx'
import { DarkFavicon } from '@utils/images'
import { Link } from 'react-router-dom'
import { useAuthService } from '@services/index'
import Notifications from './components/Notifications'

const Header = ({user, isSideBar, toggleSideBar, toggleFold}) => {

    const { first_name, last_name } = user || {}

    const { logout } = useAuthService()

    const accountMenu = [
        {
            title: 'Account', 
            href: ['/account'], 
            icon: <RiAccountCircleLine size={20} />
        },
        {
            title: 'Settings', 
            href: ['/settings'], 
            icon: <RiSettingsLine size={20} />
        }
    ]

    const AccountMenuView = () => (
        <MenuAsset
            arrowless
            trigger={Button}
            triggerContent={
                <HStack>
                    <Text className='lg:block hidden'>{first_name}</Text>
                    <Avatar size={'sm'} name={`${first_name} ${last_name}`} />
                </HStack>
            }
            menuContent={
                <>
                    {
                        accountMenu.map((item, index) => (
                            <Link to={item?.href[0]} key={index}>
                                <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                                    <Text className='text-sm font-medium'>{item?.title}</Text>
                                </MenuItem>
                            </Link>
                        ))
                    }

                    <MenuDivider />

                    <Center>
                        <Button variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'} onClick={logout}>
                            Logout
                        </Button>
                    </Center>
                </>
            }
            listProps={{
                className: '!min-w-[180px]'
            }}
            triggerProps={{
                className: '!w-full',
                _active: {
                    color: 'primary.950',
                    textDecoration: 'none',
                },
                _hover: {
                    color: 'primary.950',
                    textDecoration: 'none'
                },
                size: 'sm', 
                variant: 'link', 
                rounded: 20
            }}
        />
    )

    const NotificationMenuView = () => (
        <MenuAsset
            arrowless
            trigger={Button}
            triggerContent={<RiNotification2Fill size={15} />}
            listProps={{className: '!p-3 w-[350px]'}}
            menuContent={
                <Box>
                    <Text className='text-sm font-medium mb-3'>Notifications (20)</Text>
                    <VStack spacing={2} className='mb-3 !items-center h-[300px] overflow-y-auto'>
                        {
                            Array(20).fill(0)?.map((_, index) => (
                                <HStack key={index} className='cursor-pointer hover:bg-gray-100 p-2 justify-between items-center w-full'>
                                    <HStack>
                                        <Center className='bg-primary-950 rounded-full w-[20px] h-[20px]'>
                                            <RiNotification2Fill size={10} className='text-white' />
                                        </Center>
                                        <Box>
                                            <Box>
                                                <Text className='text-xs font-medium'>Order Placed</Text>
                                                <Text className='text-xs text-gray-400'>Godspower placed an order</Text>
                                            </Box>
                                        </Box>
                                    </HStack>
                                    <Box>
                                        <Text className='text-xs'>12 mins ago</Text>
                                    </Box>
                                </HStack>
                            ))
                        }
                    
                    </VStack>

                    <Center className='w-full'>
                        <Button size={'sm'} className='w-full'>More</Button>
                    </Center>

                </Box>
            }
            triggerProps={{
                className: '!w-full',
                _active: {
                    color: 'primary.950',
                    textDecoration: 'none',
                },
                _hover: {
                    color: 'primary.950',
                    textDecoration: 'none'
                },
                size: 'sm', 
                variant: 'link', 
                rounded: 20
            }}
        />
    )

    return (
        <Box className='w-full pb-3'>
            <HStack className="w-full justify-between items-center">

                <Box className='lg:block hidden'>
                    <BreadcrumbAsset />
                </Box>

                <Box className='lg:hidden justify-start block'>
                    <HStack>
                        <DarkFavicon width={22} />

                        <Box onClick={() => {
                            toggleSideBar(!isSideBar)
                            toggleFold(false)
                        }} className='cursor-pointer'>
                            <RiMenuLine className='text-primary-950' size={20} />
                        </Box>
                    </HStack>
                </Box>

                <HStack spacing={4} className='justify-end !items-center'>
                    
                    <Box className='lg:w-auto justify-center items-center'>
                        <SearchAsset header />
                    </Box>

                    <Box className='lg:w-auto justify-center items-center'>
                        <Notifications />
                    </Box>

                    <Box className='lg:w-auto justify-end items-center'>
                        <AccountMenuView />
                    </Box>
                </HStack>
            
            </HStack>
        </Box>
    )

}

export default Header;