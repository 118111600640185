import { useMemo } from "react"
import { useRequest } from "@handlers/index"
import { setStore, sessionExpires, appURL } from "@utils/helpers"
import { setUser } from "@modules/account/state/action"
import { useLocation, useNavigate } from "react-router-dom"
import Cookies from 'js-cookie'

const AuthService = () => { 

    const {errors: requestErrors, response, load, request} = useRequest()
    const location = useLocation()
    const navigate = useNavigate()

    
    //Fetches Sanctum Token For Login Requests
    const token = () => {
        request({
            method: 'get',
            url: `${appURL}/sanctum/csrf-cookie`
        }).then((response) => {
                
        }).catch(() => {})
    }

    //Verifies Invitation Token
    const verifyInvitation = async (data) => {
        request({
            method: 'post',
            url: `${appURL}/api/account/verify-invitation`,
            data
        }).then((response) => {
        }).catch(() => {})
    }

    //Invite the Admin
    const invite = async (data) => {
        request({
            method: 'post',
            url: `${appURL}/api/account/invite`,
            data
        }).then((response) => {
            setVerificationCookie(response?.data?.data)
        }).catch(() => {})
    }

    //Creates User Password
    const createPassword = async (data) => {
        request({   
            method: 'post',
            url: `${appURL}/api/create-password`,
            data
        }).then((response) => {
            setUserData(response?.data?.data)
            removeVerificationCookie()
            return navigate('/');
        }).catch(() => {})
    }   

    //Logs In the User
    const login = async (data) => {
      
        request({
            method: 'post',
            url: `${appURL}/api/login`,
            data
        }).then((response) => {
            const redirect = location?.search?.replace('?redirect=', '')
            setUserData(response?.data?.data)
            if(!response?.data.hasOwnProperty('redirect') && redirect){ 
                return navigate(redirect)
            }else if(!response?.data.hasOwnProperty('redirect')){
                return navigate('/');
            }
        }).catch(() => {})
    }

    //Check User Auth Status
    const checkAuth = async (data) => {
        request({
            method: 'get',
            url: `${appURL}/api/account/data`,
            data
        }).then((response) => {
            
            if (!response?.data?.data?.user?.first_name) {
                removeAuthCookie()
                removeVerificationCookie()
            }

        }).catch(() => {})
    }

    //Set User Data
    const setUserData = (data) => {
        setStore({...data?.user}, setUser)
        setAuthCookie(data)
        setVerificationCookie(data)
    }

    //Logs Out the User
    const logout = async () => {
        request({
            method: 'post',
            url: `${appURL}/api/logout`,
        }).then(() => {
            onLogout()
        }).catch(() => {})
    }

    //On Logout
    const onLogout = () => {
        removeAuthCookie()
        removeVerificationCookie()
        setStore({}, setUser)
        navigate('/auth/login')
    }

    //Verifies the User
    const verify = async (data) => {
        request({
            method: 'post',
            url: `${appURL}/api/account/${data?.reset}verify`,
            data
        }).then((response) => {
            if(data?.reset === 'reset/'){
                setVerificationCookie(response?.data?.data)
            }else{
                setUserData(response?.data?.data)
                removeVerificationCookie()
            }
        }).catch(() => {})
    }

    //Sends Verification Code To User
    const resendCode = async (data) => {
        request({
            method: 'post',
            url: `${appURL}/api/account/${data?.reset}resend-code`,
            data
        }).then((response) => {
            setVerificationCookie(response?.data?.data)
        }).catch(() => {})
    }

    //Resets User Password
    const reset = async (data) => {
        request({
            method: 'post',
            url: `${appURL}/api/forgot-password`,
            data
        }).then((response) => {
            setVerificationCookie(response?.data?.data)
        }).catch(() => {})
    }

    //Sets New Password
    const newPassword = async (data) => {
        request({
            method: 'post',
            url: `${appURL}/api/reset-password`,
            data
        }).then(() => {
            removeVerificationCookie()
        }).catch(() => {})
    }

    // Set Auth Cookie
    const setAuthCookie = (data) => {
        data?.user?.first_name && 
        Cookies.set('auth', JSON.stringify({logged: !!data?.user?.first_name, verified: !!data?.user?.email_verified_at}), { path: '/', expires: sessionExpires()})
    }

    // Get Auth Cookie
    const getAuthCookie = () => {
        const auth = Cookies.get('auth') ?? '{}';
        return JSON?.parse(auth)
    }

    //Remove Auth Cookie
    const removeAuthCookie = () => {
        Cookies.remove('auth')
    }
    
    //Set Verification Cookie
    const setVerificationCookie = (data) => {
        if(data?.verification?.token){
            Cookies.set('verification', JSON.stringify({otp: data?.verification?.otp, token: data?.verification?.token}), { path: '/' })   
        }
    }

    //Get Verification Cookie
    const getVerificationCookie = () => {
        const verification = Cookies.get('verification') ?? '{}';
        return JSON?.parse(verification)
    }

    //Remove Verification Cookie
    const removeVerificationCookie = () => {
        Cookies.remove('verification')
    }

    const services = {
        requestErrors,
        response,
        load,
        token,
        createPassword,
        resendCode,
        newPassword,
        verify,
        login,
        checkAuth,
        logout,
        reset,
        setUserData,
        setAuthCookie,
        getAuthCookie,
        removeAuthCookie,
        setVerificationCookie,
        getVerificationCookie,
        removeVerificationCookie,
        onLogout,
        invite,
        verifyInvitation
    }

    return services

    // return useMemo(() => (services), []);

}

export default AuthService