import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, VStack, Td, 
    Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors, truncateText} from "@utils/helpers"
import { RiMoreLine, RiMoreFill, RiStarFill } from "@remixicon/react"
import { Link } from "react-router-dom"
import { useProductService } from "@services/index"
import ReviewDetails from "./ReviewDetails"


const ReviewList = ({title, description, defaultItems}) => {

    const [item, setItem] = useState(null)
    const {isOpen, onOpen, onClose} = useDisclosure()

    const filters = {
        status: ['approved', 'pending'],
        rating: ['1', '2', '3', '4', '5'],
    }

    const [ filter, setFilter ] = useState({})
    const { getReviews, response } = useProductService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.reviews?.data ?? defaultItems)
    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.reviews || {}

    useEffect(() => {
        if (!defaultItems) {
            getReviews(filter)
        }else{
            setSearchItems(defaultItems)
        }
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            // If value is not empty, set the filter state
            if (value && value.length > 0) {
                updatedFilter[name] = value;
            } else {
                // If value is empty or falsy, delete the filter key
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    };

    const FilterView = () => (

        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            {
                !defaultItems && 

                <HStack spacing={5}>
                    { 
                        Object.keys(filters)?.map((title, index) => (
                            <Box key={index}>
                                <MenuAsset
                                    triggerProps={{className: '!text-xs capitalize', size: 'sm'}}
                                    trigger={Button}
                                    triggerContent={<Text>{title.replace("_", " ")}</Text>}
                                    menuContent={
                                        <CheckboxGroup 
                                            onChange={(value) => onHandleFilter(title, value)} 
                                            value={filter[title] || []} // Pass the corresponding filter state here
                                        >
                                            {filters[title].map((filterItem, filterIndex) => (
                                                <MenuItem key={filterIndex}>
                                                    <Checkbox value={filterItem} size={'lg'}>
                                                        <Text className="text-xs font-medium capitalize">{filterItem}</Text>
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                        </CheckboxGroup>
                                    }
                                />
                            </Box>
                        ))
                    }


                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Date/Time</Text>}
                        menuContent={
                            <VStack spacing={5} className="!px-4 !py-2 items-center">
                                <Box>
                                    <Text className="text-center text-xs">From</Text>
                                    <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>

                                <Box>
                                    <Text className="text-center text-xs">To</Text>
                                    <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>
                            </VStack>
                        }
                    />

                </HStack>

            }

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for reviews'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">ID</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Rating</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Product</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">User</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Title</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Comment</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                    {   

                        results?.map((review, index) => (
                            
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index + 1 + (per_page || 0) * ((current_page || 1) - 1)}</Td>
                                <Td>{review?.id}</Td>
                                <Td>
                                    <HStack spacing={0}>
                                        <Text className="text-xs">({review?.rating})</Text>
                                        {
                                            Array(review?.rating)?.fill(0)?.map((_, index) => (
                                                <RiStarFill 
                                                    key={index}
                                                    size={15} 
                                                    className="text-amber-300" 
                                                />
                                            ))
                                        }
                                    </HStack>
                                </Td>
                                <Td>
                                    <Link to={`/products/${review?.product?.name}`}>
                                        <HStack>
                                            <img src={review?.product?.images[0]} alt={review?.product?.name} width={40} />
                                            <Text className="underline">{review?.product?.name}</Text>
                                        </HStack>
                                    </Link>
                                </Td>
                                <Td>
                                    <Link to={`/users/${review?.user?.username}`}>
                                        <HStack>
                                            <Avatar size={'sm'} name={`${review?.user?.first_name} ${review?.user?.last_name}`} />
                                            <Text className="underline">{`${review?.user?.first_name} ${review?.user?.last_name}`}</Text>
                                        </HStack>
                                    </Link>
                                </Td>
                                <Td>{truncateText({text: review?.title ?? "None", length: 50})}</Td>
                                <Td>{truncateText({text: review?.comment, length: 50})}</Td>
                                <Td textAlign={{base: 'right', lg: 'left'}}>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(review?.status ? 'approved' : 'pending')} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{review?.status ? 'Approved' : 'Pending'}</Text>
                                    </Badge>
                                </Td>
                                <Td isNumeric>
                                    <MenuAsset
                                        triggerProps={{className: '!text-xs', size: 'sm'}}
                                        trigger={Button}
                                        triggerContent={<RiMoreFill size={20} />}
                                        listProps={{
                                            className: '!min-w-[120px]'
                                        }}
                                        menuContent={
                                            <>  

                                                {
                                                    !review?.status &&
                                                    <>
                                                        <MenuItem onClick={() => {
                                                            onOpen()
                                                            setItem(review)
                                                        }} _hover={{bg: 'primary.950', color: 'white'}}>
                                                            <Text className="text-xs font-medium capitalize">Approve</Text>
                                                        </MenuItem>

                                                        <MenuDivider />
                                                    </>
                                                }

                                                <Center>
                                                    <Button onClick={() => {
                                                        onOpen()
                                                        setItem(review)
                                                    }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                                        Delete
                                                    </Button>
                                                </Center>
                                            </>
                                        }
                                    />
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getReviews({filter, page})}
                />
            }
        />
    )

    return (
        <Box>   

            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "Review List"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "Browse main product reviews."}</Text>
            </Box>
            
            {FilterView()}

            {TableView()}

            <ReviewDetails isOpen={isOpen} onClose={onClose} review={item} getReviews={getReviews} />
            
        </Box>
    )

}


export default ReviewList