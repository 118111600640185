import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, LinkAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill} from "@remixicon/react"
import { Link } from "react-router-dom"
import { usePaymentService } from "@services/index"

const PaymentList = ({title, description, defaultItems}) => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)

    const filters = ['pending', 'successful', 'failed']

    const [filter, setFilter] = useState({})
    const {getPayments, response, load} = usePaymentService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(defaultItems ?? response?.payments?.data)
    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.payments || {}

    useEffect(() => {
        if (!defaultItems) {
            getPayments(filter)
        }else{
            setSearchItems(defaultItems)
        }
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            if (value) {
                updatedFilter[name] = value;
            } else {
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    }

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            {
                !defaultItems &&
                <HStack spacing={5}>
                
                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Status</Text>}

                        menuContent={
                            <CheckboxGroup onChange={(value) => onHandleFilter('status', value)} value={filter?.status}>
                                {
                                    filters.map((filter, index) => (
                                        <MenuItem key={index}>
                                            <Checkbox value={filter} size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                        </MenuItem>
                                    ))
                                }
                            </CheckboxGroup>
                        }
                    />

                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<RiMoreFill size={20} />}

                        menuContent={
                            <VStack spacing={5} className="!px-4 !py-2 items-center">
                                <Box>
                                    <Text className="text-center text-xs">From</Text>
                                    <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>

                                <Box>
                                    <Text className="text-center text-xs">To</Text>
                                    <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>
                            </VStack>
                        }
                    />

                </HStack>
            }

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for payments'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Reference</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">User</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Amount</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>

                    {
                        results?.map((payment, index) => (
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index + 1 + (per_page || 0) * ((current_page || 1) - 1)}</Td>
                                <Td>{payment?.reference} </Td>
                                <Td>
                                    <Link to={`/users/${payment?.user?.username}`}>
                                        <HStack>
                                            <Avatar size={'sm'} name={`${payment?.user?.first_name} ${payment?.user?.last_name}`} />
                                            <Text className="underline">{`${payment?.user?.first_name} ${payment?.user?.last_name}`}</Text>
                                        </HStack>
                                    </Link>
                                </Td>
                                <Td>₦{localNumber({number: payment?.amount})}</Td>
                                <Td>
                                    <Badge className="mb-3 !px-3" colorScheme={statusColors(payment?.status)} variant={'subtle'}>
                                        <Text className="font-normal" fontSize={10}>{payment?.status}</Text>
                                    </Badge>
                                </Td>
                                <Td>{formatDate({date: payment?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>

                                <Td isNumeric>  

                                    {
                                        payment?.status === 'successful' ? 
                                        <LinkAsset href={`/orders/${payment?.reference}`}>
                                            Order Details
                                        </LinkAsset>
                                        :
                                        <LinkAsset linkProps={{target: '_blank'}} href={payment?.metadata?.url}>
                                           Link
                                        </LinkAsset>
                                    }
                                    
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getPayments({filter, page})}
                />
            }
        />
    )

    return (
        <Box>   
            
            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "Payment Transactions"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "A detailed table view of all payment transactions with columns for reference, method, status, and order details."}</Text>
            </Box>

            {FilterView()}

            {TableView()}
            
        </Box>
    )

}


export default PaymentList