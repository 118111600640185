import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, LinkAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill, RiFileImageLine, RiFilePdf2Line} from "@remixicon/react"
import { Link } from "react-router-dom"
import { useOrderService } from "@services/index"


const DesignList = ({title, description, defaultItems}) => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [item, setItem] = useState(null)

    const filters = ['uploaded', 'submitted', 'confirmed', 'declined']    
    
    const [filter, setFilter] = useState({})
    const {getOrderItems, response, load} = useOrderService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.order_items?.data ?? defaultItems)
    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.order_items || {}

    useEffect(() => {
        if (!defaultItems) {
            getOrderItems(filter)
        }else{
            setSearchItems(defaultItems)
        }
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            if (value) {
                updatedFilter[name] = value;
            } else {
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    }

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            {
                !defaultItems &&

                <HStack spacing={5}>
                
                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Status</Text>}
                        menuContent={
                            <CheckboxGroup onChange={(value) => onHandleFilter('status', value)} value={filter?.status}>
                                {
                                    filters.map((filter, index) => (
                                        <MenuItem key={index}>
                                            <Checkbox value={filter} size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                        </MenuItem>
                                    ))
                                }
                            </CheckboxGroup>
                        }
                    />

                    <MenuAsset
                        triggerProps={{className: '!text-xs', size: 'sm'}}
                        trigger={Button}
                        triggerContent={<Text>Date/Time</Text>}
                        menuContent={
                            <VStack spacing={5} className="!px-4 !py-2 items-center">
                                <Box>
                                    <Text className="text-center text-xs">From</Text>
                                    <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>

                                <Box>
                                    <Text className="text-center text-xs">To</Text>
                                    <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                                </Box>
                            </VStack>
                        }
                    />

                </HStack>

            }

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for designs'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">ID</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">File</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Order</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">User</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Date</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr> 
            }
            bodyContent={
                <>
                    {   
                        results?.map((item, index) => {
                                    
                            if(item?.design){
                                
                                return (

                                    <Tr key={index} className="font-semibold text-xs">
                                        <Td>{index + 1 + (per_page || 0) * ((current_page || 1) - 1)}</Td>
                                        <Td>{item?.metadata?.id}</Td>
                                        <Td>
                                            <HStack>
                                                {
                                                    item?.metadata?.design?.mimetype?.startsWith('image') ? 
                                                        <RiFileImageLine className="text-primary-600" />
                                                    :
                                                        <RiFilePdf2Line color={'red'} /> 
                                                }
                                                <Box>
                                                    <Link className="underline" to={item?.design?.link} target="_blank">{item?.metadata?.design?.name}</Link>
                                                    <Text>{(item?.metadata?.design?.size/1024).toFixed(0)} KB</Text>
                                                </Box>
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <Link to={`/orders/${item?.order?.reference?.toLowerCase()}`}>
                                                <HStack>
                                                    <img src={item?.product?.images[0]} alt={item?.product?.name} width={40} />
                                                    <Text className="underline">{item?.product?.name}</Text>
                                                </HStack>
                                            </Link>
                                        </Td>
                                        <Td>
                                            <Link to={`/users/${item?.user?.username}`}>
                                                <HStack>
                                                    <Avatar size={'sm'} name={`${item?.user?.first_name} ${item?.user?.last_name}`} />
                                                    <Text className="underline">{`${item?.user?.first_name} ${item?.user?.last_name}`}</Text>
                                                </HStack>
                                            </Link>
                                        </Td>
                                        <Td textAlign={{base: 'right', lg: 'left'}}>
                                            <Badge className="mb-3 !px-3" colorScheme={statusColors(item?.status)} variant={'subtle'}>
                                                <Text className="font-normal" fontSize={10}>{item?.status}</Text>
                                            </Badge>
                                        </Td>
                                        <Td className="lg:table-cell hidden">
                                            {formatDate({date: item?.created_at, format: 'MMM Do YYYY, h:mm A'})}
                                        </Td>
                                        <Td isNumeric>
                                            <LinkAsset linkProps={{target: '_blank'}} href={item?.design?.link}>Download</LinkAsset>
                                        </Td>
                                    </Tr>

                                )

                            }
                        
                        })
                    }
                </>
            }
            footerContent={
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  getOrderItems({page})}
                />
            }
        />
    )

    return (
        <Box>   

            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "Designs Table"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "An organized table showcasing design submissions, including key details for efficient management and review."}</Text>
            </Box>
            
            {FilterView()}

            {TableView()}
            
        </Box>
    )

}


export default DesignList