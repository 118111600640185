import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel, Input, Textarea, VStack} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import { Link } from "react-router-dom"
import SettingsLayout from "@modules/settings/components/SettingsLayout"
import CouponList from "@modules/settings/components/Coupon/CouponList"
import PageHeader from "@components/header/components/PageHeader"

const Coupon = () => {

    return (
        <SettingsLayout title={'Coupon Settings | Manage Discounts and Promotional Codes'}>

            <Box className="mb-5">
                <PageHeader
                    title={<Text className="text-sm">Coupon Settings</Text>}
                    description={<Text className="text-xs text-gray-500">Create, manage, and configure coupon codes, discounts, and promotional offers for your store.</Text>}
                />
            </Box>

            <CouponList />
        
        </SettingsLayout>
    )

}

export default Coupon