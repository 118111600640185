import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select,
    Switch
} from "@chakra-ui/react"
import { RiAddFill, RiCloseFill} from "@remixicon/react"
import { ModalAsset, SelectAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import countries from "@utils/countries.json"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useSettingsService } from "@services/index"
import { isArrayEqual, debounce } from "@utils/helpers"

const ShippingPricingForm = ({isOpen, onClose, prices, shipping, country, setItem, getShippings}) => {

    const [errors, setError] = useState({})
    const [data, setData] = useState(
        { 
            country: country ?? 'Nigeria'
        }
    );

    const { updateShippingPrices, response:new_shipping, requestErrors, load } = useSettingsService()
    const {validate, validationErrors} = useValidator(rules.create_shipping_pricing)

    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        if (new_shipping?.shipping) {
            if(setItem) setItem(new_shipping?.shipping)
            if(getShippings) getShippings()
        }
    }, [new_shipping])

    const groupByPrice = (pricing) => {
        const grouped = {};
    
        Object.keys(pricing || {}).forEach(country => {
            pricing[country].forEach(({ state, price }) => {
                if (!grouped[country]) grouped[country] = {};
                if (!grouped[country][price]) grouped[country][price] = [];
                grouped[country][price].push(state);
            });
        });
    
        return grouped;
    };
    
    const transformToDataFormat = (pricing, country) => {
        if (!pricing || !pricing[country] || pricing[country].length === 0) {
            return { country, prices: [] };
        }
    
        const grouped = groupByPrice({ [country]: pricing[country] });
    
        return {
            country,
            prices: grouped[country]
                ? Object.entries(grouped[country]).map(([price, states]) => ({
                    states,
                    price,
                }))
                : [],
        };
    };
     

    const setTransformedData = (country) => {
        const updatedData = transformToDataFormat(shipping.pricing, country);
        setData(prevData => ({
            ...prevData,
            prices: updatedData?.prices
        }));
    }

    useEffect(() => {
        if (shipping?.pricing) {
            setTransformedData(data?.country)
        }
    }, [shipping])

    const addState = () => {
        const price = { states: [], price: '' };
        setData(prevData => ({
            ...prevData,
            prices: [...(prevData.prices || []), price]
        }));
    };

    const removeState = (stateIndex) => {
        setData(prevData => ({
            ...prevData,
            prices: prevData.prices.filter((_, index) => index !== stateIndex)
        }));
    };

    const isStateExists = (currentState, currentStateIndex) => 
    data?.prices.some((price, priceIndex) => priceIndex != currentStateIndex && price?.states?.includes(currentState));


    const onHandleStateChange = (name, index, value) => {
        setData(prevData => ({
            ...prevData,
            prices: prevData.prices.map((price, idx) =>
                idx === index
                    ? { ...price, [name]: value }
                    : price
            )
        }));
    };

    const onHandleChange = (name, value) => {
        setData((prevData) => ({ ...prevData, [name]: value }));
        validate(name, value)
        if (name === 'country') {
            setTransformedData(value)
        }
    };

    const submit = () => {
        if (!isDisabled) {
            updateShippingPrices({...data, id: shipping?.id})
        }
    }

    const isDisabled = load || Object.keys(data).some(field => 
        data[field] != 'prices' && !data[field]
    ) || Object.keys(errors).length > 0 || data?.prices?.some(price => 
        !price?.states?.length || !price?.price
    );

    return (
        
        <ModalAsset
            arrowless
            modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: '3xl'}, closeOnOverlayClick: false}}
            headerContent={
            <Box>
                <Text className="font-semibold">Shipping Rate Configuration</Text>
                <Text className="text-xs font-normal text-gray-400">
                    Set or update the shipping rates for specific regions, states, or delivery zones to ensure accurate cost calculations.
                </Text>
            </Box>
            }
            bodyContent={
                <VStack spacing={7} className="!items-start !justify-center">
                                        
                    <FieldControl error={errors?.country?.[0]} label={'Country'}>
                        <SelectAsset 
                            value={data?.country}
                            options={countries?.map?.(country => ({value: country?.name, label: country?.name}))}
                            onChange={(selected) => {
                                if (selected != data?.country){
                                    onHandleChange('country', selected)
                                }
                            }}
                            isSearch
                            placeholder={"Select Country"}
                            size={'md'}
                        />
                    </FieldControl>

                    <FieldControl error={errors?.prices?.[0]} formProps={{className: 'mb-2'}} label={'States/Provinces'}>
                        <VStack spacing={5} className="!justify-between !items-start w-full">
                            {
                                data?.prices?.map((price, index) => (
                                    <Stack key={index} direction={['column', 'row']} className="w-full">
                                        
                                        <SelectAsset 
                                            value={data?.prices[index]?.states}
                                            options={
                                                countries?.find?.((country) => country?.name === data?.country)?.states?.length > 0
                                                    ? countries.find((country) => country?.name === data?.country)?.states?.filter(state => 
                                                          !isStateExists(state?.name, index)
                                                      )?.map((state) => ({
                                                          value: state?.name,
                                                          label: state?.name,
                                                      }))
                                                    : !isStateExists(data?.country, index) ? [
                                                          {
                                                              value: data?.country,
                                                              label: data?.country,
                                                          },
                                                      ] : 

                                                    []
                                            }
                                            onChange={(selected) => onHandleStateChange('states', index, selected)}
                                            isMulti
                                            isSearch
                                            placeholder={'Select States or Provinces'}
                                        />

                                    
                                        <FieldControl leftElement={
                                            <Box className="px-2 border-r">
                                                <Text className="text-sm">₦</Text>
                                            </Box>
                                        }>
                                            <Input
                                                size={{base: 'lg', lg: 'md'}} 
                                                fontSize={'xs'} 
                                                type="number"
                                                placeholder="Enter shipping price"
                                                value={data?.prices[index]?.price}
                                                onChange={(event) => onHandleStateChange('price', index, event?.target?.value)}
                                            />
                                        </FieldControl>

                                        <HStack className="w-1/6 justify-end">
                                            <IconButton 
                                                className="!bg-red-500 !text-white" 
                                                size={'sm'} 
                                                icon={<RiCloseFill size={20} />} 
                                                onClick={() => {
                                                    removeState(index)
                                                }}
                                            />
                                        </HStack>
                
                                    </Stack>
                                ))
                            }
                            <HStack className="items-center">
                                <IconButton onClick={addState} className="!bg-primary-950 !text-white" size={'sm'} icon={<RiAddFill size={20} />} />
                                <Text className="text-xs">Add State/Price</Text>
                            </HStack>
                        </VStack>
                    </FieldControl>
                    
                </VStack>
            }
            footerContent={
                <ButtonGroup>
                    <Button size={'sm'} onClick={onClose} className="border">Cancel</Button>
                    <Button size={'sm'} onClick={submit} isDisabled={isDisabled} isLoading={load} className="!bg-primary-950 !text-white">Save Changes</Button>
                </ButtonGroup>
            }

        />
    )

}


export default ShippingPricingForm