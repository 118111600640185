import { useEffect, useState } from "react";
import { Text, MenuItem, Button } from "@chakra-ui/react"
import { MenuAsset, SelectAsset } from "@utils/assets"
import { useAnalyticsService } from "@services/index";


const Statistics = ({setStat}) => {

    const { getStats, response } = useAnalyticsService()

    const dates = [
        {
            label: "24 Hours", 
            value: "24 Hours"
        },
        {
            label: "1 Week", 
            value: "1 Week"
        },
        {
            label: "1 Month", 
            value: "1 Month"
        },
        {
            label: "3 Months", 
            value: "3 Months"
        },
        {
            label: "6 Months", 
            value: "6 Months"
        },
        {
            label: "1 Year", 
            value: "1 Year"
        },
    ];

    useEffect(() => {
        response && setStat(response)
    }, [response])


    return (
        <>
            {SelectAsset({
                options: dates,
                placeholder: "Switch Date",
                size: 'sm',
                value: dates[2]?.value,
                onChange: (e) => getStats({period: e})
            })}
        </>
    )


}

export default Statistics