"use client";
import { useState, useEffect } from "react";
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
  Menu, MenuButton, MenuList, MenuItem, useColorMode, Center, Heading, StackDivider,
  IconButton, Divider, Avatar, Input, Textarea, Checkbox, useDisclosure, Select
} from '@chakra-ui/react'
import GuestLayout from "@components/layout/Guest";
import { RiGoogleFill, RiAppleFill, RiFacebookCircleFill} from "@remixicon/react";
import { FieldControl, clearInputErrors } from "@components/form";
import { ButtonAsset, usePasswordToggle } from "@utils/assets";
import rules from "@utils/rules.json"
import { useAuthService } from "@services/index";
import { useValidator } from "@handlers/index";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";



const CreatePassword = () => {    

    const navigate = useNavigate()
    const location = useLocation()
    const { search } = location || {}

    
    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            password: '',
            password_confirmation: ''
        }
    );

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {createPassword, load, requestErrors, response, getVerificationCookie} = useAuthService()
    const {verifyInvitation, response:invitation} = useAuthService()
    const { token, otp } = getVerificationCookie()

    const {togglePass, PasswordToggle} = usePasswordToggle()
    const {togglePass:togglePassConfirm, PasswordToggle:PasswordToggleConfirm} = usePasswordToggle()

    useEffect(() => {
        
        verifyInvitation({token})

    }, [token])
    
    const {validate, validationErrors} = useValidator(rules.createPassword)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            createPassword({...data, otp: otp, email: invitation?.target})
        }
    }

    return (
        <GuestLayout title={'Create Account'}>

            <VStack spacing={5} className="lg:w-10/12 w-full">

                <VStack spacing={4}>

                    <Box mb={5} className="w-10/12">
                        <Text className="font-bold text-2xl">Create Password</Text>
                        <Text className="text-sm text-gray-400">Enter a strong password to access your account.</Text>
                    </Box>

                </VStack>

                <VStack alignItems={'flex-start'} w={'100%'} spacing={8}>


                    <Box className="w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-8">

                        <Box className="w-full">
                            <FieldControl label={'Full Name'} labelProps={{fontSize: 'sm'}}>
                                <Input 
                                    value={`${invitation?.first_name} ${invitation?.last_name}`} 
                                    size={{base: 'lg', lg: 'md'}} 
                                    type="email" 
                                    fontSize={'sm'} 
                                    placeholder="Enter your name" 
                                    variant='flushed'
                                    disabled
                                />
                            </FieldControl>
                        </Box>

                        <Box className="w-full">
                            <FieldControl label={'Email Address'} labelProps={{fontSize: 'sm'}}>
                                <Input 
                                    value={invitation?.target} 
                                    size={{base: 'lg', lg: 'md'}} 
                                    type="email" 
                                    fontSize={'sm'} 
                                    placeholder="Enter your email address" 
                                    variant='flushed'
                                    disabled
                                />
                            </FieldControl>
                        </Box>

                        <FieldControl error={errors?.password?.[0]} labelProps={{fontSize: 'sm'}} label={'Password'} rightElementProps={{pr: 3}}
                            rightElement={
                                <PasswordToggle />
                            }
                        >
                            <Input 
                                value={data.password} 
                                onChange={(event) => onHandleChange('password', event.target.value)} 
                                onKeyDown={(e) => e.code === "Enter" && submit()}  
                                type={togglePass ? 'password': 'text'}  
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'sm'} 
                                placeholder="Create a password" 
                                variant='flushed'
                            />
                        </FieldControl>

                        <FieldControl error={errors?.password_confirmation?.[0]} labelProps={{fontSize: 'sm'}} label={'Re-Type Password'} rightElementProps={{pr: 3}}
                            rightElement={
                                <PasswordToggleConfirm />
                            }
                        >
                            <Input 
                                value={data.password_confirmation} 
                                onChange={(event) => onHandleChange('password_confirmation', event.target.value)} 
                                onKeyDown={(e) => e.code === "Enter" && submit()}  
                                type={togglePassConfirm ? 'password': 'text'}  
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'sm'} 
                                placeholder="Re-enter password" 
                                variant='flushed'
                            />
                        </FieldControl>

                    </Box>

                </VStack>

                <VStack mt={10} spacing={4} w={'100%'}>
                    <Center w={'100%'}>
                        <ButtonAsset isLoading={load} onClick={submit} buttonProps={{isDisabled, w: '100%'}}>
                           Create Password
                        </ButtonAsset>
                    </Center>
                    <Text className="text-xs text-gray-400">Already have an account? 
                        <Link className="text-primary-500 font-semibold" to={'/auth/login'}>Log In</Link>
                    </Text>
                </VStack>
        
            </VStack>
            
        </GuestLayout>
    )

}

export default CreatePassword