
//Calculate Order Amount Per Item
export const orderAmount = (item) => {
        
    const total = item.sizes.reduce((total_unit_price, size) => total_unit_price + (size.price * size.quantity), 0) +
    item.sizes.reduce((total_printing_price, size) => total_printing_price + ((item.finishing.price * size.quantity) * (item.placement_2 ? 2 : 1) ), 0)

    const discount = itemDiscount(item, total)

    return total - discount

}

//Calculate Discount Amount Per Item
export const itemDiscount = (item, total) => {
    const discount = item?.product?.discounts?.find(discount => total >= discount.min && total <= discount.max)
    return discount?.discount ?? 0
}

//Calculate Total Check Out Amount
export const checkoutAmount = (items, coupon_discount) => {

    let subtotal = items.reduce((total_amount, item) => total_amount + orderAmount(item), 0)

    subtotal = couponDiscount(subtotal, coupon_discount)

    return subtotal
}

//Calculate Coupon Discount Amount
export const couponDiscount = (subtotal, discount) => {

    const total = Math.max(0, subtotal - discount?.discount_amount - (subtotal * (discount?.discount_percentage / 100)));
    return total

}