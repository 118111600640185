import { useState } from "react"
import { VStack, HStack, Text, Button, ButtonGroup, Box, Center, MenuItem, StackDivider, 
    Input, Textarea, IconButton, useDisclosure
} from "@chakra-ui/react"
import { RiFilePdf2Fill, RiSendPlane2Fill} from "@remixicon/react"
import { DrawerAsset, MenuAsset, PromptAsset, LinkAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import { truncateText } from "@utils/helpers"
import { FieldControl } from "@components/form"

const OrderItemDetails = ({isOpen, onClose, item}) => {

    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()

    const [submitPrompt, setSubmitPrompt] = useState(() => {})

    const ManageDesignMenu = () => (
        <ButtonGroup>
            <LinkAsset linkProps={{className: '!bg-primary-950 !text-white', target: '_blank'}} href={item?.design?.link}>Download Design</LinkAsset>
        </ButtonGroup>
    )

    return (

        <>

            <DrawerAsset
                drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'sm'}}}
                bodyProps={{className: '!py-5'}}
                bodyContent={
                    <VStack divider={<StackDivider />} spacing={10} className="h-full w-full !text-left !items-start">

                        <VStack spacing={5} className="w-full !items-start">
                            <HStack className="!items-center">
                                <img src={item?.product?.images[0]} alt={item?.product?.name} width={50} />
                                <Box>
                                    <Link to={`/products/${item?.product?.name}`} className="hover:underline">
                                        <Text className="font-medium text-sm">
                                            {item?.product?.name}
                                        </Text>
                                    </Link>
                                    <Text className="text-xs text-gray-500">
                                        Detailed information for the order of {item?.product?.name}.
                                    </Text>
                                </Box>
                            </HStack>
                            {
                                item?.design &&
                                <ButtonGroup>
                                    <ManageDesignMenu />
                                </ButtonGroup>
                            }
                        </VStack>

                        <Box>
                            <Text className="mb-3 font-medium text-sm">Order Specifications</Text>
                            
                            <Box className="w-full grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-y-5 gap-x-10">

                                <Box>
                                    <Text className="font-bold text-xs">Base color</Text>
                                    <Text className="text-xs">{item?.metadata?.colour ?? 'None'}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Placement 1</Text>
                                    <Text className="text-xs">{item?.metadata?.placement_1 ?? 'None'}</Text>
                                </Box>
                                <Box>
                                    <Text className="font-bold text-xs">Placement 2</Text>
                                    <Text className="text-xs capitalize">{item?.metadata?.placement_2 ?? 'None'}</Text>
                                </Box>
                            
                                <Box>
                                    <Text className="font-bold text-xs">Finishing</Text>
                                    <Text className="text-xs capitalize">{item?.metadata?.finishing?.code ?? 'None'}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Quantity</Text>
                                    <Text className="text-xs capitalize">{item?.metadata?.sizes.reduce((quantity, size) => quantity + size.quantity, 0)} Piece(s)</Text>
                                </Box>

                            </Box>

                        </Box>

                        <Box>
                            <Text className="mb-3 font-medium text-sm">Order Sizes</Text>
                            
                            <Box className="w-full grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-y-5 gap-x-10">

                                {
                                    item?.metadata?.sizes.map((size, index) => (
                                        <Box key={index}>
                                            <Text className="font-bold text-xs">{size?.name}</Text>
                                            <Text className="text-xs">{size?.quantity} pieces(s)</Text>
                                        </Box>
                                    ))
                                }

                            </Box>

                        </Box>

                        <Box>
                            <Text className="mb-3 font-medium text-sm">Order Product Info</Text>
                            
                            <Box className="w-full grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-y-5 gap-x-10">

                                <Box>
                                    <Text className="font-bold text-xs">Name</Text>
                                    <Text className="text-xs">{item?.product?.name}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Category</Text>
                                    <Text className="text-xs">{item?.product?.category?.name}</Text>
                                </Box>
                                <Box>
                                    <Text className="font-bold text-xs">Sub Category</Text>
                                    <Text className="text-xs capitalize">{item?.product?.sub_category?.name}</Text>
                                </Box>
                            
                            </Box>

                        </Box>

                        
                    </VStack>
                }
            />
        
            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    onClosePrompt()
                }}
            />

        </>

    )

}


export default OrderItemDetails