import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie'

const Middleware = (props) => {

    let auth = Cookies.get('auth') ?? '{}';
    auth = JSON?.parse(auth)
    const location = useLocation()

    if (auth) {
        if (auth.logged && !auth.verified) {
          if (!location.pathname.startsWith('/auth/verification')) {
            return <Navigate to={'/auth/verification'} replace /> 
          }else{
            return <Outlet />
          }
        }
        if (auth.logged) {
          // Redirect logged-in users away from login or register pages
          if (location.pathname.startsWith('/auth')) {
            return <Navigate to={'/'} replace />
          }
          return <Outlet />
        }
    }

    // Allow unauthenticated access to login and register pages
    if (location.pathname.startsWith('/auth')) {
      return <Outlet />
    }

    return <Navigate to={`/auth/login?redirect=${location.pathname}`} replace />
};

export default Middleware