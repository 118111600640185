import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center, Tab,
    TabPanel, TabPanels, Select, ButtonGroup, StackDivider
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiMoreFill} from "@remixicon/react"
import { localNumber, statusColors, formatDate} from "@utils/helpers"
import { MenuAsset, TableAsset, DrawerAsset, TabAsset, SearchListAsset, PaginateAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import { FieldControl } from "@components/form"
import AdminDetails from "./AdminDetails"
import AdminInvite from "./AdminInvite"
import { useAccountService } from "@services/index"

const AdminList = ({title, description, defaultItems}) => {

    const [admin, setAdmin] = useState({})

    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen:isInviteOpen, onOpen:onInviteOpen, onClose:onInviteClose} = useDisclosure()

    const { getAdmins, resendInvitation, response } = useAccountService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.admins?.data ?? defaultItems)
    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = response?.admins || {}

    useEffect(() => {
        if (!defaultItems) {
            getAdmins()
        }else{
            setSearchItems(defaultItems)
        }
    }, [])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-4" spacing={5}>

            <ButtonGroup>
                <Button onClick={onInviteOpen} size={'sm'} className="!bg-primary-950 !text-white">Invite Admin</Button>  
            </ButtonGroup>

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for admins'})}
                
            </Box>

        </HStack>
    )

    return (

        <>

            {FilterView()}
        
            <TableAsset
                tableProps={{className: 'border-t'}}
                headerContent={
                    <Tr>
                        <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Username</Th>
                        <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Roles</Th>
                        <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Full Name</Th>
                        <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Email Address</Th>
                        <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Status</Th>
                        <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Reg Date</Th>
                        <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                    </Tr>
                }
                bodyContent={
                    <>

                        {
                            results?.map((admin, index) => (
                                <Tr key={index} className="font-medium text-xs">
                                    <Td>{admin?.username}</Td>
                                    <Td>
                                        <HStack className="flex-wrap w-[200px]">
                                            {
                                                admin?.roles?.map((role, index) => (
                                                    <Text className="capitalize text-xs">{role?.role}, </Text>
                                                ))
                                            }
                                        </HStack>
                                    </Td>
                                    <Td>
                                        <HStack>
                                            <Avatar size={'sm'} name={`${admin?.first_name} ${admin?.last_name}`} />
                                            <Text className="text-xs">{`${admin?.first_name} ${admin?.last_name}`}</Text>
                                        </HStack>
                                    </Td>
                                    <Td>{admin?.email}</Td>
                                    <Td>
                                        <Badge className="mb-3 !px-3" colorScheme={statusColors(admin?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                            <Text className="font-normal" fontSize={10}>{admin?.status ? 'Active' : 'Inactive'}</Text>
                                        </Badge>
                                    </Td>
                                    <Td>{formatDate({date: admin?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Td>

                                    <Td isNumeric>
                                            
                                        <Button onClick={() => {
                                            onOpen()
                                            setAdmin(admin)
                                        }} size={'sm'} className="text-xs">More Details</Button>
                                        
                                    </Td>
                                </Tr>

                            ))
                        }
                    </>
                }
                footerContent={
                    <PaginateAsset
                        total={total}
                        prev_page_url={prev_page_url}
                        next_page_url={next_page_url}
                        current_page={current_page}
                        last_page={last_page}
                        per_page={per_page}
                        onNavigate={(page) =>  getAdmins({page})}
                    />
                }
            />

            <AdminDetails isOpen={isOpen} onClose={onClose} admin={admin} getAdmins={getAdmins} />
            <AdminInvite isOpen={isInviteOpen} onClose={onInviteClose} getAdmins={getAdmins} />

        </>

    )

}

export default AdminList