import { useState, useEffect} from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PromptAsset, PaginateAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill, RiExpandUpDownLine, RiCloseFill} from "@remixicon/react"
import { Link } from "react-router-dom"

const MediaList = ({getMedia, deleteMedia, media, defaultGetMedia}) => {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    const [selected, setSelected] = useState({})
    const { per_page, data, current_page, last_page, total, prev_page_url, next_page_url} = media || {}


    const filters = ['image/jpeg', 'image/jpg', 'image/svg', 'image/png']    

    const [filter, setFilter] = useState({})
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(data)

    useEffect(() => {
        getMedia(filter)
    }, [filter])

    useEffect(() => {
        if (data) {
            setSearchItems(data)
        }
    }, [data])

    const onHandleFilter = (name, value) => {
        setFilter((prevFilter) => {
            const updatedFilter = { ...prevFilter };
    
            if (value) {
                updatedFilter[name] = value;
            } else {
                delete updatedFilter[name];
            }
    
            return updatedFilter;
        });
    }

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            <HStack spacing={5}>
            
                <MenuAsset
                    triggerProps={{className: '!text-xs', size: 'sm'}}
                    trigger={Button}
                    triggerContent={<Text>Format</Text>}

                    menuContent={
                        <CheckboxGroup onChange={(value) => onHandleFilter('format', value)}>
                            {
                                filters.map((filter, index) => (
                                    <MenuItem key={index}>
                                        <Checkbox value={filter} size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                    </MenuItem>
                                ))
                            }
                        </CheckboxGroup>
                    }
                />

                <MenuAsset
                    triggerProps={{className: '!text-xs', size: 'sm'}}
                    trigger={Button}
                    triggerContent={<RiMoreFill size={20} />}

                    menuContent={
                        <VStack spacing={5} className="!px-4 !py-2 items-center">
                            <Box>
                                <Text className="text-center text-xs">From</Text>
                                <Input onChange={(e) => onHandleFilter('date_from', e?.target?.value)} value={filter?.date_from} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                            </Box>

                            <Box>
                                <Text className="text-center text-xs">To</Text>
                                <Input onChange={(e) => onHandleFilter('date_to', e?.target?.value)} value={filter?.to} variant={'flushed'} size='xs' placeholder='Select Date and Time' type='datetime-local' />
                            </Box>
                        </VStack>
                    }
                />

            </HStack>

        </HStack>
    )

    return (
        <Box>   
            
            {FilterView()}
    
            <Box className="mt-4 w-full grid grid-cols-2 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-4 sm:grid-cols-2 gap-4"> 
                {
                    results?.map((media, index) => (
                        <Box key={index} className="w-full h-[150px] mx-auto border p-2 relative">
                            <HStack className="absolute top-2 right-2">
                                <Link to={media?.link} target="_blank">
                                    <RiExpandUpDownLine size={18} className="text-primary-950 rotate-45 cursor-pointer hover:bg-gray-100" />
                                </Link>
                                <RiCloseFill onClick={() => {
                                    onOpenPrompt()
                                    setSelected(media)
                                }} size={18} className="text-red-500 cursor-pointer hover:bg-gray-100" />
                            </HStack>
                            <VStack className="h-full !justify-center !items-center">
                                <Link to={media?.link} target="_blank">
                                    <img src={media?.link} width={70} />
                                </Link>
                            </VStack>
                        </Box>
                    ))
                }
            </Box>

            <Box className="mt-10">
                <PaginateAsset
                    total={total}
                    prev_page_url={prev_page_url}
                    next_page_url={next_page_url}
                    current_page={current_page}
                    last_page={last_page}
                    per_page={per_page}
                    onNavigate={(page) =>  defaultGetMedia(page)}
                />
            </Box>

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    deleteMedia({id: selected?.id})
                    onClosePrompt()
                }}
            />

            
        </Box>
    )

}


export default MediaList