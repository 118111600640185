import { useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import CategoriesList from "@modules/product/components/Category/CategoryList"
import CategoryForm from "@modules/product/components/Category/CategoryForm"
import { useProductService } from "@services/index"

const Index = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const { getCategories, response:categories } = useProductService()

    useEffect(() => {
        getCategories()
    }, [])

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">Create Category</Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Categories | Organize and Manage Product Classifications'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Product Categories'}
                        description={`Explore and manage all product categories, ensuring efficient organization and seamless navigation for your inventory.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Categories'}
                                number={localNumber({number: categories?.categories?.length, decimals: 0})}
                            />
                        </Box>

                    </Box>

                    <Box>
                        {
                            categories &&
                            <CategoriesList defaultItems={categories?.categories} />
                        }
                    </Box>

                </Box>

               
           </Box>

           <CategoryForm isOpen={isOpen} onClose={onClose}  />
        
        </AuthenticatedLayout>
    )

}


export default Index