import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"

const List = () => {

    return (
        <AuthenticatedLayout title={'Product Listings | Comprehensive Inventory Management'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Products List'}
                        description={`Browse the full list of available products, including essential details like pricing and categories, with options for managing inventory effectively.`}
                    />
                </Box>


                <Box className="content">

                    <Box>
                        <ProductList title={''} description={''} />
                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default List