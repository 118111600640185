import { Route } from 'react-router-dom';
import GuestLayout from '@components/layout/Guest';

import Login from '@modules/authentication/pages/Login'
import CreatePassword from '@modules/authentication/pages/password/CreatePassword'
import Verification from '@modules/authentication/pages/Verification'
import NewPassword from '@modules/authentication/pages/password/NewPassword'
import ResetPassword from '@modules/authentication/pages/password/ResetPassword'
import Invite from '@modules/authentication/pages/Invite';

const AuthRoutes = [
    <Route path={'/auth'}> 
        <Route path="login" element={<Login />} />
        <Route path="invite" element={<Invite />} />
        <Route path="create-password" element={<CreatePassword />} />
        <Route path="verification" element={<Verification />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
    </Route>
]

export default AuthRoutes