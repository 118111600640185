import { useMemo } from "react"
import { useRequest } from "@handlers/index"
import { setStore, sessionExpires, appURL } from "@utils/helpers"
import Cookies from 'js-cookie'

const AnalyticsService = () => { 

    const {errors: requestErrors, response, load, request} = useRequest()

    //Analytics Stats
    const getStats = async (params) => {

        request({
            method: 'get',
            url: `${appURL}/api/analytics/stats`,
            params
        },
        [
            ['logged'],
            ['verified']
        ]
        ).then(() => {}).catch(() => {})

    } 

    //Search Analytics
    const search = async (params) => {

        request({
            method: 'get',
            url: `${appURL}/api/analytics/search`,
            params
        },
        [
            ['logged'],
            ['verified']
        ]
        ).then(() => {}).catch(() => {})

    }  
    
    
    const services = {
        requestErrors,
        response,
        load,
        getStats,
        search
    }

    return services

    // return useMemo(() => (services), []);

}

export default AnalyticsService