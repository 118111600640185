import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, Tab, TabPanels, TabPanel, Input} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, TabAsset, ButtonAsset, usePasswordToggle} from "@utils/assets"
import { FieldControl, clearInputErrors } from "@components/form"
import { Link } from "react-router-dom"
import AccountLayout from "@modules/account/components/AccountLayout"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useAccountService } from "@services/index"
import PageHeader from "@components/header/components/PageHeader"


const Security = () => {

    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            current_password: '', 
            new_password: '',
            new_password_confirmation: ''
        }
    );

    const { changePassword, requestErrors, load, response } = useAccountService()

    const {togglePass:togglePassCurrent, PasswordToggle:PasswordToggleCurrent} = usePasswordToggle()
    const {togglePass:togglePassNew, PasswordToggle:PasswordToggleNew} = usePasswordToggle()
    const {togglePass:togglePassConfirm, PasswordToggle:PasswordToggleConfirm} = usePasswordToggle()
    
    const {validate, validationErrors} = useValidator(rules.change_password)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        response && setData(
            { 
                current_password: '', 
                new_password: '', 
                new_password_confirmation: ''
            }
        )
    }, [response])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            changePassword(data)   
        }
    }

    return (
        <AccountLayout title={'Security Settings | Manage Your Account Security'}>

            <Box className="mb-5">
                <PageHeader
                    title={<Text className="text-sm">Security</Text>}
                    description={
                        <Text className="text-xs text-gray-500">Review and update your account security settings, including password and authentication options.</Text>
                    }
                />
            </Box>

            <Box className="mb-5 lg:w-3/6 w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">

                <FieldControl 
                    error={errors?.current_password?.[0]}
                    label={'Current Password'}
                    rightElementProps={{pr: 3}}
                    rightElement={
                        <PasswordToggleCurrent />
                    }
                >
                    <Input 
                        size={{base: 'lg', lg: 'md'}}
                        value={data.current_password} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}  
                        onChange={(event) => onHandleChange('current_password', event.target.value)} 
                        type={togglePassCurrent ? 'password': 'text'} 
                        fontSize={'xs'} 
                        placeholder="Enter current password"
                    />
                </FieldControl>

                <FieldControl 
                    error={errors?.new_password?.[0]}
                    label={'New Password'}
                    rightElementProps={{pr: 3}}
                    rightElement={
                        <PasswordToggleNew />
                    }
                >
                    <Input 
                        size={{base: 'lg', lg: 'md'}}
                        value={data.new_password} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}  
                        onChange={(event) => onHandleChange('new_password', event.target.value)} 
                        type={togglePassNew ? 'password': 'text'} 
                        fontSize={'xs'} 
                        placeholder="Enter new password"
                    />
                </FieldControl>

                <FieldControl 
                    error={errors?.new_password_confirmation?.[0]}
                    label={'Confirm New Password'}
                    rightElementProps={{pr: 3}}
                    rightElement={
                        <PasswordToggleConfirm />
                    }
                >
                    <Input 
                        size={{base: 'lg', lg: 'md'}}
                        value={data.new_password_confirmation} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}  
                        onChange={(event) => onHandleChange('new_password_confirmation', event.target.value)} 
                        type={togglePassConfirm ? 'password': 'text'} 
                        fontSize={'xs'} 
                        placeholder="Enter new password"
                    />
                </FieldControl>

            </Box>

            <HStack className="justify-end lg:w-3/6 w-full">
                <ButtonAsset onClick={submit} buttonProps={{isLoading: load, isDisabled, size: 'sm'}}>
                    Change Password
                </ButtonAsset>
            </HStack>

        </AccountLayout>
    )

}

export default Security