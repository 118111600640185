import { useToast } from '@chakra-ui/react'

const NotificationHandler = () => {

    const toast = useToast()
    const id = 'toast'

    const notify = ({status, title, msg, position}) => {
        if (!toast.isActive(id)) {
            toast({
                id,
                title,
                description: msg,
                status: status,
                position: position ?? 'top',
                isClosable: true,
            })   
        }
    }


    return {
        notify
    }

}

export default NotificationHandler