import { useMemo } from "react"
import { useRequest } from "@handlers/index"
import { setStore, sessionExpires, appURL } from "@utils/helpers"
import Cookies from 'js-cookie'

const SettingsService = () => { 

    const {errors: requestErrors, response, load, request} = useRequest()

    const shipping_endpoint = `${appURL}/api/shipping`;
    const settings_endpoint = `${appURL}/api/settings`;

    // Get All Shipping Settings
    const getShippings = async () => {
        request({
            method: 'get',
            url: `${shipping_endpoint}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Get Shipping Setting by ID
    const getShipping = async (data) => {
        request({
            method: 'get',
            url: `${shipping_endpoint}/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Create Shipping Setting
    const createShipping = async (data) => {
        request({
            method: 'post',
            url: `${shipping_endpoint}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Update Shipping Setting
    const updateShipping = async (data) => {
        request({
            method: 'put',
            url: `${shipping_endpoint}/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Delete Shipping Setting
    const deleteShipping = async (data) => {
        request({
            method: 'delete',
            url: `${shipping_endpoint}/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Update Shipping Price List
    const updateShippingPrices = async (data) => {
        request({
            method: 'put',
            url: `${shipping_endpoint}/shipping-prices/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };
    
    // Services Object with all methods
    const services = {
        requestErrors, 
        response, 
        load,
        getShippings,
        getShipping,
        createShipping,
        updateShipping,
        deleteShipping,
        updateShippingPrices
    };
    

    return services

    // return useMemo(() => (services), []);

}

export default SettingsService