import { useMemo } from "react"
import { useRequest } from "@handlers/index"
import { setStore, sessionExpires, appURL } from "@utils/helpers"
import { useAuthService } from "@services/index"
import Cookies from 'js-cookie'

const AccountService = () => { 

    const {errors: requestErrors, response, load, request} = useRequest()
    const {setUserData, onLogout, removeVerificationCookie, removeAuthCookie} = useAuthService()
    
    const endpoint = `${appURL}/api/account`;

    //Fetches Admin Info (Guest)
    const userDataGuest = async () => {
        request({
            method: 'get',
            url: `${endpoint}/data`,
        }).then((response) => {
            response?.data?.data && setUserData(response?.data?.data)
        }).catch(() => {})
    } 

    //Fetches Admin Info (Authenticated)
    const userDataAuth = async () => {
        request({
            method: 'get',
            url: `${endpoint}/dashboard`,
        }).then((response) => {
            response?.data?.data && setUserData(response?.data?.data)
        }).catch(() => {})
    } 

    //Verifies Verification Token
    const verifyToken = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/verify-token`,
            data
        }).then((response) => {
            if (response.data?.status === false) {
                removeVerificationCookie()
                removeAuthCookie()
            }
        }).catch(() => {})
    }

    // Update Account
    const updateAccount = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Update Account Password
    const changePassword = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/password`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get All Admins
    const getAdmins = async () => {
        request({
            method: 'get',
            url: `${endpoint}/admins`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Get Admin by ID
    const getAdminById = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/admin/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Admin
    const updateAdmin = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/admin/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Admin Password
    const changeAdminPassword = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/admin/${data?.id}/password`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Delete Admin
    const deleteAdmin = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/admin/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Invite Admin
    const inviteAdmin = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/admin/invite`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Resend Admin Invitation
    const resendInvitation = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/admin/${data?.id}/resend-invitation`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Toggle Admin
    const toggleAdmin = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/admin/${data?.id}/toggle`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Get Settings
    const getSettings = async () => {
        request({
            method: 'get',
            url: `${endpoint}/settings`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Company Settings
    const updateCompanySettings = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/settings/company`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Phone Settings
    const updatePhoneSettings = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/settings/phone`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Email Settings
    const updateEmailSettings = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/settings/email`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Address Settings
    const updateAddressSettings = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/settings/address`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };

    // Update Social Settings
    const updateSocialSettings = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/settings/social`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    };


    const services = {
        response,
        load,
        requestErrors,
        userDataGuest,
        userDataAuth,
        verifyToken,
        updateAccount,
        changePassword,
        getAdmins,
        getAdminById,
        updateAdmin,
        changeAdminPassword,
        deleteAdmin,
        inviteAdmin,
        resendInvitation,
        toggleAdmin,
        getSettings,
        updateCompanySettings,
        updatePhoneSettings,
        updateEmailSettings,
        updateAddressSettings,
        updateSocialSettings
    }

    return services

    // return useMemo(() => (services), []);

}

export default AccountService