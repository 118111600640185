import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/cms/pages/Index'
import Edit from '@modules/cms/pages/Edit'

const CMSRoutes = [
    <Route path={'/cms'}> 
        <Route index element={<Index />} />
        <Route path=":id" element={<Edit />} />
    </Route>
]

export default CMSRoutes