import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, 
    VStack, Td, Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors} from "@utils/helpers"
import { RiMoreLine, RiMoreFill} from "@remixicon/react"
import { Link } from "react-router-dom"
import countries from "@utils/countries.json"

const ShippingPricingList = ({shipping, onPricingOpen, setCountry}) => {

    
    const [filter, setFilter] = useState([])
    const filters = Object?.keys(shipping?.pricing ?? [])

    const prices = Object.keys(shipping?.pricing || {})
    .filter(country => !filter.length || filter.includes(country))
    .flatMap(country =>
        shipping?.pricing[country]?.map(price => ({
            ...price,
            country
        }))
    );

    const { results, SearchView, setItems } = SearchListAsset(prices)

    useEffect(() => {
        
        if (shipping) {            
            setItems(prices)
        }

    }, [shipping, filter])

    const FilterView = () => (
        <HStack className="justify-between items-center w-full py-2" spacing={5}>

            <HStack spacing={5}>
            
                <MenuAsset
                    triggerProps={{className: '!text-xs', size: 'sm'}}
                    trigger={Button}
                    triggerContent={<Text>Country</Text>}

                    menuContent={
                        <CheckboxGroup onChange={(value) => setFilter(value)} value={filter?.status}>
                            {
                                filters.map((filter, index) => (
                                    <MenuItem key={index}>
                                        <Checkbox value={filter} size={'lg'}><Text className="text-xs font-medium capitalize">{filter}</Text></Checkbox>
                                    </MenuItem>
                                ))
                            }
                        </CheckboxGroup>
                    }
                />

            </HStack>

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for pricing lists'})}
                
            </Box>

        </HStack>
    )

    const TableView = () => (
        <TableAsset
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">ID</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Country</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">State</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Price</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                   {
                        results?.map((price, index) => (

                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index+1}</Td>
                                <Td>{price?.country}</Td>
                                <Td>{price?.state}</Td>
                                <Td>₦{localNumber({number: price?.price })}</Td>
                                <Td isNumeric>
                                    <Button size={'sm'} className="!text-xs" onClick={() => {
                                        setCountry(price?.country)
                                        onPricingOpen()
                                    }}>Edit</Button>
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
        />
    )

    return (
        <Box>   
            
            {FilterView()}

            {TableView()}
            
        </Box>
    )

}


export default ShippingPricingList