import { useState, useEffect} from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select,
    Switch
} from "@chakra-ui/react"
import {RiCloseFill, RiAddFill} from "@remixicon/react"
import { useNavigate } from "react-router-dom"
import {ModalAsset, SelectAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import UploadMedia from "@modules/media/components/UploadMedia"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useProductService } from "@services/index"

const AttributeForm = ({isOpen, onClose, attribute, getAttributes}) => {

    const navigate = useNavigate()

    const [errors, setError] = useState({})
    const [data, setData] = useState(
        { 
            name: '', 
            options: ''
        }
    );

    const { createAttribute, response:updated_attribute, requestErrors, load } = useProductService()
    const {validate, validationErrors} = useValidator(rules.create_attribute)

    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        if (updated_attribute?.attribute) {
            onClose()
            setData({ 
                name: '', 
                options: ''
            })
            if (getAttributes) getAttributes()
        }
    }, [updated_attribute])

    useEffect(() => {
        if (attribute) {
            setData({
                ...data,
                name: attribute?.name,
                options: attribute?.options?.join(', ')
            })
        }
    }, [attribute])

    const onHandleChange = (name, value) => {
        setData((prevData) => ({ ...prevData, [name]: value }));
        validate(name, value)
    };

    const submit = () => {
        if (!isDisabled) {
            createAttribute({...data, options: data?.options?.split(/,\s*/), id: attribute?.id})
        }
    }

    const isDisabled = load || Object.keys(data).some(field => 
        Array.isArray(data[field]) ? data[field].length === 0 : !data[field]
    ) || Object.keys(errors).length > 0;

    return (
        
        <Box>

            <ModalAsset
                arrowless
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                <Box>
                    <Text className="font-semibold">{attribute ? `Edit ${attribute?.name}` : 'Create Attribute'}</Text>
                    <Text className="text-xs font-normal text-gray-400">
                        {attribute ? 'Modify the details of this attribute to keep your classifications up-to-date.' : 'Add a new attribute to expand your product classifications and improve catalog organization.'}
                    </Text>
                </Box>
                }
                bodyContent={
                    <VStack spacing={7} className="!items-start !justify-center">
                                                
                        <FieldControl error={errors?.name?.[0]} label={'Attribute name'}>
                            <Input 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                placeholder="Enter attribute name" 
                                value={data.name}  
                                onChange={(event) => onHandleChange('name', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.options?.[0]} label={'Attribute Options (separate with commas)'}>
                            <Textarea 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                placeholder="Enter attribute options (separate with commas)" 
                                value={data?.options}  
                                onChange={(event) => onHandleChange('options', event.target.value)} 
                            />
                        </FieldControl>

                    </VStack>
                }
                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={onClose} className="border">Cancel</Button>
                        <Button size={'sm'} onClick={submit} isDisabled={isDisabled} isLoading={load} className="!bg-primary-950 !text-white">Save Changes</Button>
                    </ButtonGroup>
                }

            />

        </Box>

    )

}


export default AttributeForm