import { useMemo } from "react"
import { appURL } from "@utils/helpers"
import { useRequest } from "@handlers/index"


const ProductService = () => {

    const {errors: requestErrors, response, load, request} = useRequest()

    const endpoint = `${appURL}/api/product`

    // Get All Products
    const getProducts = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}`,
            params: data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get Product by ID
    const getProduct = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/product/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Create Product
    const createProduct = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Update Product
    const updateProduct = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Product
    const deleteProduct = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get All Categories
    const getCategories = async () => {
        request({
            method: 'get',
            url: `${endpoint}/categories`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get Category by ID
    const getCategory = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/category/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Create Category
    const createCategory = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/category`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Category
    const deleteCategory = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/category/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }
    

    // Get All SubCategories
    const getSubCategories = async () => {
        request({
            method: 'get',
            url: `${endpoint}/subcategories`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get SubCategory by ID
    const getSubCategory = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/subcategory/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Create SubCategory
    const createSubCategory = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/subcategory`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete SubCategory
    const deleteSubCategory = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/subcategory/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get All Attributes
    const getAttributes = async () => {
        request({
            method: 'get',
            url: `${endpoint}/attributes`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get Attribute by ID
    const getAttribute = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/attribute/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Create Attribute
    const createAttribute = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/attribute`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Attribute
    const deleteAttribute = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/attribute/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get All Reviews
    const getReviews = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/reviews`,
            params: data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get Review by ID
    const getReview  = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/review/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Update Review
    const updateReview = async (data) => {
        request({
            method: 'put',
            url: `${endpoint}/review/${data?.id}`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Review
    const deleteReview = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/review/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }
        
    // Get Coupons
    const getCoupons = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/coupons`,
            params: data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Get Coupon by ID
    const getCoupon = async (data) => {
        request({
            method: 'get',
            url: `${endpoint}/coupon/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Create Coupon
    const createCoupon = async (data) => {
        request({
            method: 'post',
            url: `${endpoint}/coupon`,
            data
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    // Delete Coupon
    const deleteCoupon = async (data) => {
        request({
            method: 'delete',
            url: `${endpoint}/coupon/${data?.id}`,
        },
        [
            ['logged'],
            ['verified']
        ])
        .then(() => {})
        .catch(() => {});
    }

    const services = {
        
        requestErrors,
        response,
        load,

        // Product Methods
        getProducts,
        getProduct,
        createProduct,
        updateProduct,
        deleteProduct,
    
        // Category Methods
        getCategories,
        getCategory,
        createCategory,
        deleteCategory,
    
        // SubCategory Methods
        getSubCategories,
        getSubCategory,
        createSubCategory,
        deleteSubCategory,
    
        // Attribute Methods
        getAttributes,
        getAttribute,
        createAttribute,
        deleteAttribute,

        // Review Routes
        getReviews,
        getReview,
        updateReview,
        deleteReview,

        // Review Routes
        getCoupons,
        getCoupon,
        createCoupon,
        deleteCoupon

    };
    
    
    return services

    // return useMemo(() => (services), []);

}

export default ProductService