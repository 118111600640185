import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack,
    Tab, TabPanel, TabPanels
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiUploadCloudFill, RiExpandUpDownLine, RiCloseFill,
    RiAddFill, RiSubtractFill, RiPaintBrushFill, RiPrinterFill, RiFilterFill, RiSparklingFill,
    RiArrowDownSLine
} from "@remixicon/react"
import { toOrdinal } from "@utils/helpers"
import {MenuAsset, LinkAsset, AccordionAsset, PopOverAsset, SelectAsset, DrawerAsset, TabAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link, useLocation} from "react-router-dom"
import { FieldControl } from "@components/form"
import AddressList from "@modules/users/components/Address/AddressList"
import countries from '@utils/countries.json'
import ProfileForm from "../components/ProfileForm"
import SecurityForm from "../components/SecurityForm"
import { useUserService } from "@services/index"

const Form = () => {

    const location = useLocation()
    const { pathname } = location
    const username = pathname?.split('/')[3]

    const { getUser, response:user } = useUserService()

    useEffect(() => {
        if (username) {
            getUser({username})
        }
    }, [username])

    const { isOpen: isMediaOpen, onOpen: onMediaOpen, onClose:onMediaClose } = useDisclosure()
    const { isOpen: isColourOpen, onOpen: onColourOpen, onClose:onColourClose } = useDisclosure()
    const { isOpen: isFinishingOpen, onOpen: onFinishingOpen, onClose:onFinishingClose } = useDisclosure()

    const PageHeaderAction = () => (
        <ButtonGroup>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={`${user?.user?.first_name ?? 'User'}'s Account | Manage Personal Information and Settings`}>

            <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={`${user?.user?.first_name}'s Account`}
                        description={`Access and update ${user?.user?.first_name}'s account details, preferences, and security settings.`}
                        action={PageHeaderAction}
                    />
                </Box>

                <Box className="content relative">

                    <TabAsset
                        listProps={{ className: '!border-b-0 !p-1'}}
                        listContent={
                            <>
                                <Tab _selected={{borderBottom: 0, bg: 'primary.950', color: 'white'}}>
                                    <Text fontSize={{base: 10, lg: 12}}>Profile</Text>
                                </Tab>
                                <Tab _selected={{borderBottom: 0, bg: 'primary.950', color: 'white'}}>
                                    <Text fontSize={{base: 10, lg: 12}}>Security</Text>
                                </Tab>
                                <Tab _selected={{borderBottom: 0, bg: 'primary.950', color: 'white'}}>
                                    <Text fontSize={{base: 10, lg: 12}}>Addresses</Text>
                                </Tab>
                            </>
                        }
                        panelContent={
                            <TabPanels>

                                <TabPanel className="!px-0">

                                    <ProfileForm user={user?.user} />

                                </TabPanel>

                                <TabPanel className="!px-0">

                                    <SecurityForm user={user?.user} />    
                                    
                                </TabPanel>

                                <TabPanel className="!px-0">
                                    
                                    <AddressList user={user?.user} getUser={getUser} />
                                    
                                </TabPanel>

                            </TabPanels>
                        }
                    />

                </Box>
                
            </Box>

        </AuthenticatedLayout>
    )

}


export default Form