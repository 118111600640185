import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import {connect} from 'react-redux'
import { localNumber } from "@utils/helpers"
import OrderList from "@modules/order/components/OrderList"
import { StatCard, MenuAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import { useAnalyticsService } from "@services/index"
import Statistics from "@components/header/components/StatDate"

const Dashboard = ({accountReducer}) => {

    const { user } = accountReducer || {}
    const { first_name, last_name } = user || {}

    const [stat, setStat] = useState({})

    const { successful_payments, registered_users, placed_orders } = stat || {}

    const PageHeaderAction = () => (
        <Statistics setStat={setStat} />
    )

    return (
        <AuthenticatedLayout title={'Dashboard'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Dashboard'}
                        description={`Hi ${first_name}, Welcome to your admin account!`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Successful Payments'}
                                number={`₦${localNumber({number: successful_payments?.amount})}`}
                                helper={`${localNumber({number: successful_payments?.trend?.percentage, decimals: 2})}% In ${successful_payments?.trend?.period}`}
                                arrow={successful_payments?.trend?.arrow}
                                link={'/payments'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Placed Orders'}
                                number={`${localNumber({number: placed_orders?.number, decimals: 0})}`}
                                helper={`${localNumber({number: placed_orders?.trend?.percentage, decimals: 2})}% In ${placed_orders?.trend?.period}`}
                                arrow={placed_orders?.trend?.arrow}
                                link={'/orders'}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'User Registerations'}
                                number={localNumber({number: registered_users?.number, decimals: 0})}
                                helper={`${localNumber({number: registered_users?.trend?.percentage, decimals: 2})}% In ${registered_users?.trend?.period}`}
                                arrow={registered_users?.trend?.arrow}
                                link={'/users'}
                            />
                        </Box>

                    </Box>

                    <Box>
                        <OrderList />
                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}


const mapStateToProps = (state) => {
    const { accountReducer } = state
    return { accountReducer }
};

export default connect(mapStateToProps)(Dashboard)