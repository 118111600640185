import { useState } from "react"
import { VStack, HStack, Text, Button, ButtonGroup, Box, Center, MenuItem, StackDivider, 
    Input, Textarea, IconButton, useDisclosure, MenuDivider
} from "@chakra-ui/react"
import { RiFilePdf2Fill, RiSendPlane2Fill} from "@remixicon/react"
import { DrawerAsset, MenuAsset, PromptAsset, LinkAsset, ModalAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import { localNumber, truncateText } from "@utils/helpers"
import { FieldControl } from "@components/form"
import { useEffect } from "react"

const OrderDetails = ({isOpen, onClose, payment, order, updateOrder, deleteOrder, load, delete_order_load}) => {

    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    const {isOpen:isOpenDecline, onOpen:onOpenDecline, onClose:onCloseDecline} = useDisclosure()
    const [prompt, setPrompt] = useState('');
    const [comment, setComment] = useState('Your order has been declined. Please contact support for further assistance if you have any questions.');

    useEffect(() => {
        if (order) {
            onClosePrompt()
        }
    }, [order])

    const onHandlePromptCall = () => {
        
        switch (prompt) {
            case 'delete':
                deleteOrder({id: order?.id})
                break;
            case 'confirmed':
                updateOrder({status: 'confirmed', id: order?.id})
                break;
            case 'declined':
                updateOrder({status: 'declined', id: order?.id, comment})
                break;
            case 'canceled':
                updateOrder({status: 'canceled', id: order?.id})
                break;
            case 'shipped':
                updateOrder({status: 'shipped', id: order?.id})
                break;
            case 'delivered':
                updateOrder({status: 'delivered', id: order?.id})
                break;
            default:
                break;
        }

    }

    const ManageOrderMenu = () => (
        <ButtonGroup>
            {
                order?.status === 'submitted' &&
                <Button isLoading={load} onClick={() => {
                    onOpenPrompt()
                    setPrompt('confirmed')
                }} size={'sm'} className="!bg-primary-950 !text-white !text-xs">Confirm Order</Button>
            }
            <MenuAsset
                triggerProps={{className: '!text-xs', size: 'sm'}}
                trigger={Button}
                triggerContent={<Text>Manage Order</Text>}
                listProps={{
                    className: '!min-w-[180px]'
                }}
                menuContent={
                    <>

                        {
                            (order?.status === 'confirmed' || order?.status === 'shipped') && 
                            <>

                                {
                                    order?.status != 'shipped' &&
                                    <MenuItem onClick={() => {
                                        onOpenPrompt()
                                        setPrompt('shipped')
                                    }} _hover={{bg: 'primary.950', color: 'white'}}>
                                        <Text className="text-xs font-medium capitalize">Ship Order</Text>
                                    </MenuItem>
                                }

                                {
                                    order?.status != 'delivered' &&
                                    <MenuItem onClick={() => {
                                        onOpenPrompt()
                                        setPrompt('delivered')
                                    }} _hover={{bg: 'primary.950', color: 'white'}}>
                                        <Text className="text-xs font-medium capitalize">Deliver Order</Text>
                                    </MenuItem>
                                }
                                

                            </>
                        }

                        {
                            order?.status != 'canceled' &&
                            <MenuItem onClick={() => {
                                onOpenPrompt()
                                setPrompt('canceled')
                            }} _hover={{bg: 'primary.950', color: 'white'}}>
                                <Text className="text-xs font-medium capitalize">Cancel Order</Text>
                            </MenuItem>
    
                        }

                        {
                            order?.status != 'declined' &&
                            <MenuItem onClick={onOpenDecline} _hover={{bg: 'primary.950', color: 'white'}}>
                                <Text className="text-xs font-medium capitalize">Decline Order</Text>
                            </MenuItem>
    
                        }

                        <MenuDivider />

                        <Center>
                            <Button onClick={() => {
                                onOpenPrompt()
                                setPrompt('delete')
                            }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                Delete
                            </Button>
                        </Center>
                    </>
                }
            />
        </ButtonGroup>
    )

    return (

        <>

            <DrawerAsset
                drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'sm'}}}
                bodyProps={{className: '!py-5'}}
                bodyContent={
                    <VStack divider={<StackDivider />} spacing={10} className="h-full w-full !text-left !items-start">

                        <VStack spacing={5} className="w-full !items-start">
                            <HStack className="!items-center">
                                <Box>
                                    <Text className="font-medium text-sm">
                                        {`Order Info for #${payment?.reference}`}
                                    </Text>
                                    <Text className="text-xs text-gray-500">
                                        Detailed overview of the order's information and status.
                                    </Text>
                                </Box>
                            </HStack>
                            <ButtonGroup>
                                <ManageOrderMenu />
                            </ButtonGroup>
                        </VStack>

                        <Box>
                            <Text className="mb-3 font-medium text-sm">Payment Info</Text>
                            
                            <Box className="w-full grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-y-5 gap-x-10">

                                <Box>
                                    <Text className="font-bold text-xs">Total Amount</Text>
                                    <Text className="text-xs">₦{localNumber({number: payment?.amount})}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Sub Total Amount</Text>
                                    <Text className="text-xs">₦{localNumber({number: payment?.metadata?.order?.subtotal_amount})}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Delivery Amount</Text>
                                    <Text className="text-xs">₦{localNumber({number: payment?.metadata?.order?.delivery_amount})}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Code</Text>
                                    <Text className="text-xs">{payment?.metadata?.code}</Text>
                                </Box>
                                <Box>
                                    <Text className="font-bold text-xs">Checkout</Text>
                                    <Link to={payment?.metadata?.url} target="_blank" rel="noopener noreferrer" className="text-xs capitalize" >Link</Link>
                                </Box>
                            
                                <Box>
                                    <Text className="font-bold text-xs">Platform</Text>
                                    <Text className="text-xs capitalize">{payment?.metadata?.gateway}</Text>
                                </Box>

                            </Box>

                        </Box>

                        <Box>
                            <Text className="mb-3 font-medium text-sm">Coupon Discount Info</Text>
                            
                            <Box className="w-full grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-y-5 gap-x-10">

                                <Box>
                                    <Text className="font-bold text-xs">Amount</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.discounted_amount ? `₦${localNumber({number: payment.metadata.order.discounted_amount})}` : 'None'}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Percentage</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.discounted_percentage ? `₦${localNumber({number: payment.metadata.order.discounted_percentage})}` : 'None'}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Coupon Code</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.coupon_code ?? 'None'}</Text>
                                </Box>

                            </Box>

                        </Box>

                        <Box>
                            <Text className="mb-3 font-medium text-sm">Shipping Info</Text>
                            
                            <Box className="w-full grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-y-5 gap-x-10">

                                <Box>
                                    <Text className="font-bold text-xs">Full Name</Text>
                                    <Text className="text-xs">{`${payment?.metadata?.order?.shipping_info?.first_name} ${payment?.metadata?.order?.shipping_info?.last_name}`}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Email</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.shipping_info?.email}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Phone</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.shipping_info?.phone}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">State/Province</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.shipping_info?.state}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">City</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.shipping_info?.city}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Address</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.shipping_info?.address}</Text>
                                </Box>

                                <Box>
                                    <Text className="font-bold text-xs">Country</Text>
                                    <Text className="text-xs">{payment?.metadata?.order?.shipping_info?.country}</Text>
                                </Box>
                            
                            </Box>

                        </Box>

                        
                    </VStack>
                }
            />
        
            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                load={load}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    onHandlePromptCall(prompt)
                }}
            />

                    
        <ModalAsset

            modalProps={{isOpen: isOpenDecline, onClose: onCloseDecline, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
            headerContent={
            <Box>
                <Text className="font-semibold">Decline Comment</Text>
                <Text className="text-xs font-normal text-gray-400">Fill in all required info in the form fields below.</Text>
            </Box>
            }
            bodyContent={
                <Box>
                    <Box className="mt-5 mb-10">
                        <FieldControl label={'Decline comment'}>
                            <Textarea 
                                size={'sm'} 
                                fontSize={'xs'} 
                                value={comment}
                                onChange={(e) => setComment(e?.target?.value)}
                                placeholder="Comment on the decline of this order" 
                            />
                        </FieldControl>
                    </Box>

                </Box>
            }
            footerContent={
                <ButtonGroup>
                    <Button size={'sm'} onClick={onCloseDecline} className="border">Cancel</Button>
                    <Button isDisabled={comment === ''} size={'sm'} onClick={() => {
                        setPrompt('declined')
                        onOpenPrompt()
                        onCloseDecline()
                    }} className="!bg-primary-950 !text-white">Continue</Button>
                </ButtonGroup>
            }

        />

        </>

    )

}


export default OrderDetails