import { useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import UsersList from "@modules/users/components/UsersList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import Statistics from "@components/header/components/StatDate"

const Index = () => {

    const [stat, setStat] = useState({})

    const { registered_users, active_users } = stat || {}

    const PageHeaderAction = () => (
        <Statistics setStat={setStat} />
    )

    return (
        <AuthenticatedLayout title={'Users | Manage and Engage with Platform Users'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Users'}
                        description={`Oversee and manage platform users, including their profiles and account activities.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Registered Users'}
                                number={localNumber({number: registered_users?.number, decimals: 0})}
                                helper={`${localNumber({number: registered_users?.trend?.percentage, decimals: 2})}% In ${registered_users?.trend?.period}`}
                                arrow={registered_users?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Active Users'}
                                number={localNumber({number: active_users?.number, decimals: 0})}
                                helper={`${localNumber({number: active_users?.trend?.percentage, decimals: 2})}% In ${active_users?.trend?.period}`}
                                arrow={active_users?.trend?.arrow}
                            />
                        </Box>

                    </Box>

                    <Box>

                        <UsersList />

                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Index