import {useState, useEffect, useRef, useCallback} from 'react'
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
    Menu, MenuButton, MenuList, MenuItem, useColorMode, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, AccordionIcon, Input, IconButton, Heading,
    Popover, PopoverTrigger, Portal, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton,
    PopoverBody, PopoverFooter, StackDivider, useDisclosure, Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, 
    Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Tabs, TabList, TabPanels, Tab, TabPanel,
    useBreakpointValue, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
    AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
    AlertDialogCloseButton, ButtonGroup, Skeleton, SkeletonCircle, SkeletonText, Spinner, Center,
    Stat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup,  List, ListItem, ListIcon, Checkbox,
    CheckboxGroup, Tooltip
} from '@chakra-ui/react'

import Cookies from 'js-cookie'
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import { RiArrowDownSLine, RiArrowRightSLine, RiSearch2Line, RiHome4Line, RiEyeOffLine, 
    RiEyeLine, RiCloseLine, RiArrowRightUpLine, RiArrowRightLine, RiArrowLeftSLine
} from '@remixicon/react'
import { RiCloseFill } from '@remixicon/react'
import {FieldControl} from '@components/form'
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { primary } from './theme'
import { appURL, formatDate, localNumber, removeEncodedSpace, truncateText } from './helpers'
import debounce from 'lodash.debounce';
import { useAnalyticsService } from '@services/index'
import { useNotification, useError } from '@handlers/index';


export const LinkAsset = ({variant, type, href, onClick, linkProps, children, isLoading}) => {
    
    const {className, ...props} = linkProps || {}

    return (
        <>
        
            {
                variant === 'outline' ?
                    <Link to={href} className={`text-xs p-2 font-medium border border-primary-950 text-primary-950 hover:bg-primary-950 hover:text-white ${className}`} {...props}>
                        {children}
                    </Link>
                :
                    <Link to={href} className={`text-xs p-2 font-medium bg-gray-100 text-primary-950 hover:bg-primary-950 hover:text-white ${className}`} {...props}>
                        {children}
                    </Link> 
            }

        </>
    )

}

export const ButtonAsset = ({variant, type, href, onClick, buttonProps, children, isLoading}) => {

    const {className, ...props} = buttonProps || {}

    return (
        <>
            {
                variant === 'outline' && type === 'button' ?
                    <Button onClick={onClick} isLoading={isLoading} borderColor={"primary.500"} color="primary.500" fontWeight={'medium'} fontSize={'xs'} px={7} py={3} variant="outline" {...buttonProps}>
                        {children}
                    </Button>
                :
                    <Button onClick={onClick} isLoading={isLoading} color={'white'} bg={'primary.500'} fontWeight={'medium'} fontSize={'xs'} px={7} py={3} {...buttonProps}>
                        {children}
                    </Button>
            }
        </>
    )
}

export const MenuAsset = ({trigger, triggerContent, menuContent, triggerProps, menuProps, listProps, arrowless}) => {
    return (
        <Menu
            {...menuProps}
        >
          <MenuButton as={trigger} 
              {...triggerProps}
              cursor={'pointer'}
              rightIcon={!arrowless && <RiArrowDownSLine size={16} />}
            >
                {triggerContent}
          </MenuButton>

            <MenuList
                {...listProps}
            >
                {menuContent}
            </MenuList>

        </Menu>
    )
}
 
export const PopOverAsset = ({
    trigger,
    triggerContent, triggerProps,
    headerContent, headerProps,
    bodyContent, bodyProps,
    footerContent, footerProps,
    portalProps, contentProps, popOverProps,
    isClose, arrowless
}) => {
    
    return (
        
        <Popover {...popOverProps}>
            {
                triggerContent &&
                <PopoverTrigger {...triggerProps}>
                    {triggerContent}
                </PopoverTrigger>
            }
            <Portal {...portalProps}>
                <PopoverContent {...contentProps}>
                    {!arrowless && <PopoverArrow />}
                    {headerContent && <PopoverHeader {...headerProps}>{headerContent}</PopoverHeader>}
                    {isClose && <PopoverCloseButton />}
                    <PopoverBody {...bodyProps}>
                        {bodyContent}
                    </PopoverBody>
                    {footerContent && <PopoverFooter {...footerProps}>{footerContent}</PopoverFooter>}
                </PopoverContent>
            </Portal>
        </Popover>

    )

}

export const AccordionAsset = ({accordionItems, accordionProps, itemProps, titleProps, panelprops}) => {

    return (    

        <Accordion {...accordionProps}>
            {
                accordionItems?.map((item, index) => (
                    <Box key={index}>
                        {
                            item && 
                            <AccordionItem {...itemProps}>
                                    <AccordionButton {...titleProps}>
                                        {item?.title}
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel {...panelprops} pb={4}>
                                    {item?.description}
                                </AccordionPanel>
                            </AccordionItem>
                        }
                    </Box>
                ))
            }
    </Accordion>

    )

}

export const SearchAsset = ({ mini, sidebar, header}) => {
    const navigate = useNavigate()
    const { isOpen, onClose, onOpen} = useDisclosure();
    const [query, setQuery] = useState('');
    const { search: searchQuery, response: results, load } = useAnalyticsService();

    const {products, categories, sub_categories, orders, payments, users} = results?.results || {}

    const search = useCallback(() => {
        if (query) searchQuery({ term: query });
    }, [query]);

    const debouncedSearch = useCallback(debounce(search, 1000), [search]);

    const handleInputChange = (value) => {
        setQuery(value);
        !isOpen && onOpen();
    };

    useEffect(() => {
        debouncedSearch();
        // Clean up the debounce on unmount
        return () => {
            debouncedSearch.cancel();
        };
    }, [query, debouncedSearch]);

    const Result = () => (
        <Box className='w-full h-full'>
            {load ? (
                <VStack className='w-full h-full justify-center items-center'>
                    <Spinner size="lg" borderWidth={3} color="primary.500" />
                </VStack>
            ) : Object.keys(results?.results ?? {})?.length ? (
                <VStack spacing={4} divider={<StackDivider />}>

                    {
                        products?.length && 
                        <Box className='w-full'>
                            <Text className='text-left mb-3 text-xs text-gray-500 font-semibold'>Products</Text>
                            <VStack divider={<StackDivider />}>
                                {
                                    products?.map((product, index) => (
                                        <HStack
                                            key={index}
                                            className="w-full py-2 px-2 hover:bg-gray-50 cursor-pointer justify-between"
                                            onClick={() => {
                                                navigate(`/products/${product?.name}`)
                                                onClose()
                                            }}
                                        >
                                            <VStack spacing={1} alignItems="flex-start">
                                                <Heading as="h6" fontSize={12}>{product?.name}</Heading>
                                                <Text fontWeight="regular" fontSize={12}>{truncateText({ text: product?.description, length: 50 })}</Text>
                                            </VStack>

                                            {
                                                product?.images?.[0] ? 
                                                    <Box>
                                                        <img src={product?.images?.[0]} width={25} alt={product?.name} />
                                                    </Box>
                                                :
                                                <></>
                                            }
                                        </HStack>
                                    ))
                                }
                            </VStack>
                        </Box>
                    }

                    {
                        categories?.length &&
                        <Box className='w-full'>
                            <Text className='text-left mb-3 text-xs text-gray-500 font-semibold'>Categories</Text>
                            <VStack divider={<StackDivider />}>
                                {
                                    categories?.map((category, index) => (
                                        <HStack
                                            key={index}
                                            className="w-full py-2 px-2 hover:bg-gray-50 cursor-pointer justify-between"
                                            onClick={() => {
                                                navigate(`/products/categories/${category?.name}`)
                                                onClose()
                                            }}
                                        >
                                            <VStack spacing={1} alignItems="flex-start">
                                                <Heading as="h6" fontSize={12}>{category?.name}</Heading>
                                                <Text fontWeight="regular" fontSize={12}>{truncateText({ text: category?.description, length: 50 })}</Text>
                                            </VStack>

                                            <Box>
                                                <img src={category?.image} width={25} alt={category?.name} />
                                            </Box>
                                            
                                        </HStack>
                                    ))
                                }
                            </VStack>
                        </Box>
                    }

                    {
                        sub_categories?.length && 
                        <Box className='w-full'>
                            <Text className='text-left mb-3 text-xs text-gray-500 font-semibold'>Subcategories</Text>
                            <VStack divider={<StackDivider />}>
                                {
                                    sub_categories?.map((sub_category, index) => (
                                        <HStack
                                            key={index}
                                            className="w-full py-2 px-2 hover:bg-gray-50 cursor-pointer justify-between"
                                            onClick={() => {
                                                navigate(`/products/subcategories/${sub_category?.name}`)
                                                onClose()
                                            }}
                                        >
                                            <VStack spacing={1} alignItems="flex-start">
                                                <Heading as="h6" fontSize={12}>{sub_category?.name}</Heading>
                                                <Text fontWeight="regular" fontSize={12}>{truncateText({ text: sub_category?.description, length: 50 })}</Text>
                                            </VStack>

                                            <Box>
                                                <img src={sub_category?.image} width={25} alt={sub_category?.name} />
                                            </Box>
                                        </HStack>
                                    ))
                                }
                            </VStack>
                        </Box>
                    }

                    {
                        orders?.length &&
                        <Box className='w-full'>
                            <Text className='text-left mb-3 text-xs text-gray-500 font-semibold'>Orders</Text>
                            <VStack divider={<StackDivider />}>
                                {
                                    orders?.map((order, index) => (
                                        <HStack
                                            key={index}
                                            className="w-full py-2 px-2 hover:bg-gray-50 cursor-pointer justify-between"
                                            onClick={() => {
                                                navigate(`/orders/${order?.reference}`)
                                                onClose()
                                            }}
                                        >
                                            <VStack spacing={1} alignItems="flex-start">
                                                <Heading as="h6" fontSize={12}>Order #{order?.reference}</Heading>
                                                <Text fontWeight="regular" fontSize={12}>{`₦${localNumber({number: order?.amount})}`} order on {formatDate({date: order?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Text>
                                            </VStack>
                                            
                                        </HStack>
                                    ))
                                }
                            </VStack>
                        </Box>

                    }

                    {
                        payments?.length &&
                        <Box className='w-full'>
                            <Text className='text-left mb-3 text-xs text-gray-500 font-semibold'>Payments</Text>
                            <VStack divider={<StackDivider />}>
                                {
                                    payments?.map((payment, index) => (
                                        <HStack
                                            key={index}
                                            className="w-full py-2 px-2 hover:bg-gray-50 cursor-pointer justify-between"
                                            onClick={() => {
                                                navigate('/payments')
                                                onClose()
                                            }}
                                        >
                                            <VStack spacing={1} alignItems="flex-start">
                                                <Heading as="h6" fontSize={12}>Payment #{payment?.reference}</Heading>
                                                <Text fontWeight="regular" fontSize={12}>{`₦${localNumber({number: payment?.amount})}`} payment on {formatDate({date: payment?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Text>
                                            </VStack>
                                        </HStack>
                                    ))
                                }
                            </VStack>
                        </Box>

                    }

                    {
                        users?.length && 
                        <Box className='w-full'>
                            <Text className='text-left mb-3 text-xs text-gray-500 font-semibold'>Users</Text>
                            <VStack divider={<StackDivider />}>
                                {
                                    users?.map((user, index) => (
                                        <HStack
                                            key={index}
                                            className="w-full py-2 px-2 hover:bg-gray-50 cursor-pointer justify-between"
                                            onClick={() => {
                                                navigate(`/users/${user?.username}`)
                                                onClose()
                                            }}
                                        >
                                            <VStack spacing={1} alignItems="flex-start">
                                                <Heading as="h6" fontSize={12}>{`${user?.first_name} ${user?.last_name} (${user?.username})`}</Heading>
                                                <Text fontWeight="regular" fontSize={12}>User registered on {formatDate({date: user?.created_at, format: 'MMM Do YYYY, h:mm A'})}</Text>
                                            </VStack>
                                        </HStack>
                                    ))
                                }
                            </VStack>
                        </Box>

                    }

                </VStack>
            ) : 
                <VStack w="100%" h="100%" justifyContent="center" alignContent="center">
                    <EmptyAsset
                        containerProps={{className: 'text-center w-4/6 mx-auto'}}
                        titleContent={<Text className="text-primary-950 text-sm font-bold">There are no results for “{truncateText({text: query, length: 10})}’</Text>}
                    />
                </VStack>
            }
        </Box>
    );

    return (
        <>

            {
                sidebar ? 
                    <Tooltip placement='right-end' label={'Search'} hasArrow bg='white' color='black'>
                        <HStack onClick={onOpen} className="justify-center items-center cursor-pointer">
                            <RiSearch2Line size={20} className='text-primary-100' />
                        </HStack>
                    </Tooltip>
                :
                header ?
                    <HStack onClick={onOpen} className="justify-center items-center cursor-pointer">
                        <RiSearch2Line size={20} className='text-gray-500' />
                    </HStack>
                :
                    <Box onClick={onOpen} className='w-auto'>
                        <FieldControl
                            formProps={{className: `${!mini ? '!text-primary-950' : '!text-primary-50'} !transparent`}}
                            rightElement={
                                <IconButton
                                    bg={mini ? 'transparent' : 'primary.950'}
                                    _hover={mini ? 'transparent' : 'primary.950'}
                                    _active={mini ? 'transparent' : 'primary.950'}
                                    aria-label="Search"
                                    roundedStart={0}
                                    roundedRight={5}
                                    icon={isOpen ? <RiCloseLine className='!text-primary-50' size={20} /> : <RiSearch2Line size={20} className='!text-primary-50' />}
                                    size={'md'}
                                />
                            }
                        >
                            <Input
                                size={'md'}
                                fontSize="sm"
                                fontWeight={'normal'}
                                bg="transparent"
                                type="text"
                                placeholder="Search for anything"
                                value={query}
                                onChange={(e) => handleInputChange(e.target.value)}
                            />
                        </FieldControl>
                    </Box>
            }

            <ModalAsset
                modalProps={{isOpen, onClose: () => {
                    onClose()
                    setQuery('')
                }, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                bodyProps={{className: 'min-h-[500px] max-h-[500px] !px-5'}}
                headerContent={
                    <VStack className='!items-start !justify-start w-full'>
                        <Box className='w-full'>
                            <Text className="font-semibold">Search For Anything</Text>
                            <Text className="text-xs font-normal text-gray-400">
                                Quickly find the items you need by typing keywords, names or descriptions.
                            </Text>
                        </Box>
                        <Box className='w-full'>
                            <FieldControl
                                formProps={{className: 'text-primary-950 !transparent'}}
                                rightElement={
                                    <IconButton
                                        bg={'transparent'}
                                        _hover={'transparent'}
                                        _active={'transparent'}
                                        aria-label="Search"
                                        roundedStart={0}
                                        roundedRight={5}
                                        icon={isOpen ? <RiCloseLine className='!text-primary-50' size={20} /> : <RiSearch2Line size={20} className='!text-primary-50' />}
                                        onClick={() => {
                                            setQuery('')
                                        }}
                                        size={'md'}
                                    />
                                }
                            >
                                <Input
                                    size={'md'}
                                    fontSize="xs"
                                    bg="transparent"
                                    type="text"
                                    placeholder="Search for anything"
                                    value={query}
                                    onChange={(e) => handleInputChange(e.target.value)}
                                />
                            </FieldControl>
                        </Box>
                    </VStack>
                    }
                bodyContent={
                    <VStack className='w-full'>
                        <Result />
                    </VStack>
                }
            />
        </>
    );
};

export const BreadcrumbAsset = () => {

    const ignore_urls = ['/', '/dashboard']

    const location = useLocation();
    const { pathname } = location;
    
    const pathSegments = pathname.split('#')[0] // Remove hash-related content
        .split('/')
        .filter(segment => segment.split('?')[0]);

    const createBreadcrumbs = () => {
        return pathSegments.map((segment, index) => {
            const href = '/' + pathSegments.slice(0, index + 1).join('/');
            const isLast = index === pathSegments.length - 1 || segment.toLowerCase() === 'edit'; // Handle '/edit' as non-clickable
            const segmentLabel = segment.split('?')[0];
            const segmentLabelFormatted = segmentLabel.charAt(0).toUpperCase() + segmentLabel.slice(1);
    
            return (
                <BreadcrumbItem key={href} isCurrentPage={isLast}>
                    {isLast ? (
                        <span className="capitalize font-normal text-gray-400">
                            {removeEncodedSpace(segmentLabelFormatted)}
                        </span>
                    ) : (
                        <Link to={href} className="capitalize text-primary-700">
                            {removeEncodedSpace(segmentLabelFormatted)}
                        </Link>
                    )}
                </BreadcrumbItem>
            );
        });
    };
        
  
    return (
        <>
            {
                !ignore_urls.find(url => url === pathname) && 

                <HStack w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box className='lg:w-7/12 w-full h-full flex lg:flex-col justify-between lg:items-start items-center'>
                        <Breadcrumb spacing='8px' className="text-xs font-medium lg:mb-2" separator={<RiArrowRightSLine size={18} />}>
                            <BreadcrumbItem>
                                <HStack className="text-primary-700 items-center justify-center">
                                    <RiHome4Line size={18} />
                                    <Link to='/'>Dashboard</Link>
                                </HStack>
                            </BreadcrumbItem>
                            {createBreadcrumbs()}
                        </Breadcrumb>
                    </Box>
                </HStack>
                
            }
        </>
    )
}

export const ModalAsset = ({arrowless, modalProps, contentProps, headerContent, headerProps, bodyContent, bodyProps, footerContent, footerProps}) => {

    return (
        <Modal scrollBehavior={'inside'} {...modalProps}>

            <ModalOverlay 
                bg={'none'}
                backdropFilter='auto'
                backdropBlur='2px'
            />

            <ModalContent {...contentProps}>

                {headerContent && <ModalHeader {...headerProps}>{headerContent}</ModalHeader>}

                {!arrowless && <ModalCloseButton />}

                <ModalBody {...bodyProps}>
                    {bodyContent}
                </ModalBody>

                {
                    footerContent &&
                    <ModalFooter {...footerProps}>{footerContent}</ModalFooter>
                }

            </ModalContent>
        
      </Modal>
    )

}

export const DialogueAsset = ({isCloseBTN, dialogueProps, contentProps, headerContent, headerProps, bodyContent, bodyProps, footerContent, footerProps}) => {
    
    const cancelRef = useRef()

    return (
      
        <AlertDialog
            {...dialogueProps}
            leastDestructiveRef={cancelRef}
        >

            <AlertDialogOverlay
                bg={'none'}
                backdropFilter='auto'
                backdropBlur='2px'
            >

                <AlertDialogContent {...contentProps}>

                    {
                        headerContent &&
                        <AlertDialogHeader {...headerProps}>
                           {headerContent}
                        </AlertDialogHeader>
                    }

                    <AlertDialogBody {...bodyProps}>
                       {bodyContent}
                    </AlertDialogBody>

                    {
                        footerContent &&
                        <AlertDialogFooter {...footerProps}>
                            {footerContent}
                        </AlertDialogFooter>
                    }

                </AlertDialogContent>

            </AlertDialogOverlay>

        </AlertDialog>

    )

}

export const PromptAsset = ({isOpen, onClose, title, body, onSubmit, load}) => {
    
    return (
        <DialogueAsset
            dialogueProps={{
                isOpen,
                onClose,
                size: 'xs'
            }}
            headerContent={
                <HStack justifyContent={'space-between'}>
                    <Text className="text-sm font-bold">{title}</Text>
                    <Text onClick={onClose} className="text-xs cursor-pointer"><RiCloseFill size={20} /></Text>
                </HStack>
            }
            bodyContent={
                <Text>{body}</Text>
            }
            footerContent={
                <ButtonGroup w={'100%'}>
                    <Button onClick={onClose} w={'50%'} variant={'outline'}>Cancel</Button>
                    <Button isLoading={load} onClick={onSubmit} w={'50%'} bg={'red.400'} color={'white'}>Confirm</Button>
                </ButtonGroup>
            }
        />
    )

}

export const DrawerAsset = ({
    isCloseBTN, drawerProps, contentProps, headerContent, headerProps, bodyContent, bodyProps, footerContent, footerProps
}) => {

    return (
        <Drawer {...drawerProps}>

            <DrawerOverlay 
                bg={'none'}
                backdropFilter='auto'
                backdropBlur='2px'
            />
            
            <DrawerContent {...contentProps}>
                
                {!isCloseBTN && <DrawerCloseButton />}

                {headerContent && <DrawerHeader {...headerProps}>{headerContent}</DrawerHeader>}

                <DrawerBody {...bodyProps}>
                    {bodyContent}
                </DrawerBody>

                {
                    footerContent &&
                    <DrawerFooter {...footerProps}>
                       {footerContent}
                    </DrawerFooter>
                }

            </DrawerContent>
        </Drawer>
    )

}

export const TableAsset = ({
    containerProps, tableProps, caption, captionProps,
    headerContent, headerProps, bodyContent, bodyProps,
    footerContent, footerProps
}) => {


    return (

        <TableContainer {...containerProps}>
            <Table {...tableProps} variant='simple'>
                {caption && <TableCaption {...captionProps} >{caption}</TableCaption>}
                <Thead {...headerProps}>
                    {headerContent}
                </Thead>
                <Tbody {...bodyProps}>
                    {bodyContent}
                </Tbody>
                {
                    footerContent &&
                    <Tfoot {...footerProps}>
                        {footerContent}
                    </Tfoot>
                }
            </Table>
        </TableContainer>

    )

}

export const TabAsset = ({tabProps, listProps, listContent, panelProps, panelContent}) => {

    return (

        <Tabs {...tabProps}>

            <TabList {...listProps}>
                {listContent}
            </TabList>

            <TabPanels {...panelProps}>
                {panelContent}
            </TabPanels>

        </Tabs>

    )

}

export const EmptyAsset = ({containerProps, iconContent, iconProps, titleContent, titleProps, descContent, descProps}) => {
    
    return (
        <VStack {...containerProps}>

            <Box {...iconProps} mb={3}>
                {iconContent}
            </Box>

            <Box {...titleProps}>
                {titleContent}
            </Box>

            <Box {...descProps}>
                {descContent}
            </Box>
    
        </VStack>
    )

}

export const SkeletonAsset = ({skContainer, skProps, skCircleProps, skTextProps, skeletons, count}) => {    
    return (
        <>
            {
                Array(count ?? 1).fill(0).map((_, index) => (
                    <Box key={index} {...skContainer}>
                        {
                            (skeletons?.box || !skeletons) && 
                            <Skeleton {...skProps} />
                        }
                        {
                            skeletons?.circle &&
                            <SkeletonCircle {...skCircleProps} />
                        }
                        {
                            skeletons?.text &&
                            <SkeletonText {...skTextProps} />
                        }
                    </Box>
                ))
            }
        </>
    )
}

export const usePasswordToggle = () => {

    //Initialises Password Toggle State
    const [togglePass, setTogglePass] = useState(true);

    //Passowrd 
    const PasswordToggle = () => {
        return (
            <Box onClick={() => {
                setTogglePass(!togglePass)
            }} cursor={'pointer'}>
                {
                    togglePass ? 
                    <RiEyeOffLine className="text-gray-400 cursor-pointer" size={20} />
                    :
                    <RiEyeLine className="text-gray-400 cursor-pointer" size={20} />
                }
            </Box>
        )
    }


    return {
        togglePass,
        setTogglePass,
        PasswordToggle
    }

}

export const UploadAsset = ({files, setFiles, data, maxFiles, onUpload, uploadProps}) => {
    
    const { notify } = useNotification()
    const { handleError } = useError()
    const [token, setToken] = useState('')

    useEffect(() => {
        initUpload()
    },[])

    registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType)
    
    const initUpload = async () => {
        const xsrf_token = await Cookies.get('XSRF-TOKEN')
        setToken(xsrf_token)
    }
    
    return (
        <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowRevert={true}
            allowImagePreview={true}
            allowMultiple={true}
            maxFiles={maxFiles ?? 1}
            acceptedFileTypes={['image/png', 'image/jpeg']}
            {...uploadProps}
            name={data?.name}
            server={{
                url: data?.url,
                headers: {'X-Requested-With': 'XMLHttpRequest', 'X-XSRF-TOKEN': token},
                process: {
                    withCredentials: true,
                    ondata: (formData) => {
                        Object.entries(data?.data || {}).forEach(([key, value]) => formData.append(key, value));
                        return formData;
                    },
                    onerror: (error) => {
                        try {
                            error = JSON?.parse(error)
                            handleError(error)
                        } catch (error) {}
                    },
                    onload: (response) => {
                        try {
                            const responseMsg = JSON?.parse(response)
                            responseMsg && notify({...responseMsg?.alert, msg: responseMsg?.alert.message})
                            onUpload(responseMsg)
                        } catch (error) {}
                    }
                }
            }}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            onerror={(error) => {
                error && notify({status: 'error', title: 'Error', msg: error?.body})
            }}
        />
    )
    
}

export const SearchListAsset = (items) => {

    const [results, setResult] = useState(items)
    const [query, setQuery] = useState('')

    const searchQuery = ({ query, subjects }) => {

        setQuery(query)

        if (query) {

            const lowerCaseQuery = query.replace(/\s+/g, '').toLowerCase();

            const matches = items?.filter(item => {
                if (typeof item === 'string') {
                    return item.replace(/\s+/g, '').toLowerCase().includes(lowerCaseQuery);
                } else if (typeof item === 'object' && item !== null) {
                    return subjects 
                        ? subjects.some(subject => 
                            typeof item[subject] === 'string' &&
                            item[subject].replace(/\s+/g, '').toLowerCase().includes(lowerCaseQuery)
                        )
                        : Object.values(item).some(value => 
                            typeof value === 'string' &&
                            value.replace(/\s+/g, '').toLowerCase().includes(lowerCaseQuery)
                        );
                }
                return false;
            });   

            return setResult(matches)
            
        } 
        
        setResult(items)
    }

    const setItems = (items) => {
        !query && setResult(items)
    }

    const SearchView = ({placeholder, subjects}) => (
        <FieldControl
            formProps={{ color: 'black', className: `mx-auto` }}
            rightElement={
                <IconButton
                    bg={'transparent'}
                    _hover={'transparent'}
                    _active={'transparent'}
                    aria-label="Search"
                    roundedStart={0}
                    roundedRight={5}
                    icon={query ? <RiCloseLine size={17} className='!text-primary-950' /> : <RiSearch2Line size={17} className='!text-primary-950' />}
                    size={'lg'}
                    onClick={() => {
                        searchQuery({query: ''})
                    }}
                />
            }
        >
            <Input
                className='!text-xs !bg-white'
                size={'sm'}
                type="text"
                placeholder={placeholder ?? "Search for anything"}
                value={query}
                onChange={(e) => searchQuery({query: e.target.value, subjects})}
            />
        </FieldControl>
    )

    return {
        results,
        SearchView,
        setItems
    }

};

export const ImageAsset = (props) => {

    const [loading, setLoading] = useState(true);

    const {src, ...imgData} = props

    return (
        <Box className='relative'>
            <Box className='absolute top-0 left-0'>
                {loading && <img src={'/bg/loader.png'} {...imgData} />}
            </Box>
            <img {...props} onLoad={() => loading && setLoading(false)} />
        </Box>
    )
}

export const StatAsset = ({
    statProps,
    labelContent,
    labelProps,
    numberContent,
    numberProps,
    helperContent,
    helperProps,
    arrowProps
}) => {

    
    return (
        <Stat {...statProps}>
            {labelContent && <StatLabel {...labelProps} >{labelContent}</StatLabel>}
            {numberContent && <StatNumber {...numberProps}>{numberContent}</StatNumber>}
            {
                helperContent &&
                <StatHelpText {...helperProps}>
                    {arrowProps && <StatArrow {...arrowProps} />}
                    {helperContent}
                </StatHelpText>
            }
        </Stat>
    )

}

export const StatCard = ({
    number,
    helper,
    label,
    arrow,
    link,
    button
}) => {
    
    return (

        <StatAsset
            statProps={{className: '!rounded-none'}}
            labelProps={{fontSize: {base: 'xs', lg: 'sm'}}}
            labelContent={
                <HStack className="justify-between w-full">
                    <HStack>
                        <Text>{label}</Text>
                    </HStack>
                    {link && <Link to={link}><RiArrowRightLine size={20} /></Link>}
                    {button && <Button size={'sm'} className='border' onClick={button?.callback}>{button?.content}</Button>}
                </HStack>
            }
            numberProps={{className: '!py-1', fontSize: {base: 'md', lg: '2xl'}}}
            numberContent={number?.replace(/undefined/g, 0)}
            helperProps={{className: '!text-xs'}}
            arrowProps={{type: arrow}}
            helperProps={{className: '!text-xs'}}
            helperContent={helper?.replace(/undefined/g, '')}
        />

    )

}

export const SelectAsset = ({options, placeholder, isMulti, isSearch, value, size, onChange}) => {

    const [selected, setSelected] = useState(null)

    const { results, SearchView, setItems } = SearchListAsset(options)

    useEffect(() => {
        setItems(options)
    }, [options])

    useEffect(() => {
        if (value) setSelected(value)
    }, [value])

    useEffect(() => {
        if (selected && onChange) onChange(selected)
    }, [selected])

    return (
        <Box className='w-full'>

            <PopOverAsset
                arrowless
                popOverProps={{placement: 'bottom'}}
                triggerContent={
                    <Box className='cursor-pointer overflow-y-auto'>
                        {
                            isMulti ? 
                                <HStack className='w-full border p-2 flex-wrap max-h-[100px] overflow-y-auto'>
                                    {
                                        selected?.map((selected, index) => (
                                        <HStack key={index} className='p-2 !bg-primary-950 !text-white h-[20px] items-center justify-center'>
                                            <Text fontSize={10} className='capitalize'>{selected}</Text>
                                        </HStack>
                                        ))
                                    }
                                    <> {(!selected || selected?.length === 0) && <Text className='text-xs'>{placeholder ?? 'Select'}</Text>}</>
                                </HStack >
                            :
                            <Button className="!text-xs w-full border" size={size ?? 'sm'}>
                                <HStack className='!justify-between !items-center w-full'>
                                    <Text className='text-xs'>{selected ?? placeholder ?? 'Select'}</Text>
                                    <RiArrowDownSLine size={20} />
                                </HStack>
                            </Button>
                        }
                    </Box>
                }
                contentProps={{
                    minWidth: 180
                }}
                contentProps={{className: 'py-1'}}
                bodyContent={
                    <VStack spacing={5} className="!items-start">  

                        {
                            isSearch &&
                            <Box className="w-full">
                                {SearchView({placeholder: 'Search'})}
                            </Box>
                        }

                        <Box className="w-full max-h-[350px] overflow-y-auto">
                            <List>
                                {
                                    isMulti ? 
                                        <CheckboxGroup value={selected ?? []} onChange={(value) => setSelected(value)}>
                                            {
                                                results?.map((result, index) => (
                                                    <ListItem key={index} className={`text-xs p-2 hover:bg-gray-100 cursor-pointer !capitalize`}>
                                                        <Checkbox className='mr-2' value={result.value} />
                                                        {result.label}
                                                    </ListItem>
                                                ))
                                            }
                                        </CheckboxGroup>
                                    :

                                    <>

                                        {
                                            results?.map((result, index) => (
                                                <ListItem key={index} onClick={() => setSelected(result.value)} 
                                                    className={`text-xs p-2 hover:bg-gray-100 cursor-pointer !capitalize ${selected === result.value ? '!bg-primary-950 !text-white' : ''}`}
                                                >
                                                    {result.label}
                                                </ListItem>
                                            ))
                                        }
                                    
                                    </>
                                }
                            </List>
                        </Box>
                    </VStack>
                }
            />

        </Box>
    )
}

export const PaginateAsset = ({per_page, total, prev_page_url, next_page_url, current_page, last_page, onNavigate}) => {

    return (
        <>
            {
                total > per_page &&
                <HStack spacing={5} className="w-full !justify-start !items-center py-5">
                    {
                        prev_page_url &&
                        <RiArrowLeftSLine onClick={() => onNavigate(current_page-1)} size={20} className="cursor-pointer hover:underline" />
                    }
                    {
                        Array(last_page)?.fill(0)?.map((_, index) => (
                            <Text onClick={() => onNavigate(index+1)} className={`hover:underline cursor-pointer ${current_page === index+1 ? 'text-md font-semibold text-primary-700 underline' : 'text-sm'}`}>{index+1}</Text>
                        ))
                    }
                    {
                        next_page_url &&
                        <RiArrowRightSLine onClick={() => onNavigate(current_page+1)} size={20} className="cursor-pointer hover:underline" />
                    }
                </HStack>
            }
        </>

    )
}
