import { useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, StackDivider, 
    useDisclosure, Center, MenuDivider, Badge
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiMoreFill} from "@remixicon/react"
import { localNumber, statusColors } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, DrawerAsset, LinkAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import OrderList from "@modules/order/components/OrderList"
import CouponForm from "./CouponForm"


const CouponDetails = ({isOpen, onClose, coupon, getCoupons, onOpenPrompt}) => {

    const { isOpen:isCouponOpen, onOpen:onCouponOpen, onClose:onCouponClose } = useDisclosure()

    return (

        <DrawerAsset
            drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'xl'}}}
            bodyContent={
                <VStack spacing={10} className="w-full h-full !items-start mt-5">

                    <VStack className="w-full !items-start" spacing={5}>
                        <Box>
                            <HStack className="mb-1">
                                <Text className="text-sm">{coupon?.code} Details</Text>
                                <Badge className="!px-2" colorScheme={statusColors(coupon?.status ? 'active' : 'inactive')} variant={'subtle'}>
                                    <Text className="font-normal" fontSize={10}>{coupon?.status ? "Active" : 'Inactive'}</Text>
                                </Badge>
                            </HStack>
                            <Text className="text-xs font-normal text-gray-400">
                                View complete information about {coupon?.code}, including usage history and conditions.
                            </Text>
                        </Box>
                        <ButtonGroup>
                            <Button onClick={onCouponOpen} size={'sm'} className="!bg-primary-950 !text-white">Edit</Button>
                            <Button onClick={onOpenPrompt} size={'sm'}>Delete</Button>
                        </ButtonGroup>
                    </VStack>
                    
                    <Box className="w-full">

                        <Box className="mb-4 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
                            
                            <Box className="bg-gray-50 p-5">
                                <StatCard
                                    label={'Total Use Count'}
                                    number={localNumber({number: coupon?.used_count, decimals: 0})}
                                />
                            </Box>
    
                            <Box className="bg-gray-50 p-5">
                                <StatCard
                                    label={'Total Discounted Amount'}
                                    number={`₦${localNumber(
                                        {
                                            number: coupon?.orders?.reduce((sum, order) => {
                                                    const discountedAmount = order?.metadata?.discounted_amount || 0;
                                                    const discountedPercentage = order?.metadata?.discounted_percentage || 0;
                                                    return sum + discountedAmount + discountedPercentage;
                                                },
                                                0
                                            )
                                        }
                                    )}`}
                                />
                            </Box>
    
                        </Box>

                        <OrderList title={''} description={''} defaultItems={coupon?.orders ?? []} />
                        
                    </Box>
                    
                    <CouponForm isOpen={isCouponOpen} onClose={onCouponClose} coupon={coupon} getCoupons={getCoupons}/>

                </VStack>
            }
        />

    )

}


export default CouponDetails