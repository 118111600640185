import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import {connect} from 'react-redux'
import { localNumber } from "@utils/helpers"
import OrderList from "@modules/order/components/OrderList"
import { StatCard, MenuAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import { useAnalyticsService } from "@services/index"
import Statistics from "@components/header/components/StatDate"

const NotFound = () => {

    return (
        <AuthenticatedLayout title={'Page Not Found | Error 404'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'404 - Page Not Found'}
                        description={`Oops! The page you’re looking for doesn’t exist.`}
                    />
                </Box>


                <Box className="content">

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}


const mapStateToProps = (state) => {
    const { accountReducer } = state
    return { accountReducer }
};

export default NotFound