import { useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import DesignList from "@modules/design/components/DesignList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import Statistics from "@components/header/components/StatDate"

const Index = () => {

    const [stat, setStat] = useState({})

    const { uploaded_designs, unconfirmed_designs, confirmed_designs} = stat || {}

    const PageHeaderAction = () => (
        <Statistics setStat={setStat} />
    )

    return (
        <AuthenticatedLayout title={'Designs | Manage and Review Submissions'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Designs'}
                        description={`Access and manage design submissions, review details, and maintain a seamless design workflow.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Uploaded Designs'}
                                number={`${localNumber({number: uploaded_designs?.number, decimals: 0})}`}
                                helper={`${localNumber({number: uploaded_designs?.trend?.percentage, decimals: 2})}% In ${uploaded_designs?.trend?.period}`}
                                arrow={uploaded_designs?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Unconfirmed Designs'}
                                number={`${localNumber({number: unconfirmed_designs?.number, decimals: 0})}`}
                                helper={`${localNumber({number: unconfirmed_designs?.trend?.percentage, decimals: 2})}% In ${unconfirmed_designs?.trend?.period}`}
                                arrow={unconfirmed_designs?.trend?.arrow}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Confirmed Designs'}
                                number={`${localNumber({number: confirmed_designs?.number, decimals: 0})}`}
                                helper={`${localNumber({number: confirmed_designs?.trend?.percentage, decimals: 2})}% In ${confirmed_designs?.trend?.period}`}
                                arrow={confirmed_designs?.trend?.arrow}
                            />
                        </Box>

                    </Box>

                    <Box>

                        <DesignList />

                    </Box>

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Index