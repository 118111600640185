import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack,
    Tab, TabPanel, TabPanels, Switch
} from "@chakra-ui/react"
import { formatDate } from "@utils/helpers"
import {SelectAsset} from "@utils/assets"
import { FieldControl, clearInputErrors } from "@components/form"
import countries from '@utils/countries.json'
import { useUserService } from "@services/index"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"

const ProfileForm = ({user}) => {

    const { first_name, last_name, email, username, email_verified_at, phone, country, status } = user || {}

    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            first_name, 
            last_name,
            username,
            email,
            country,
            phone,
            email_verified_at,
            status
        }
    );

    const { updateUser, requestErrors, load, response } = useUserService()
    
    const {validate, validationErrors} = useValidator(rules.update_user_profile)

    //Handles Form Inputs
    const onHandleChange = (name, value) => {

        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Add User Data to State
    useEffect(() => {
       if (user) {
            setData(data => (
                {
                    ...data,
                    first_name,
                    last_name,
                    username,
                    email,
                    country,
                    phone,
                    email_verified_at,
                    status
                }
            ))
       }
    }, [user])

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => data[field] === '' ) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            clearInputErrors(setError)
            updateUser({...data, id: user?.id})   
        }
    }

    return (
        
        <Box>

            <Box className="mb-5 lg:w-3/6 w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">
                
                <FieldControl error={errors?.first_name?.[0]} label={'First Name'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}} 
                        fontSize={'xs'} 
                        placeholder="Enter first name" 
                        value={data?.first_name}
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('first_name', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl error={errors?.last_name?.[0]} label={'Last name'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}}  
                        fontSize={'xs'} 
                        placeholder="Enter last name" 
                        value={data?.last_name}
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('last_name', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl error={errors?.email?.[0]} label={'Email Address'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}}  
                        fontSize={'xs'} 
                        placeholder="Enter email address" 
                        value={data?.email}
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('email', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl error={errors?.email_updated_at?.[0]} label={'Email Verified At'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}}  
                        fontSize={'xs'} 
                        type="date"
                        placeholder="Select email verification date" 
                        value={formatDate({date: data?.email_verified_at, format: 'YYYY-MM-DD'})} 
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('email_verified_at', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl error={errors?.username?.[0]} label={'Username'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}}  
                        fontSize={'xs'} 
                        placeholder="Enter username" 
                        value={data?.username}
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('username', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl error={errors?.phone?.[0]} label={'Phone Number'}>
                    <Input 
                        size={{base: 'lg', lg: 'md'}}  
                        fontSize={'xs'} 
                        placeholder="Enter phone number" 
                        value={data?.phone}
                        onKeyDown={(e) => e.code === "Enter" && submit()}   
                        onChange={(event) => onHandleChange('phone', event.target.value)} 
                    />
                </FieldControl>

                <FieldControl error={errors?.country?.[0]} label={'Country'}>
                    <SelectAsset
                        value={data?.country}
                        options={countries.map(country => ({value: country?.name, label: country?.name}))}
                        placeholder={'Select Country'}
                        onChange={(selected) => onHandleChange('country', selected)} 
                        isSearch
                    />
                </FieldControl>

            </Box>
            
            <FieldControl label={'Status'}>
                <Switch size={{base: 'lg', lg: 'md'}}  isChecked={data?.status} onChange={(event) => onHandleChange('status', event.target.checked)}  />
            </FieldControl>

            <HStack className="justify-end lg:w-3/6 w-full">
                <Button size={'sm'} onClick={submit} isLoading={load} isDisabled={isDisabled} className="!bg-primary-950 !text-white">
                    Update Profile
                </Button>
            </HStack>
            
        </Box>

    )
    
}


export default ProfileForm