import { useState } from "react"
import { useNotification } from ".."
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom"

const ResponseHandler = () => {

    const [response, setResponse] = useState(null)
    const {notify} = useNotification()
    const navigate = useNavigate()

    const handleResponse = ({data, alert, redirect}) => {
    
        // Sets the Initial Response Object From Server
        setResponse(data)

        //Notify on Response
        if (alert) {
            notify({
              status: alert?.status,
              title: alert?.title,
              msg: alert?.message,
            });
        }

        if (redirect) {
            const query = new URLSearchParams(redirect?.params || {}).toString();
            navigate(`${redirect?.page}${query ? `?${query}` : ''}`);
        }
        
    }
    
    return {
        response,
        handleResponse
    }

}


export default ResponseHandler