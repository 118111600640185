import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import UsersList from "@modules/users/components/UsersList"
import { StatAsset, StatCard, MenuAsset} from "@utils/assets"
import { Link } from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"

const Index = () => {

    const PageHeaderAction = () => (

        <MenuAsset
            triggerProps={{className: '!text-xs', size: 'sm'}}
            trigger={Button}
            triggerContent={<Text>Switch Date</Text>}
            listProps={{
                className: '!min-w-[180px]'
            }}
            menuContent={
                <>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">24Hrs</Text>
                    </MenuItem>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">1 Week</Text>
                    </MenuItem>
                    <MenuItem _hover={{bg: 'primary.950', color: 'white'}}>
                        <Text className="text-xs font-medium capitalize">1 Month</Text>
                    </MenuItem>
                </>
            }
        />
    )

    return (
        <AuthenticatedLayout title={'User Records | Explore and Manage User Data'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'User Records'}
                        description={`Browse a comprehensive list of platform users with options for sorting, filtering, and detailed insights.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <UsersList />

                </Box>

               
           </Box>
        
        </AuthenticatedLayout>
    )

}

export default Index