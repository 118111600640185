import { useState, useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack,
    Select, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton
} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine, RiUploadCloudFill, RiExpandUpDownLine, RiCloseFill,
    RiAddFill, RiSubtractFill, RiPrinterFill, RiFilterFill, RiSparklingFill,
    RiArrowDownSLine
} from "@remixicon/react"
import { toOrdinal, removeEncodedSpace } from "@utils/helpers"
import {MenuAsset, LinkAsset, AccordionAsset, PopOverAsset, DrawerAsset, SelectAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link, useLocation, useNavigate, Prompt } from "react-router-dom"
import { FieldControl, clearInputErrors } from "@components/form"
import UploadMedia from "@modules/media/components/UploadMedia"
import ProductColoursForm from "@modules/product/components/Product/ProductColoursForm"
import ProductFinishingForm from "@modules/product/components/Product/ProductFinishingForm"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useProductService } from "@services/index"

const Form = ({ type }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname } = location
    const name = pathname?.split('/')[3]

    const { isOpen: isMediaOpen, onOpen: onMediaOpen, onClose:onMediaClose } = useDisclosure()

    const [categories, setCategories] = useState([])
    const [attributes, setAttributes] = useState([])
    const [errors, setError] = useState({})

    const [data, setData] = useState(
        { 
            name: '', 
            description: '',
            tags: '',
            category: '',
            sub_category: '',
            images: [],
            colours: [],
            finishings: [],
            attributes: [],
            discounts: [],
            is_publish: false,
        }
    );

    const { createProduct, requestErrors, load, response:new_product } = useProductService()
    const {getProduct, response:product } = useProductService()
    const {validate, validationErrors} = useValidator(rules.create_product)
    const { getCategories, response:product_categories } = useProductService()
    const { getAttributes, response:product_attributes } = useProductService()

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault(); // For modern browsers
          event.returnValue = ""; // For older browsers
        };
      
        window.addEventListener("beforeunload", handleBeforeUnload);
      
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        
        if (type === 'edit' && name) {
            getProduct({id: name})
        }

    }, [name])

    useEffect(() => {
        if (product?.product && !data?.name) {
            const { metadata, specifications } = product.product;
    
            // Transform Colours
            const transformedColours = specifications?.colours?.map(colour => ({
                code: colour?.code || '#000', // Default to black if code is missing
                name: colour?.name || 'black',
                sizes: colour?.sizes?.map(size => ({
                    name: size?.name || '',
                    code: size?.code || '',
                    price: size?.price || 0.00,
                })) || []
            })) || [];
    
            // Extract and transform Finishings
            const transformedFinishings = Object.values(
                specifications?.finishing?.reduce((acc, finishing) => {
                    const key = finishing?.name || ''; // Use name as the unique key
                    if (!acc[key]) {
                        // Initialize a new entry if it doesn't exist
                        acc[key] = {
                            name: finishing?.name || '',
                            image: finishing?.image || '',
                            sizes: [],
                            placements: finishing?.placements?.map(placement => ({
                                name: placement?.name || '',
                                image: placement?.image || '',
                            })) || [],
                        };
                    }
            
                    // Append the size to the sizes array if it exists
                    if (finishing?.size) {
                        acc[key].sizes.push({
                            name: finishing?.size || '',
                            price: finishing?.price || 0.00,
                        });
                    }
            
                    return acc;
                }, {}) || {}
            );
            
    
            // Set the transformed data to the state
            setData({
                ...data,
                name: product.product?.name || '',
                description: product.product?.description || '',
                tags: product.product?.tags || '',
                category: product.product?.category?.id || '',
                sub_category: product.product?.sub_category?.id || '',
                images: product.product?.images || [],
                colours: transformedColours,
                finishings: transformedFinishings,
                attributes: metadata?.attributes || [],
                discounts: product.product?.discounts || []
            });
    
        }
    }, [product]);
    
    useEffect(() => {
        if (new_product?.product) {
            if (data?.is_publish) {
                return navigate(`/products/${new_product?.product?.name}`)
            }else{
                return navigate(`/products`)
            }
        }
    }, [new_product])

    useEffect(() => {
        if (!categories?.length) getCategories()
        if (!attributes?.length)  getAttributes() 
    }, [categories, attributes])

    useEffect(() => {
        if (product_categories && !categories?.length) setCategories(product_categories?.categories)
        if (product_attributes && !attributes?.length) setAttributes(product_attributes?.attributes)
    }, [product_categories, product_attributes])

    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    const onHandleChange = (name, value) => {
        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    const onSelectMedia = (media) => {
        onHandleChange('images', [...(data?.images || []), media]);
    }

    const onRemoveMedia = (index) => {
        onHandleChange('images', data?.images?.filter((_, idx) => idx !== index) || []);
    };  

    const addAttribute = () => {
        const attribute = { name: "", options: [] };
        setData(prevData => ({
            ...prevData,
            attributes: [...(prevData.attributes || []), attribute]
        }));
    };

    const removeAttribute = (attributeIndex) => {
        setData(prevData => ({
            ...prevData,
            attributes: prevData.attributes.filter((_, index) => index !== attributeIndex)
        }));
    };

    const emptyAttributeOptions = (attributeIndex) => {
        setData(prevData => ({
            ...prevData,
            attributes: prevData.attributes.map((attribute, index) => 
                index === attributeIndex ? { ...attribute, options: [] } : attribute
            )
        }));
    };
    
    const isAttributeExists = (currentAttribute, currentAttributeIndex) => 
        data?.attributes.some((attribute, attributeIndex) => attributeIndex !== currentAttributeIndex && attribute.name === currentAttribute);

    const onHandleAttributeChange = (name, index, value) => {
        setData(prevData => ({
            ...prevData,
            attributes: prevData.attributes.map((attribute, idx) =>
                idx === index
                    ? { ...attribute, [name === 'attribute' ? 'name' : 'options']: value }
                    : attribute
            )
        }));
    };

    const addDiscount = () => {
        const discount = { min: "", max: "", discount: "" };
        setData(prevData => ({
            ...prevData,
            discounts: [...(prevData.discounts || []), discount]
        }));
    };

    const removeDiscount = (discountIndex) => {
        setData(prevData => ({
            ...prevData,
            discounts: prevData.discounts.filter((_, index) => index !== discountIndex)
        }));
    };
    
    const onHandleDiscountChange = (name, index, value) => {
        setData(prevData => ({
            ...prevData,
            discounts: prevData.discounts.map((discount, idx) =>
                idx === index
                    ? { ...discount, [name]: value }
                    : discount
            )
        }));
    };
    
    const publish = () => {
        if (!isDisabled) {
            setData(prevData => ({...prevData, is_publish: true}))
            clearInputErrors(setError)
            createProduct({...data, is_publish: true,  id: product?.product?.id})   
        }
    }

    const draft = () => {
        if (data?.name) {
            setData(prevData => ({...prevData, is_publish: false}))
            clearInputErrors(setError)
            createProduct({...data, is_publish: false,  id: product?.product?.id})   
        }
    }

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button isDisabled={isDisabled} onClick={publish} isLoading={load && data?.is_publish} size={'sm'} className='!bg-primary-950 !text-white'> Publish</Button>
            <Button isDisabled={!data?.name} onClick={draft} isLoading={load && !data?.is_publish} size={'sm'} className="border"> Save As Draft</Button>
        </ButtonGroup>
    )

    const ProductDetails = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Text className="text-xl font-semibold text-primary-950">1</Text>
                <Box className="text-left">
                    <Text className="text-sm">Product Details (required)</Text>
                    <Text className="text-xs text-gray-400">Fill in all required info in the form fields below.</Text>
                </Box>
            </HStack>,
        description: 
            <VStack spacing={10} divider={<StackDivider />} className="!justify-between !items-start w-full relative">
                
                <FieldControl error={errors?.name?.[0]}>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-y-4">
                        <Box>
                            <Text className="text-sm font-semibold">Product Name </Text>
                            <Text className="text-xs text-gray-400">Please enter a unique product name for the item</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Input 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                type="text" 
                                placeholder="Enter a unique product name  e.g Cotton T Shirt" 
                                value={data.name}  
                                onChange={(event) => onHandleChange('name', event.target.value)} 
                            />
                        </Box>
                   </Box>
                </FieldControl>

                <FieldControl error={errors?.description?.[0]}>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-4">
                        <Box>
                            <Text className="text-sm font-semibold">Product Description </Text>
                            <Text className="text-xs text-gray-400">Please write a concise product description for the item</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Textarea 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                type="text" 
                                placeholder="Write a concise description for the item" 
                                value={data.description}  
                                onChange={(event) => onHandleChange('description', event.target.value)} 
                            />
                        </Box>
                   </Box>
                </FieldControl>

                <FieldControl error={errors?.tags?.[0]}>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-4">
                        <Box>
                            <Text className="text-sm font-semibold">Product Tags </Text>
                            <Text className="text-xs text-gray-400">Please write list of tags for this item. Separate each tag with a comma.</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Textarea 
                                size={{base: 'lg', lg: 'md'}} 
                                fontSize={'xs'} 
                                type="text" 
                                placeholder="Provide tags for this product  e.g shirt, clothing, cotton" 
                                value={data.tags}
                                onChange={(event) => onHandleChange('tags', event.target.value)} 
                            />
                        </Box>
                   </Box>
                </FieldControl>

                <FieldControl error={errors?.category?.[0]}>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-4">
                        <Box>
                            <Text className="text-sm font-semibold">Product Category</Text>
                            <Text className="text-xs text-gray-400">Please select the nearest category for this item</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                           <Box className="lg:w-2/6">
                                <Select
                                    onChange={(event) => onHandleChange('category', event.target.value)} 
                                    value={data?.category} 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    placeholder="Select Category"
                                >
                                    {
                                        categories?.map((category, index) => (
                                            <option value={category?.id} className="capitalize">{category?.name}</option>
                                        ))
                                    }
                                </Select>
                            </Box>
                        </Box>
                   </Box>
                </FieldControl>

                <FieldControl error={errors?.sub_category?.[0]}>
                   <Box className="flex lg:flex-row flex-col lg:w-5/6 w-full lg:items-center gap-4">
                        <Box>
                            <Text className="text-sm font-semibold">Product Sub Category</Text>
                            <Text className="text-xs text-gray-400">Please select the nearest sub category for this item</Text>
                        </Box>
                        <Box className="lg:w-4/6 lg:ml-auto">
                            <Box className="lg:w-2/6">
                                <Select 
                                    onChange={(event) => onHandleChange('sub_category', event.target.value)} 
                                    value={data?.sub_category} 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    placeholder="Select Sub Category"
                                >
                                    {
                                        categories?.find(category => category?.id == data?.category)?.sub_categories?.map((sub_category, index) => (
                                            <option value={sub_category?.id} className="capitalize">{sub_category?.name}</option>
                                        ))
                                    }
                                </Select>
                            </Box>
                        </Box>
                   </Box>
                </FieldControl>

            </VStack>
    }

    const ProductImages = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Text className="text-xl font-semibold text-primary-950">2</Text>
                <Box className="text-left">
                    <Text className="text-sm">Product Images (required)</Text>
                    <Text className="text-xs text-gray-400">Upload high-quality images for this product. Up to 5 images allowed, in PNG or JPG format only.</Text>
                </Box>
            </HStack>,
        description: 
            <>
                <FieldControl formProps={{className: 'mb-2'}} error={errors?.images?.[0]} />
                <Box className="w-full grid grid-cols-3 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-3 gap-4">
                    
                    {
                        data?.images?.map((image, index) => (
                            <Box key={index} className="w-full h-[110px] mx-auto border p-2 relative">
                                <HStack className="absolute top-2 right-2">
                                    <RiCloseFill onClick={() => onRemoveMedia(index)} size={18} className="text-red-500 cursor-pointer hover:bg-gray-100 " />
                                </HStack>
                                <VStack className="h-full !justify-center !items-center">
                                    <img src={image} width={50} />
                                </VStack>
                            </Box>
                        ))
                    }
                    <Box className="w-full h-[110px]">
                        <IconButton onClick={onMediaOpen} className="!h-full !w-full border" size={'sm'} icon={
                            <VStack spacing={1} className="!items-center !justify-center">
                                <RiUploadCloudFill size={25} />
                                <Text>Add Images</Text>
                            </VStack>
                        } />
                    </Box>

                </Box>
            </>
    }

    const ProductColours = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Text className="text-xl font-semibold text-primary-950">3</Text>
                <Box className="text-left">
                    <Text className="text-sm">Product Colours and Sizes (required)</Text>
                    <Text className="text-xs text-gray-400">Specify the available colours and sizes for this product.</Text>
                </Box>
            </HStack>,
        description: 
            <>
                <FieldControl formProps={{className: 'mb-2'}} error={errors?.colours?.[0]} />
                <ProductColoursForm data={data} onHandleChange={onHandleChange} attributes={attributes} />
            </>
    }

    const ProductFinishing = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Text className="text-xl font-semibold text-primary-950">4</Text>
                <Box className="text-left">
                    <Text className="text-sm">Product Finshing Types (required)</Text>
                    <Text className="text-xs text-gray-400">Specify the available finishing types for this product.</Text>
                </Box>
            </HStack>,
        description: 
            <>
                <FieldControl formProps={{className: 'mb-2'}} error={errors?.finishings?.[0]} />
                <ProductFinishingForm data={data}  onHandleChange={onHandleChange} attributes={attributes} />
            </>

    }

    const ProductAttributes = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Text className="text-xl font-semibold text-primary-950">5</Text>
                <Box className="text-left">
                    <Text className="text-sm">Product Attributes (required)</Text>
                    <Text className="text-xs text-gray-400">Specify all attributes related to this product.</Text>
                </Box>
            </HStack>,
        description: 
            <>
                <FieldControl formProps={{className: 'mb-2'}} error={errors?.attributes?.[0]} />
                <VStack spacing={10} className="!justify-between !items-start w-full relative">

                    {
                        data?.attributes.map((attribute_, index) => (
                            
                            <Stack key={index} direction={['column', 'row']} className="lg:w-3/6 w-full">

                                <FieldControl>      
                                    <SelectAsset
                                        value={data?.attributes[index]?.name}
                                        options={attributes
                                            ?.filter((attribute, attributeIndex) => !isAttributeExists(attribute.name, attributeIndex) || attribute?.name === attribute_?.name)
                                            ?.map(attribute => ({ value: attribute?.name, label: attribute?.name }))
                                        }
                                        placeholder={'Select Attribute'}
                                        size={'md'}
                                        isSearch
                                        onChange={(selected) => {
                                            onHandleAttributeChange('attribute', index, selected)
                                            emptyAttributeOptions(index)
                                        }}
                                    />
                                </FieldControl> 

                                <FieldControl>

                                    <SelectAsset
                                        value={data?.attributes[index]?.options}
                                        options={attributes?.find(attribute => attribute.name === data?.attributes[index]?.name)?.options.map(option => ({value: option, label: option}))}
                                        placeholder={'Select Options'}
                                        size={'md'}
                                        isSearch
                                        isMulti
                                        onChange={(selected) => onHandleAttributeChange('options', index, selected)}
                                    />
                                    
                                </FieldControl> 

                                <HStack className="w-1/6 justify-end">
                                    <IconButton 
                                        className="!bg-red-500 !text-white" 
                                        size={'sm'} 
                                        icon={<RiCloseFill size={20} />} 
                                        onClick={() => {
                                            removeAttribute(index)
                                        }}
                                    />
                                </HStack>

                            </Stack>
                                
                        ))
                    }
                    
                    <HStack className="items-center">
                        <IconButton onClick={addAttribute} className="!bg-primary-950 !text-white" size={'sm'} icon={<RiAddFill size={20} />} />
                        <Text className="text-xs">Add Attribute</Text>
                    </HStack>

                </VStack>
            </>
    }
    
    const ProductDiscounts = {
        title: 
            <HStack className="!justify-start !items-center w-full">
                <Text className="text-xl font-semibold text-primary-950">6</Text>
                <Box className="text-left">
                    <Text className="text-sm">Product Discounts (optional)</Text>
                    <Text className="text-xs text-gray-400">Create discounts for this product.</Text>
                </Box>
            </HStack>,
        description: 
            <>
                <FieldControl formProps={{className: 'mb-2'}} error={errors?.discounts?.[0]} />
                <VStack spacing={10} className="w-full justify-between !items-start">
                    {
                        data.discounts.map((discount, index) => (
                            <Box key={index}>
                                <Box className="mb-2">
                                    <Text className="text-sm font-semibold">{toOrdinal(index + 1)} Discount</Text>
                                    <Text className="text-xs text-gray-400">Make sure you enter the min, max and discount amounts.</Text>
                                </Box>

                                <HStack>
                                    <FieldControl leftElement={
                                        <Box className="px-2 border-r">
                                            <Text className="text-sm">₦</Text>
                                        </Box>
                                    }>
                                        <Input
                                            size="sm"
                                            fontSize="xs"
                                            type="number"
                                            placeholder="Minimum"
                                            value={discount.min}
                                            onChange={(e) => onHandleDiscountChange("min", index, e.target.value)}
                                        />
                                    </FieldControl>

                                    <FieldControl leftElement={
                                        <Box className="px-2 border-r">
                                            <Text className="text-sm">₦</Text>
                                        </Box>
                                    }>
                                        <Input
                                            size="sm"
                                            fontSize="xs"
                                            type="number"
                                            placeholder="Maximum"
                                            value={discount.max}
                                            onChange={(e) => onHandleDiscountChange("max", index, e.target.value)}
                                        />
                                    </FieldControl>

                                    <FieldControl leftElement={
                                        <Box className="px-2 border-r">
                                            <Text className="text-sm">₦</Text>
                                        </Box>
                                    }>
                                        <Input
                                            size="sm"
                                            fontSize="xs"
                                            type="number"
                                            placeholder="Discount"
                                            value={discount.discount}
                                            onChange={(e) => onHandleDiscountChange("discount", index, e.target.value)}
                                        />
                                    </FieldControl>

                                    <HStack className="w-1/6 justify-end">
                                        <IconButton 
                                            className="!bg-red-500 !text-white" 
                                            size={'sm'} 
                                            icon={<RiCloseFill size={20} />} 
                                            onClick={() => {
                                                removeDiscount(index)
                                            }}
                                        />
                                    </HStack>

                                </HStack>
                            </Box>
                        ))
                    }

                    <HStack className="items-center">
                        <IconButton onClick={addDiscount} className="!bg-primary-950 !text-white" size={'sm'} icon={<RiAddFill size={20} />} />
                        <Text className="text-xs">Add Discount</Text>
                    </HStack>
                </VStack>
            </>
    }

    const isDisabled = 
        !data?.name || 
        !data?.description || 
        !data?.category || 
        !data?.sub_category || 
        !data?.images?.length ||  
        !data?.colours?.length || 
        !data?.finishings?.length ||
        !data?.attributes?.length ||
        data?.attributes?.some(attribute => 
            !attribute?.name || 
            !attribute?.options?.length
        ) || 
        data?.discounts?.some(discount => 
            !discount?.min || 
            !discount?.max || 
            !discount?.discount || 
            parseFloat(discount?.min) === 0 || 
            parseFloat(discount?.max) === 0 || 
            parseFloat(discount?.discount) === 0
        ) || 
        data?.colours?.some(colour => 
            colour?.sizes?.some(size => 
                !size.name || !size.code || parseFloat(size.price) === 0 || size.price === ""
            )
        ) || 
        data?.finishings?.some(finishing => 
            finishing?.sizes?.some(size => 
                !size.name || parseFloat(size.price) === 0 || size.price === ""
            ) || 
            finishing?.placements?.some(placement => 
                !placement.name
            )
        );

    return (
        <AuthenticatedLayout title={type === 'edit' ? 'Edit Product | Update Inventory Details' : 'Create Product | Add New Inventory'}>

            <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={type === 'edit' ? `Edit ${removeEncodedSpace(name)}` : 'Create New Product'}
                        description={ type === 'edit' ? 
                                `Make changes to ${removeEncodedSpace(name)}'s details, including its description, stock, and pricing` :
                                'Add a new product to your inventory, including details like name, description, pricing, and stock levels.'
                        }
                        action={PageHeaderAction}
                    />
                </Box>

                {
                    (Object?.keys(errors)?.length && Object?.keys(requestErrors)?.length) ? 
                        <Box>
                            <Alert my={2} status='error'>
                                <Box className="flex lg:flex-row flex-col w-full items-center">
                                    <AlertTitle className='text-xs'>Error</AlertTitle>
                                    <AlertDescription className='text-xs'>
                                        You have issues with your 
                                        <strong> {Object?.keys(errors)?.join(', ')} </strong>
                                        submission(s).
                                    </AlertDescription>
                                </Box>
                            </Alert>
                        </Box>
                        :
                        <></>
                }

                <Box className="content relative">

                    <AccordionAsset 
                        accordionProps={{allowMultiple: true}}
                        accordionItems={[ProductDetails, ProductImages, ProductColours, ProductFinishing, ProductAttributes, ProductDiscounts]}
                        titleProps={{className: 'bg-gray-100 !py-5' }}
                        panelprops={{className: 'font-normal text-sm text-gray-500 !bg-gray-50 !py-5'}}
                        itemProps={{ className: 'border my-5'}}
                    />

                </Box>
                
            </Box>

                         
            <UploadMedia isOpen={isMediaOpen} onClose={onMediaClose} onSelectMedia={onSelectMedia} />

        </AuthenticatedLayout>
    )

}


export default Form