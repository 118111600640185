import { useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import ProductList from "@modules/product/components/Product/ProductList"
import { StatAsset, StatCard, MenuAsset, LinkAsset} from "@utils/assets"
import PageHeader from "@components/header/components/PageHeader"
import { Link } from "react-router-dom"
import AttributeList from "@modules/product/components/Attribute/AttributeList"
import AttributeForm from "@modules/product/components/Attribute/AttributeForm"
import { useProductService } from "@services/index"

const Index = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const { getAttributes, response:attributes } = useProductService()

    useEffect(() => {
        getAttributes()
    }, [])

    const PageHeaderAction = () => (
        <ButtonGroup>
            <Button onClick={onOpen} size={'sm'} className="!bg-primary-950 !text-white">Create Attribute</Button>
        </ButtonGroup>
    )

    return (
        <AuthenticatedLayout title={'Attributes | Define Product Features and Specifications'}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={'Product Attributes'}
                        description={`Manage product attributes such as size, color, and material to create a tailored and versatile product catalog.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Total Attributes'}
                                number={localNumber({number: attributes?.attributes?.length, decimals: 0})}
                            />
                        </Box>

                    </Box>

                    <Box>
                        {
                            attributes &&
                            <AttributeList defaultItems={attributes?.attributes} />
                        }
                    </Box>

                </Box>

               
           </Box>

           <AttributeForm isOpen={isOpen} onClose={onClose} getAttributes={getAttributes}  />
        
        </AuthenticatedLayout>
    )

}


export default Index