import { useEffect, useState } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, StackDivider, useDisclosure} from "@chakra-ui/react"
import { RiArrowDownLine, RiArrowUpLine, RiArrowRightUpLine} from "@remixicon/react"
import { localNumber } from "@utils/helpers"
import { StatAsset, StatCard, MenuAsset, DrawerAsset, LinkAsset} from "@utils/assets"
import { Link, useLocation} from "react-router-dom"
import PageHeader from "@components/header/components/PageHeader"
import PaymentList from "@modules/payment/components/PaymentList"
import OrderList from "@modules/order/components/OrderList"
import DesignList from "@modules/design/components/DesignList"
import ProductList from "@modules/product/components/Product/ProductList"
import CartList from "@modules/users/components/CartList"
import ReviewList from "@modules/product/components/Review/ReviewList"
import { useUserService } from "@services/index"

const Details = () => {

    const location = useLocation()
    const { pathname } = location
    const username = pathname?.split('/')[2]
    

    const [drawer, setDrawer] = useState(null)
    const {isOpen, onOpen, onClose} = useDisclosure()

    const { getUser, response:user } = useUserService()

    const { cart_items, addresses, order_items, orders, payments, reviews, shippings, wish_list, ...user_} = user?.user || {}

    useEffect(() => {
        if (username) {
            getUser({username})
        }
    }, [username])

    const PageHeaderAction = () => (
        <ButtonGroup>
            <LinkAsset linkProps={{className: '!bg-primary-950 !text-white'}} href={`/users/edit/${user_?.username}`}>
                Edit User
            </LinkAsset>
        </ButtonGroup>
    )

    const UserPayments = () => (
       <>
            {
                drawer === 'payments' &&
                <DrawerAsset
                    headerContent={
                        <Box>
                            <Text className="text-sm">{`${user_?.first_name} ${user_?.last_name}`}'s Payments</Text>
                            <Text className="text-xs font-normal text-gray-400">Below are the payment details for {`${user_?.first_name} ${user_?.last_name}`}</Text>
                        </Box>
                    }
                    drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'xl'}}}
                    bodyContent={
                        <Box className="mt-5">

                            <Box className="mb-4 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
                                
                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Total Successful Payments'}
                                        number={`₦${localNumber({
                                            number: payments?.filter(payment => payment.status === 'successful').reduce((sum, payment) => sum + (payment.amount || 0), 0), 
                                            decimals: 2
                                        })}`}
                                    />
                                </Box>
        
                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Total Pending Payments'}
                                        number={`₦${localNumber({
                                            number: payments?.filter(payment => payment.status === 'pending').reduce((sum, payment) => sum + (payment.amount || 0), 0), 
                                            decimals: 2
                                        })}`}
                                    />
                                </Box>
        
                            </Box>
        
                           
                                        
                               {
                                    payments &&
                                    <Box>
                                        <PaymentList defaultItems={payments?.map((payment) => ({...payment, user: user_}))} title={''} description={''} />
                                    </Box>
                               }
        
                         
        
                        </Box>
                    }
                />
            }
       </>
    )

    const UserOrders = () => (
       <>
            {
                drawer === 'orders' &&
                <DrawerAsset
                    headerContent={
                        <Box>
                            <Text className="text-sm">{`${user_?.first_name} ${user_?.last_name}`}'s Orders</Text>
                            <Text className="text-xs font-normal text-gray-400">
                                Below are the order details for {`${user_?.first_name} ${user_?.last_name}`}.
                            </Text>
                        </Box>
                    }
                    drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'xl'}}}
                    bodyContent={
                        <Box className="mt-5">

                            <Box className="mb-4 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                                
                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Placed Orders'}
                                        number={`${localNumber({
                                            number: orders?.filter(order => order.status === 'placed')?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>

                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Submitted Orders'}
                                        number={`${localNumber({
                                            number: orders?.filter(order => order.status === 'submitted')?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>

                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Shipped Orders'}
                                        number={`${localNumber({
                                            number: orders?.filter(order => order.status === 'shipped')?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>

                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Declined Orders'}
                                        number={`${localNumber({
                                            number: orders?.filter(order => order.status === 'declined')?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>
            
                            </Box>
        
                            {
                                orders &&
                                <Box>
                                    <OrderList defaultItems={orders?.map((order) => ({...order, user: user_}))} title={''} description={''} />
                                </Box>
                            }
        
                        </Box>
                    }
                />
            }
       </>
    )

    const UserDesigns = () => (
       <>
            {
                drawer === 'designs' &&
                <DrawerAsset
                    headerContent={
                        <Box>
                            <Text className="text-sm">{`${user_?.first_name} ${user_?.last_name}`}'s Designs</Text>
                            <Text className="text-xs font-normal text-gray-400">
                                Below are the design submissions from {`${user_?.first_name} ${user_?.last_name}`}.
                            </Text>
                        </Box>
                    }
                    drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'xl'}}}
                    bodyContent={
                        <Box className="mt-5">

                            <Box className="mb-4 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
            
                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Uploaded Designs'}
                                        number={`${localNumber({
                                            number: order_items?.filter(item => item.design != null)?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>

                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Unconfirmed Designs'}
                                        number={`${localNumber({
                                            number: order_items?.filter(item => item.status === 'submitted')?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>

                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Confirmed Designs'}
                                        number={`${localNumber({
                                            number: order_items?.filter(item => item.status === 'confirmed')?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>

                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Declined Designs'}
                                        number={`${localNumber({
                                            number: order_items?.filter(item => item.status === 'declined')?.length,
                                            decimals: 0
                                        })}`}
                                    />
                                </Box>
            
                            </Box>
        
                            {
                                order_items &&
                                <Box>
                                    <DesignList defaultItems={order_items?.map((item) => ({...item, user: user_}))} title={''} description={''} />
                                </Box>
                            }
        
                        </Box>
                    }
                />
            }
       </>
    )

    const UserReviews = () => (
       <>
            {
                drawer === 'reviews' &&
                <DrawerAsset
                    headerContent={
                        <Box>
                            <Text className="text-sm">{`${user_?.first_name} ${user_?.last_name}`}'s Reviews</Text>
                            <Text className="text-xs font-normal text-gray-400">
                                Below are the reviews provided by {`${user_?.first_name} ${user_?.last_name}`}.
                            </Text>
                        </Box>
                    }
                    drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'xl'}}}
                    bodyContent={
                        <Box className="mt-5">
                            
                            <Box className="mb-4 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-3 sm:grid-cols-1 gap-4">
                        
                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Approved Reviews'}
                                        number={localNumber({number: reviews?.filter((review) => review?.status )?.length, decimals: 0})}
                                    />
                                </Box>

                                <Box className="bg-gray-50 p-5">
                                    <StatCard
                                        label={'Pending Reviews'}
                                        number={localNumber({number: reviews?.filter((review) => !review?.status )?.length, decimals: 0})}
                                    />
                                </Box>

                            </Box>

                            <Box>

                                {
                                    reviews &&
                                    <ReviewList title={''} description={''} defaultItems={reviews?.map((review) => ({...review, user: user_}))} />
                                }
                                
                            </Box>

                        </Box>
                    }
                />
            }
       </>
    )

    const UserWishList = () => (
       <>
            {
                drawer === 'wishlist' &&
                <DrawerAsset
                    headerContent={
                        <Box>
                            <Text className="text-sm">{`${user_?.first_name} ${user_?.last_name}`}'s Wish List</Text>
                            <Text className="text-xs font-normal text-gray-400">
                                Below are the items in {`${user_?.first_name} ${user_?.last_name}`}'s wish list.
                            </Text>
                        </Box>
                    }
                    drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'xl'}}}
                    bodyContent={
                        <Box className="mt-5">

                            <ProductList defaultItems={wish_list?.map(({product}) => product )} title={''} description={''} />
        
                        </Box>
                    }
                />
            }
       </>
    )

    const UserCart = () => (
       <>
            {
                drawer === 'cart' &&
                <DrawerAsset
                    headerContent={
                        <Box>
                            <Text className="text-sm">{`${user_?.first_name} ${user_?.last_name}`}'s Cart</Text>
                            <Text className="text-xs font-normal text-gray-400">
                                Below are the items in {`${user_?.first_name} ${user_?.last_name}`}'s cart.
                            </Text>
                        </Box>
                    }
                    drawerProps={{isOpen, onClose, isCentered: true, closeOnOverlayClick: false, size: {base: 'full', lg: 'xl'}}}
                    bodyContent={
                        <Box className="mt-5">

                            <CartList defaultItems={cart_items} title={''} description={''} />
        
                        </Box>
                    }
                />
            }
       </>
    )

    return (
        <AuthenticatedLayout title={`User Details | Profile and Activity for ${user?.user?.first_name}`}>

           <Box className="w-full h-full">

                <Box className="mb-5">
                    <PageHeader
                        title={`User Profile - ${user?.user?.first_name ?? ''}`}
                        description={`View the profile, activity, and account details of ${user?.user?.first_name} to manage their interactions and settings.`}
                        action={PageHeaderAction}
                    />
                </Box>


                <Box className="content">

                    <Box className="mb-4 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
                        
                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Payments'}
                                number={`₦${localNumber({number: payments?.reduce((sum, payment) => sum + (payment.amount || 0), 0), decimals: 2})}`}
                                button={{callback: () => {
                                    setDrawer('payments')
                                    onOpen()
                                }, content: 'More'}}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Orders'}
                                number={`₦${localNumber({number: orders?.reduce((sum, order) => sum + (order.amount || 0), 0), decimals: 2})}`}
                                button={{callback: () => {
                                    setDrawer('orders')
                                    onOpen()
                                }, content: 'More'}}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Designs'}
                                number={localNumber({number: order_items?.filter(item => item?.design !== null)?.length, decimals: 0})}
                                button={{callback: () => {
                                    setDrawer('designs')
                                    onOpen()
                                }, content: 'More'}}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Wish List'}
                                number={localNumber({number: wish_list?.length, decimals: 0})}
                                button={{callback: () => {
                                    setDrawer('wishlist')
                                    onOpen()
                                }, content: 'More'}}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Cart'}
                                number={localNumber({number: cart_items?.length, decimals: 0})}
                                button={{callback: () => {
                                    setDrawer('cart')
                                    onOpen()
                                }, content: 'More'}}
                            />
                        </Box>

                        <Box className="bg-gray-50 p-5">
                            <StatCard
                                label={'Reviews'}
                                number={localNumber({number: reviews?.length, decimals: 0})}
                                button={{callback: () => {
                                    setDrawer('reviews')
                                    onOpen()
                                }, content: 'More'}}
                            />
                        </Box>
                        

                    </Box>

                </Box>

               
           </Box>
                                
            <UserPayments />
            <UserOrders />
            <UserDesigns />
            <UserReviews />
            <UserWishList />
            <UserCart />

        </AuthenticatedLayout>
    )

}

export default Details