"use client";
import { useState, useEffect } from "react";
import {Box, Stack, HStack, VStack, SimpleGrid, Text, useColorModeValue, Button,
  Menu, MenuButton, MenuList, MenuItem, useColorMode, Center, Heading, StackDivider,
  IconButton, Divider, Avatar, Input, PinInput, PinInputField, Textarea, Checkbox, useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'
import { RiGoogleFill, RiAppleFill, RiFacebookCircleFill, RiStarFill, RiEyeLine, RiEyeOffLine } from "@remixicon/react";
import { FieldControl, clearInputErrors } from "@components/form";
import { BreadcrumbAsset, SearchAsset, ButtonAsset } from "@utils/assets";
import { useAuthService, useAccountService } from "@services/index";
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index";
import GuestLayout from "@components/layout/Guest";
import { useLocation, useNavigate } from "react-router-dom";


const Invite = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const { search } = location || {}

    //Initialise Validator Hook
    const {validate, validationErrors} = useValidator(rules.otp)

    //Make request
    const {invite, load, requestErrors, response} = useAuthService()
    const {verifyInvitation, response:invitation} = useAuthService()

    useEffect(() => {
        
        if (!search) {
            return navigate('/auth/login')
        }

        const token = Object.fromEntries(new URLSearchParams(search.replace('?', '')))
        verifyInvitation(token)

    }, [search])

    //Initialiases Input State
    const [data, setData] = useState('');

    //Initialises Error State
    const [errors, setError] = useState({})

    //Handles Form Inputs
    const onHandleChange = (value) => {

        const length = value.length

        if(length <= 6 && !load){
            
            setData(value);
            
            validate('otp', value)   

        }

    };

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])
        
    //Disable Submit Button On Conditions
    const isDisabled = load || !data || data.length < 6
    
    //Submits Form 
    const submit = () => {
        if (!isDisabled) {
            setError([])
            invite({otp: data, email: invitation?.target, channel: invitation?.channel})
        }
    }

    useEffect(() => {
        if(data.length === 6){
            submit()
        }
    }, [data])

    return (
        <GuestLayout title={'Verify Your Email Address'}>

            <VStack spacing={5} className="lg:w-10/12 w-full">

                <VStack spacing={4}>

                    <Box mb={5} className="w-10/12">
                        <Text className="font-bold text-2xl">Verify Invitation</Text>
                        <Text className="text-sm text-gray-400">Please enter OTP code sent to {invitation?.target ?? 'your email inbox'} below to continue</Text>
                    </Box>

                </VStack>

                <VStack alignItems={'center'} w={'100%'} spacing={4}>
                    <HStack w={'100%'} justifyContent={'center'}>
                        <PinInput onKeyDown={(e) => e.code === "Enter" && submit()} isInvalid={errors?.otp?.[0] ?? false} onChange={(value) => onHandleChange(value)} w={'100%'} mask>
                            <PinInputField w={55} h={55} />
                            <PinInputField w={55} h={55} />
                            <PinInputField w={55} h={55} />
                            <PinInputField w={55} h={55} />
                            <PinInputField w={55} h={55} />
                            <PinInputField w={55} h={55} />
                        </PinInput>
                    </HStack>
                    {
                        errors?.otp?.[0] &&
                        <Text fontSize={'sm'} color={'red'} textAlign={'center'}>{errors?.otp?.[0]}</Text>
                    }
                    
                </VStack>
                

                <VStack mt={10} spacing={4} w={'100%'}>
                    <Center w={'100%'}>
                        <ButtonAsset isLoading={load} onClick={submit} buttonProps={{w: '100%', isDisabled}}>
                           Accept Invitation
                        </ButtonAsset>
                    </Center>
                </VStack>

            </VStack>
                    
        </GuestLayout>
    )

}

export default Invite