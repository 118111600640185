import { Route } from 'react-router-dom';
import AuthenticatedLayout from '@components/layout/Authenticated';

import Index from '@modules/users/pages/Index'
import List from '@modules/users/pages/List'
import Details from '@modules/users/pages/Details'
import Form from '@modules/users/pages/Form'

const UserRoutes = [
    <Route path={'/users'}> 
        <Route index element={<Index />} />
        <Route path="list" element={<List />} />
        <Route path=":id" element={<Details />} />
        <Route path="edit/:id" element={<Form />} />
    </Route>
]

export default UserRoutes