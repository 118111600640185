import { useState, useEffect } from "react"
import AuthenticatedLayout from "@components/layout/Authenticated"
import { Box, Text, HStack, Button, ButtonGroup, MenuItem, VStack, Radio, StackDivider, Input, 
    Textarea, Center, IconButton, useDisclosure, RadioGroup, Checkbox, List, ListItem, Stack, Select,
    Switch
} from "@chakra-ui/react"
import { formatDate } from "@utils/helpers"
import {ModalAsset} from "@utils/assets"
import { FieldControl } from "@components/form"
import rules from "@utils/rules.json"
import { useValidator } from "@handlers/index"
import { useProductService } from "@services/index"

const CouponForm = ({isOpen, onClose, coupon, getCoupons, setItem}) => {
    
    const [errors, setError] = useState({})
    const [data, setData] = useState(
        { 
            code: '', 
            discount_amount: 0,
            discount_percentage: 0,
            valid_from: '',
            valid_until: '',
            usage_limit: '',
            status: false,
            description: ''
        }
    );

    const { createCoupon, response:updated_coupon, requestErrors, load } = useProductService()
    const {validate, validationErrors} = useValidator(rules.create_coupon)

    const cleanState = () => {
        setData({ 
            code: '', 
            discount_amount: 0,
            discount_percentage: 0,
            valid_from: '',
            valid_until: '',
            usage_limit: '',
            status: false,
            description: ''
        })
        if(setItem) setItem(null)
    }

    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        if (updated_coupon?.coupon) {
            onClose()
            cleanState()
            if (getCoupons) getCoupons()
        }
    }, [updated_coupon])

    useEffect(() => {
        if (coupon && !data?.code) {
            setData({
                ...data,
                code: coupon?.code, 
                discount_amount: coupon?.discount_amount,
                discount_percentage: coupon?.discount_percentage,
                valid_from: coupon?.valid_from,
                valid_until: coupon?.valid_until,
                usage_limit: coupon?.usage_limit,
                status: coupon?.status,
                description: coupon?.description
            })
        }else if(!coupon){
            cleanState()
        }
    }, [coupon, isOpen])

    const onHandleChange = (name, value) => {
        setData((prevData) => ({ ...prevData, [name]: value }));
        validate(name, value)
    };

    const submit = () => {
        if (!isDisabled) {
            createCoupon({...data, id: coupon?.id})
        }
    }

    const isDisabled = load || 
    Object.keys(data).some(field => (field !== 'description' && field !== 'status' && data[field] === '')) || 
    Object.keys(errors).length > 0 || 
    (data.discount_percentage === 0 && data.discount_amount === 0);
    
    
    return (
        
        <Box>

            <ModalAsset
                arrowless
                modalProps={{isOpen, onClose, isCentered: true, size: {base: 'full', lg: 'lg'}, closeOnOverlayClick: false}}
                headerContent={
                <Box>
                    <Text className="font-semibold capitalize">{coupon ? `Edit ${coupon?.code}` : `Create Coupon`}</Text>
                    <Text className="text-xs font-normal text-gray-400">
                        {
                            coupon ? `Update the details and rules for ${coupon?.code}.` : 
                            'Generate a new discount coupon with customizable rules and usage limits.'
                        }
                    </Text>
                </Box>
                }
                bodyContent={
                    <VStack spacing={7} className="!items-start !justify-center">

                        <Box className="w-full grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-y-7 gap-x-4">
                                                
                            <FieldControl error={errors?.code?.[0]} label={'Code'}>
                                <Input 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    placeholder="Enter coupon code" 
                                    value={data.code}  
                                    onChange={(event) => onHandleChange('code', event.target.value)} 
                                />
                            </FieldControl>

                            <FieldControl error={errors?.discount_amount?.[0]} label={'Discount amount'} leftElement={
                                    <Box className="px-2 border-r">
                                        <Text className="text-sm">₦</Text>
                                    </Box>
                                }>
                                <Input 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    placeholder="Enter discount amount" 
                                    value={data.discount_amount}  
                                    onChange={(event) => onHandleChange('discount_amount', event.target.value)} 
                                />
                            </FieldControl>

                            <FieldControl error={errors?.discount_percentage?.[0]} label={'Discount percentage'} leftElement={
                                    <Box className="px-2 border-r">
                                        <Text className="text-sm">%</Text>
                                    </Box>
                                }>
                                <Input 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    placeholder="Enter coupon amount in percentage" 
                                    value={data.discount_percentage}  
                                    onChange={(event) => onHandleChange('discount_percentage', event.target.value)} 
                                />
                            </FieldControl>

                            <FieldControl error={errors?.valid_from?.[0]} label={'Valid From'}>
                                <Input 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    type="date"
                                    value={formatDate({date: data?.valid_from, format: 'YYYY-MM-DD'})} 
                                    onChange={(event) => onHandleChange('valid_from', event.target.value)} 
                                />
                            </FieldControl>

                            <FieldControl error={errors?.valid_until?.[0]} label={'Valid Until'}>
                                <Input 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    type="date"
                                    value={formatDate({date: data?.valid_until, format: 'YYYY-MM-DD'})} 
                                    onChange={(event) => onHandleChange('valid_until', event.target.value)} 
                                />
                            </FieldControl>

                            <FieldControl error={errors?.usage_limit?.[0]} label={'Usage Limit'}>
                                <Input 
                                    size={{base: 'lg', lg: 'md'}} 
                                    fontSize={'xs'} 
                                    placeholder="Enter usage limit" 
                                    value={data.usage_limit}  
                                    onChange={(event) => onHandleChange('usage_limit', event.target.value)} 
                                />
                            </FieldControl>

                        </Box>

                        <FieldControl error={errors?.description?.[0]} label={'Description'}>
                            <Textarea 
                                fontSize={'xs'} 
                                placeholder="Enter coupon description" 
                                value={data.description}  
                                onChange={(event) => onHandleChange('description', event.target.value)} 
                            />
                        </FieldControl>

                        <FieldControl error={errors?.status?.[0]} label={'Coupon Status'}>
                            <Switch isChecked={data?.status} onChange={(event) => onHandleChange('status', event.target.checked) } />
                        </FieldControl>
                        
                    </VStack>
                }
                footerContent={
                    <ButtonGroup>
                        <Button size={'sm'} onClick={() => {
                            cleanState()
                            onClose()
                        }} className="border">Cancel</Button>
                        <Button size={'sm'} onClick={submit} isDisabled={isDisabled} isLoading={load} className="!bg-primary-950 !text-white">Save Changes</Button>
                    </ButtonGroup>
                }

            />

        </Box>

    )

}


export default CouponForm