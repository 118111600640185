import { useState, useEffect } from "react"
import { Box, Th, Tr, HStack, Button, Text, MenuItem, Checkbox, CheckboxGroup, Input, VStack, Td, 
    Badge, Avatar, useDisclosure, MenuDivider, Center
} from "@chakra-ui/react"
import { TableAsset, MenuAsset, SearchListAsset, PromptAsset} from "@utils/assets"
import { formatDate, localNumber, statusColors, truncateText} from "@utils/helpers"
import { RiMoreLine, RiMoreFill } from "@remixicon/react"
import { Link } from "react-router-dom"
import AttributeForm from "@modules/product/components/Attribute/AttributeForm"
import { useProductService } from "@services/index"


const AttributeList = ({title, description, defaultItems}) => {

    const [item, setItem] = useState(null)

    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen:isOpenPrompt, onOpen:onOpenPrompt, onClose:onClosePrompt} = useDisclosure()
    
    const { getAttributes, response } = useProductService()
    const { deleteAttribute, response:deleted_attribute, load:delete_attribute_load } = useProductService()
    const { results, SearchView, setItems:setSearchItems } = SearchListAsset(response?.attributes ?? defaultItems)

    useEffect(() => {
        if (!defaultItems) {
            getAttributes()
        }else{
            setSearchItems(defaultItems)
        }
    }, [defaultItems])

    useEffect(() => {
        if (response?.attributes) {
            setSearchItems(response?.attributes)
        }
    }, [response?.attributes])

    useEffect(() => {
        if (deleted_attribute) {
            onClosePrompt()
            getAttributes()
        }
    }, [deleted_attribute])
    
    const FilterView = () => (

        <HStack className="justify-end items-center w-full py-2" spacing={5}>

            <Box className="lg:block hidden" spacing={5}>

                {SearchView({placeholder: 'Search for attributes'})}
                
            </Box>

        </HStack>
    )
    
    const TableView = () => (
        <TableAsset
            containerProps={{className: 'w-full'}}
            tableProps={{className: 'border-t'}}
            headerContent={
                <Tr>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">#</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Name</Th>
                    <Th className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Options</Th>
                    <Th isNumeric className="!py-3 !text-gray-400 !font-normal !capitalize !border-0">Action</Th>
                </Tr>
            }
            bodyContent={
                <>
                    {   

                        results?.map((attribute, index) => (
                            
                            <Tr key={index} className="font-medium text-xs">
                                <Td>{index+1}</Td>
                                <Td>{attribute?.name}</Td>
                                <Td>{truncateText({text: attribute?.options?.join(', '), length: 50})}</Td>
                                <Td isNumeric>
                                    <MenuAsset
                                        triggerProps={{className: '!text-xs', size: 'sm'}}
                                        trigger={Button}
                                        triggerContent={<RiMoreFill size={20} />}
                                        listProps={{
                                            className: '!min-w-[120px]'
                                        }}
                                        menuContent={
                                            <>  
                                                <MenuItem onClick={() => {
                                                    setItem(attribute)
                                                    onOpen()
                                                }} _hover={{bg: 'primary.950', color: 'white'}}>
                                                    Edit Attribute
                                                </MenuItem>

                                                <MenuDivider />

                                                <Center>
                                                    <Button onClick={() => {
                                                        setItem(attribute)
                                                        onOpenPrompt()
                                                    }} variant={'link'} color={'red.600'} fontSize={14} fontWeight={'medium'}>
                                                        Delete
                                                    </Button>
                                                </Center>

                                            </>
                                        }
                                    />
                                </Td>
                            </Tr>

                        ))
                    }
                </>
            }
            footerContent={<></>}
        />
    )

    return (
        <Box>   

            <Box className="py-3 lg:w-2/12 w-full">
                <Text className="font-semibold">{title ?? "Attributes List"}</Text>
                <Text className="text-xs text-gray-500">{description ?? "Browse main product attributes for efficient organization."}</Text>
            </Box>
            
            {FilterView()}

            {TableView()}

            {
                item &&
                <AttributeForm isOpen={isOpen} onClose={onClose} attribute={item} getAttributes={getAttributes} />
            }

            <PromptAsset
                isOpen={isOpenPrompt}
                onClose={onClosePrompt}
                title={'Please confirm'}
                load={delete_attribute_load}
                body={'Are you sure you want to proceed with this action?'}
                onSubmit={() => {
                    deleteAttribute({id: item?.id})
                }}
            />
        
            
        </Box>
    )

}


export default AttributeList